import { Grid } from '@mui/material';
import { EOfferType } from '../../../../domain/model/enums';
import { Nullable } from '../../../../domain/model/types';
import ReportCardBookingOrdersStatistic from '../components/cards/bookingOrdersStatistic';
import ReportCardOffers from '../components/cards/offers';
import ReportCardOffersStatistic from '../components/cards/offersStatistic';
import { EReportActionType } from '../types';

interface ReportPartnerProps {
  readonly offerType: Nullable<EOfferType>;
  readonly isOffersReportFetching: boolean;
  readonly isOffersStatisticReportFetching: boolean;
  readonly isBookingOrdersStatisticReportFetching: boolean;
  readonly onDownload: Nullable<(type: EReportActionType) => void>;
}

const ReportPartner = (props: ReportPartnerProps) => {
  const {
    offerType,
    isOffersReportFetching,
    isOffersStatisticReportFetching,
    isBookingOrdersStatisticReportFetching,
    onDownload,
  } = props;

  return offerType === EOfferType.Booking ? (
    <Grid
      container
      spacing={3}
      wrap='wrap'
    >
      <Grid
        item
        xs={12}
        md={6}
        lg={4}
        xl={3}
      >
        <ReportCardBookingOrdersStatistic
          onDownload={onDownload}
          isFetching={isBookingOrdersStatisticReportFetching}
        />
      </Grid>
    </Grid>
  ) : (
    <Grid
      container
      spacing={3}
      wrap='wrap'
    >
      <Grid
        item
        xs={12}
        md={6}
        lg={4}
        xl={3}
      >
        <ReportCardOffersStatistic
          onDownload={onDownload}
          isFetching={isOffersStatisticReportFetching}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        lg={4}
        xl={3}
      >
        <ReportCardOffers
          onDownload={onDownload}
          isFetching={isOffersReportFetching}
        />
      </Grid>
    </Grid>
  );
};

export default ReportPartner;
