import { Fade, Typography } from '@mui/material';
import moment, { Moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import Api from '../../../../data/api';
import { EOfferType } from '../../../../domain/model/enums';
import { EDateFormat, EDateTimeFormat } from '../../../../domain/model/formats';
import { Nullable, UUID } from '../../../../domain/model/types';
import AppBreadcrumbs from '../../../components/common/breadcrumbs';
import DefaultHeader from '../../../components/common/header';
import Splitter from '../../../components/common/splitter';
import { DefaultContentWrapper } from '../../../components/common/wrappers/content';
import { EReportActionType, ReportOfferTypeItem } from '../types';
import ReportPartner from './component';
import { ContainerWrapper } from './controls';
import ReportsPartnerFilter from './filters';
import useReportPartnerDownload from './hooks/useReportPartnerDownload';
import { reportPartnerFilterDataSelector, reportPartnerReportIsFetchingSelector } from './store/selectors';
import { partnerReportsFilterSetOffer, partnerReportsFilterSetPeriod } from './store/slice';

interface ReportPartnerContainerProps {
  readonly partnerId: UUID;
  readonly canExecute?: boolean;
}

const ReportPartnerContainer = (props: ReportPartnerContainerProps) => {
  const { partnerId, canExecute } = props;
  const dispatch = useDispatch();

  const filterData = useSelector(reportPartnerFilterDataSelector);
  const offerType: Nullable<EOfferType> = filterData?.offer?.id ?? null;

  const isOffersReportFetching = useSelector(reportPartnerReportIsFetchingSelector(EReportActionType.Offers));
  const isOffersStatisticReportFetching = useSelector(
    reportPartnerReportIsFetchingSelector(EReportActionType.OffersStatistic)
  );
  const isBookingOrdersStatisticReportFetching = useSelector(
    reportPartnerReportIsFetchingSelector(EReportActionType.BookingOrdersStatistic)
  );

  const reportDownload = useReportPartnerDownload();

  const onOfferChange = (value: Nullable<ReportOfferTypeItem>) => dispatch(partnerReportsFilterSetOffer(value));

  const onPeriodChange = (startDate: Nullable<Moment>, endDate: Nullable<Moment>) => {
    dispatch(
      partnerReportsFilterSetPeriod({
        startDate: startDate?.format(EDateTimeFormat.LongMs) ?? null,
        endDate: endDate?.format(EDateTimeFormat.LongMs) ?? null,
      })
    );
  };

  const onDownloadReport = (type: EReportActionType) => {
    const { offer, startDate, endDate } = filterData;
    const startDateToFormat = moment(startDate)?.format(EDateFormat.Server);
    const endDateToFormat = moment(endDate)?.format(EDateFormat.Server);

    switch (type) {
      case EReportActionType.OffersStatistic:
        reportDownload(
          EReportActionType.OffersStatistic,
          Api.report.mp.offersStatistic({
            startDate: startDateToFormat,
            endDate: endDateToFormat,
            offerType: offer?.id,
            partnerId: partnerId,
          })
        );
        break;
      case EReportActionType.Offers:
        reportDownload(
          EReportActionType.Offers,
          Api.report.mp.offers({
            offerType: offer?.id,
            startDate: startDateToFormat,
            endDate: endDateToFormat,
            partnerId: partnerId,
          })
        );
        break;
      case EReportActionType.BookingOrdersStatistic:
        reportDownload(
          EReportActionType.BookingOrdersStatistic,
          Api.report.mp.bookingOrdersStatistic({
            startDate: startDateToFormat,
            endDate: endDateToFormat,
            partnerId: partnerId,
          })
        );
        break;
      case EReportActionType.Users:
      case EReportActionType.Partners:
        //Not implemented
        break;
    }
  };

  return (
    <Fade in>
      <DefaultContentWrapper
        type='table'
        stickyHeader
      >
        <ContainerWrapper>
          <DefaultHeader headline={<AppBreadcrumbs />}>
            <Typography variant='h2'>Отчеты</Typography>
          </DefaultHeader>
          <Splitter size={3} />
          <ReportsPartnerFilter
            filter={filterData}
            onOfferChange={onOfferChange}
            onPeriodChange={onPeriodChange}
          />
          <Splitter size={4} />
          <ReportPartner
            offerType={offerType}
            isOffersReportFetching={isOffersReportFetching}
            isOffersStatisticReportFetching={isOffersStatisticReportFetching}
            isBookingOrdersStatisticReportFetching={isBookingOrdersStatisticReportFetching}
            onDownload={canExecute ? onDownloadReport : null}
          />
        </ContainerWrapper>
      </DefaultContentWrapper>
    </Fade>
  );
};

export default ReportPartnerContainer;
