import { BookingOrder, ESortDirection } from '@/domain';
import BookingOrderTableCellObjectCode from '@features/bookingOrder/components/tableCell/objectCode';
import BookingOrderTableCellObjectPartner from '@features/bookingOrder/components/tableCell/objectPartner';
import DataTable, { DataTableMetadata, DataTableRow } from '../../../components/common/table';
import TableActionsContainer from '../../../components/common/table/actions/container';
import { useBookingOrderActionsSelectors } from '../actions/useAction';
import BookingOrderTableCellCancelInitiator from '../components/tableCell/cancelInitiator';
import BookingOrderTableCellCancelReason from '../components/tableCell/cancelReason';
import BookingOrderTableCellCost from '../components/tableCell/cost';
import BookingOrderTableCellCreateAt from '../components/tableCell/createAt';
import BookingOrderTableCellCustomerName from '../components/tableCell/customerName';
import BookingOrderTableCellManagerComment from '../components/tableCell/managerComment';
import BookingOrderTableCellNumber from '../components/tableCell/number';
import BookingOrderTableCellObjectName from '../components/tableCell/objectName';
import { BookingOrderActions, BookingOrderActionTableType, EBookingOrderTableColumn } from '../types';
import BookingOffersTableCellAdapter from './adapters/cell';

interface BookingOrderTableProps {
  readonly metadata: DataTableMetadata<EBookingOrderTableColumn>;
  readonly sort: {
    readonly column: string;
    readonly direction: ESortDirection;
  };
  readonly orders: BookingOrder[];
  readonly getActions: (order: BookingOrder) => BookingOrderActions<BookingOrderActionTableType>;
  readonly onRequestSort: (column: EBookingOrderTableColumn, direction: ESortDirection) => void;
  readonly onClick: (order: BookingOrder) => void;
  readonly onAction: (action: BookingOrderActionTableType, order: BookingOrder) => void;
  readonly onChangeMetadata?: (metadata: DataTableMetadata<EBookingOrderTableColumn>) => void;
}

const BookingOrderTable = (props: BookingOrderTableProps) => {
  const { metadata, orders, sort, onRequestSort, onChangeMetadata, onAction, getActions, onClick } = props;

  const { getIsBookingOrderProcessingSelector } = useBookingOrderActionsSelectors();

  const rows: DataTableRow<BookingOrder, EBookingOrderTableColumn>[] = orders?.map((order, index) => {
    return {
      [EBookingOrderTableColumn.CreateAt]: (
        <BookingOffersTableCellAdapter
          index={index}
          component={BookingOrderTableCellCreateAt}
        />
      ),
      [EBookingOrderTableColumn.Number]: (
        <BookingOffersTableCellAdapter
          index={index}
          component={BookingOrderTableCellNumber}
        />
      ),
      [EBookingOrderTableColumn.CustomerName]: (
        <BookingOffersTableCellAdapter
          index={index}
          component={BookingOrderTableCellCustomerName}
        />
      ),
      [EBookingOrderTableColumn.ObjectName]: (
        <BookingOffersTableCellAdapter
          index={index}
          component={BookingOrderTableCellObjectName}
        />
      ),
      [EBookingOrderTableColumn.ObjectCode]: (
        <BookingOffersTableCellAdapter
          index={index}
          component={BookingOrderTableCellObjectCode}
        />
      ),
      [EBookingOrderTableColumn.ObjectPartner]: (
        <BookingOffersTableCellAdapter
          index={index}
          component={BookingOrderTableCellObjectPartner}
        />
      ),
      [EBookingOrderTableColumn.Cost]: (
        <BookingOffersTableCellAdapter
          index={index}
          component={BookingOrderTableCellCost}
        />
      ),
      [EBookingOrderTableColumn.ManagerComment]: (
        <BookingOffersTableCellAdapter
          index={index}
          component={BookingOrderTableCellManagerComment}
        />
      ),
      [EBookingOrderTableColumn.CancelInitiator]: (
        <BookingOffersTableCellAdapter
          index={index}
          component={BookingOrderTableCellCancelInitiator}
        />
      ),
      [EBookingOrderTableColumn.CancelReason]: (
        <BookingOffersTableCellAdapter
          index={index}
          component={BookingOrderTableCellCancelReason}
        />
      ),
      data: order,
    };
  });

  return (
    <DataTable<BookingOrder, EBookingOrderTableColumn>
      width='auto'
      overflowX='inherit'
      metadata={metadata}
      rows={rows}
      rowActions
      sort={{ column: sort.column, direction: sort.direction }}
      onSort={(event, column, direction) => onRequestSort(column as EBookingOrderTableColumn, direction)}
      onRowClick={(event, cell, { data }) => onClick(data)}
      onChangeMetadata={onChangeMetadata}
      getRowActions={order => {
        return (
          <TableActionsContainer<BookingOrder, BookingOrderActionTableType>
            entityLink={order}
            actions={getActions(order)}
            onExecute={onAction}
            isFetchingSelector={getIsBookingOrderProcessingSelector(order.id)}
          />
        );
      }}
    />
  );
};

export default BookingOrderTable;
