import { AnyClientOrg, ClientOrgCreate } from '@/domain';
import { ClientOrgForValidation, ClientOrgValidationRules } from '@features/clientOrg/types';
import validator from 'validator';
import { EValidationMessages, EValidationType, ValidationRules } from '../../../utils/validation';

//правила технической валидации
const clientOrgTechnicalValidationRules: ValidationRules<ClientOrgCreate> = {
  domain: {
    validator: (object, value) => {
      if (!value) return null;

      const isUrl = validator.isURL(value, { require_protocol: false });
      if (!isUrl) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: EValidationMessages.URLInvalid,
        };
      } else {
        return null;
      }
    },
  },
  colorBrand: {
    validator: (object, value) => {
      if (!value) return null;

      const isHexColor = validator.isHexColor(value) && value.startsWith('#');
      if (!isHexColor) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: EValidationMessages.HexColorInvalid,
        };
      } else {
        return null;
      }
    },
  },
  colorPrimary: {
    validator: (object, value) => {
      if (!value) return null;

      const isHexColor = validator.isHexColor(value) && value.startsWith('#');
      if (!isHexColor) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: EValidationMessages.HexColorInvalid,
        };
      } else {
        return null;
      }
    },
  },
};

//правила бизнес валидации
export const clientOrgBusinessValidationRules: ValidationRules<ClientOrgForValidation> = {
  ...clientOrgTechnicalValidationRules,
};

export const clientOrgValidationRulesDraft: ValidationRules<ClientOrgForValidation> = {
  name: {
    ...clientOrgBusinessValidationRules.name,
    required: true,
  },
  code: {
    ...clientOrgBusinessValidationRules.code,
    required: true,
  },
};

export const clientOrgValidationRulesPublish: ValidationRules<ClientOrgForValidation> = {
  ...clientOrgValidationRulesDraft,
  code: {
    ...clientOrgBusinessValidationRules.code,
    required: true,
  },
};

export const getClientOrgValidationRulesForSave = (
  clientOrg: AnyClientOrg | ClientOrgCreate
): ClientOrgValidationRules => {
  const status = clientOrg.status ?? null;
  switch (status) {
    case null:
      return clientOrgValidationRulesDraft;
    default:
      return clientOrgValidationRulesPublish;
  }
};
