import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault } from '@/data/store/types';
import { ETradeOfferType, Nullable, PartnerShort, Target, TradeOffer, TradeOfferRequest, UUID } from '@/domain';
import { ValidationItemResult, ValidationResult } from '@/presentation/utils/validation';
import { TradeOfferCreateUiState } from '@features/tradeOffer/create/types';
import { TradeOfferCreateStepType } from '@features/tradeOffer/types';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';
import offerServices from '../../../general/offer/services';
import { getTradeOfferCreateEmptyOffer } from '../utils';

export const tradeOfferCreateViewed = createAsyncThunk<void, UUID, AppThunkAPIConfig>(
  'tradeOffer/create/viewed',
  async id => {
    try {
      await offerServices.common.makeViewed({ id });
    } catch (e: any) {
      console.error(`Error at call user event`, e);
    }
  }
);

export const tradeOfferCreateByIdFetch = createAsyncThunk<
  { offer: TradeOffer; loadedOffer: Nullable<TradeOffer>; partner: PartnerShort },
  { id: Nullable<UUID>; partnerId: Nullable<UUID> },
  AppThunkAPIConfig
>('tradeOffer/create/byId/fetch', async ({ id, partnerId }, { rejectWithValue, dispatch, signal }) => {
  try {
    if (id) {
      const offer = await offerServices.trade.one({ id, signal });
      const partner = (await Api.partner.one({ id: offer.partner.id, signal })).data;
      dispatch(tradeOfferCreateViewed(id));
      return { offer, loadedOffer: offer, partner };
    } else {
      if (partnerId) {
        const partner = (await Api.partner.one({ id: partnerId, signal })).data;
        const offer = { ...getTradeOfferCreateEmptyOffer(), partner, partnerId };
        return { offer, loadedOffer: null, partner };
      } else {
        // TODO как то криво здесь
        throw new Error(`Не заданы ни идинтификтор предложения ни партнер`);
      }
    }
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);

    return rejectWithValue(e.response.data);
  }
});

export const tradeOfferCreatePromotionsCountFetch = createAsyncThunk<TradeOffer, { id: UUID }, AppThunkAPIConfig>(
  'tradeOffer/create/promotionsCount/fetch',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await offerServices.trade.one({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export type TradeOfferCreateValidationStepper = Nullable<
  Record<TradeOfferCreateStepType, Nullable<ValidationResult<any>>>
>;

type ChangePromotionTypeDataDialogs = {
  name: 'changePromotionType';
  data: Nullable<ETradeOfferType>;
};

type CommandActionsDataDialogs = {
  name: Exclude<keyof TradeOfferCreateState['dialogs'], 'changePromotionType'>;
  data: Nullable<TradeOffer>;
};

export type TradeOfferCreateChangeDialogStateType = CommandActionsDataDialogs | ChangePromotionTypeDataDialogs;

export interface TradeOfferCreateState {
  readonly guid: Nullable<UUID>;
  readonly byId: Fetchable & {
    readonly tradeOffer: TradeOffer;
    readonly loadedData: Nullable<TradeOffer>;
    readonly partner: Nullable<PartnerShort>;
  };
  readonly modified: boolean;
  readonly needSave: boolean;

  readonly ui: Nullable<TradeOfferCreateUiState>;
  readonly dialogs: {
    readonly close: Nullable<TradeOffer>;
    readonly history: Nullable<TradeOffer>;
    readonly codesDelete: Nullable<TradeOffer>;
    readonly changePromotionType: Nullable<ETradeOfferType>;
  };

  readonly validation: ValidationResult<TradeOffer>;
  readonly validationTarget: ValidationResult<Target>;
  readonly validationStepper: TradeOfferCreateValidationStepper;
}

type Reducer<T = undefined> = CaseReducer<TradeOfferCreateState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<TradeOfferCreateState> {
  tradeOfferCreateStartSession: Reducer<{
    guid: Nullable<UUID>;
  }>;
  tradeOfferCreateSetModified: Reducer<boolean>;
  tradeOfferCreateSetNeedSave: Reducer<boolean>;
  tradeOfferCreateApplySavedOffer: Reducer<TradeOffer>;
  tradeOfferCreateClearState: Reducer;
  tradeOfferCreateSetValidationStepper: Reducer<TradeOfferCreateValidationStepper>;
  tradeOfferCreateSetAttribute: Reducer<{ name: keyof TradeOffer; value: any }>;
  tradeOfferCreateSetTargetAttribute: Reducer<{ name: keyof Target; value: any }>;
  tradeOfferCreateRestoreOffer: Reducer;
  tradeOfferCreateSetUiState: Reducer<{
    name: keyof TradeOfferCreateUiState;
    value: any;
  }>;
  tradeOfferCreateClearAllValidations: Reducer;
  tradeOfferCreateClearAttributeValidation: Reducer<keyof TradeOffer>;
  tradeOfferCreateClearTargetAttributeValidation: Reducer<keyof Target>;
  tradeOfferCreateSetAttributeValidation: Reducer<{
    name: keyof TradeOffer;
    results: Nullable<ValidationItemResult>;
  }>;
  tradeOfferCreateSetTargetAttributeValidation: Reducer<{
    name: keyof Target;
    results: Nullable<ValidationItemResult>;
  }>;
  tradeOfferCreateSetValidation: Reducer<Nullable<ValidationResult<TradeOffer>>>;
  tradeOfferCreateSetValidationTarget: Reducer<Nullable<ValidationResult<Target>>>;

  tradeOfferCreateSetDialogState: Reducer<TradeOfferCreateChangeDialogStateType>;
}

const slice = createSlice<TradeOfferCreateState, Reducers, 'create'>({
  name: 'create',
  initialState: {
    guid: null,
    byId: {
      ...fetchableDefault,
      tradeOffer: getTradeOfferCreateEmptyOffer(),
      loadedData: null,
      partner: null,
    },
    modified: false,
    needSave: false,
    ui: null,
    validation: {},
    validationTarget: {},
    validationStepper: null,
    dialogs: { close: null, history: null, changePromotionType: null, codesDelete: null },
  },
  reducers: {
    tradeOfferCreateStartSession: (state, { payload }) => {
      const { guid } = payload;

      if (guid !== state.guid) {
        state.guid = guid;
        state.byId = {
          ...fetchableDefault,
          tradeOffer: getTradeOfferCreateEmptyOffer(),
          loadedData: null,
          partner: null,
        };
        state.modified = false;
        state.needSave = false;

        state.validation = {};
        state.validationTarget = {};
        state.validationStepper = null;
        state.ui = null;
        state.dialogs = { close: null, history: null, changePromotionType: null, codesDelete: null };
      }
    },
    tradeOfferCreateClearState: state => {
      state.validation = {};
      state.validationTarget = {};
      state.validationStepper = null;
      state.ui = null;
      state.dialogs = { close: null, history: null, changePromotionType: null, codesDelete: null };
    },
    // TODO - чекнуть - что делать с валидацией и флагом modified (сечас это как и было) и что делать с needSave
    tradeOfferCreateRestoreOffer: state => {
      if (state.byId.loadedData) {
        state.byId.tradeOffer = { ...state.byId.loadedData };
      }
    },
    tradeOfferCreateSetModified: (state, { payload }) => {
      state.modified = payload;
    },
    tradeOfferCreateSetNeedSave: (state, { payload }) => {
      state.needSave = payload;
    },
    tradeOfferCreateApplySavedOffer: (state, { payload }) => {
      state.byId.tradeOffer = payload;
      state.byId.loadedData = payload;
      state.modified = false;
      state.needSave = false;
    },
    tradeOfferCreateSetValidationStepper: (state, { payload }) => {
      state.validationStepper = payload;
    },
    tradeOfferCreateSetAttribute: (state, { payload }) => {
      const { name, value } = payload;

      if (state.byId.tradeOffer) {
        (state.byId.tradeOffer[name] as keyof TradeOfferRequest) = value;
      }
    },
    tradeOfferCreateSetTargetAttribute: (state, { payload }) => {
      const { name, value } = payload;

      state.modified = true;

      if (state.byId.tradeOffer) {
        const target: Target = state.byId.tradeOffer.target;
        state.byId.tradeOffer.target = { ...target, [name]: value };
      }
    },
    tradeOfferCreateSetUiState: (state, { payload }) => {
      const { name, value } = payload;
      if (!state.ui) {
        state.ui = {
          steps: [],
          fields: {
            visible: [],
            disabled: [],
          },
        };
      }
      state.ui[name] = value;
    },
    tradeOfferCreateClearAllValidations: state => {
      state.validation = {};
      state.validationTarget = {};
    },
    tradeOfferCreateClearAttributeValidation: (state, { payload }) => {
      delete state.validation[payload];
    },
    tradeOfferCreateClearTargetAttributeValidation: (state, { payload }) => {
      delete state.validationTarget[payload];
    },
    tradeOfferCreateSetAttributeValidation: (state, { payload }) => {
      const { name, results } = payload;
      if (!results) {
        delete state.validation?.[name];
      } else {
        state.validation[name] = results;
      }
    },
    tradeOfferCreateSetTargetAttributeValidation: (state, { payload }) => {
      const { name, results } = payload;
      if (!results) {
        delete state.validationTarget[name];
      } else {
        state.validationTarget[name] = results;
      }
    },
    tradeOfferCreateSetValidation: (state, { payload }) => {
      state.validation = payload ?? {};
    },
    tradeOfferCreateSetValidationTarget: (state, { payload }) => {
      state.validationTarget = payload ?? {};
    },
    tradeOfferCreateSetDialogState: (state, { payload }) => {
      const { name, data } = payload;
      if (name === 'changePromotionType') {
        state.dialogs.changePromotionType = data;
      } else {
        state.dialogs[name] = data;
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(tradeOfferCreateByIdFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
      })
      .addCase(tradeOfferCreateByIdFetch.fulfilled, (state, { payload }) => {
        const { offer, loadedOffer, partner } = payload;
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;

        state.byId.tradeOffer = offer;
        state.byId.loadedData = loadedOffer;
        state.byId.partner = partner;
      })
      .addCase(tradeOfferCreateByIdFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = true;
        } else {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = false;
        }
      })
      .addCase(tradeOfferCreatePromotionsCountFetch.fulfilled, (state, { payload }) => {
        state.byId.tradeOffer.status = payload.status;
        state.byId.tradeOffer.offerCount = payload.offerCount;
        state.byId.tradeOffer.notUsedOfferCount = payload.notUsedOfferCount;

        if (state.byId.loadedData) {
          state.byId.loadedData.status = payload.status;
          state.byId.loadedData.offerCount = payload.offerCount;
          state.byId.loadedData.notUsedOfferCount = payload.notUsedOfferCount;
        }
      });
  },
});

export const {
  tradeOfferCreateStartSession,
  tradeOfferCreateClearState,
  tradeOfferCreateSetModified,
  tradeOfferCreateSetNeedSave,
  tradeOfferCreateRestoreOffer,
  tradeOfferCreateSetValidationStepper,
  tradeOfferCreateSetAttribute,
  tradeOfferCreateSetTargetAttribute,
  tradeOfferCreateSetUiState,
  tradeOfferCreateClearAllValidations,
  tradeOfferCreateSetValidation,
  tradeOfferCreateSetValidationTarget,
  tradeOfferCreateClearAttributeValidation,
  tradeOfferCreateClearTargetAttributeValidation,
  tradeOfferCreateSetAttributeValidation,
  tradeOfferCreateSetTargetAttributeValidation,
  tradeOfferCreateApplySavedOffer,
  tradeOfferCreateSetDialogState,
} = slice.actions;

export default slice.reducer;
