import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { MpUser, MpUserData } from '../../../../../domain/model/user';
import { partnerEmployeeEditByIdSelector } from './selectors';

export const partnerEmployeeEditByIdFetch = createAsyncThunk<MpUser, { id: UUID }, AppThunkAPIConfig>(
  'partner/employee/edit/byId/fetch',
  async ({ id }, { rejectWithValue, signal }) => {
    try {
      const { data } = await Api.user.mp.one({ id, signal });
      return data;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);

      return rejectWithValue(e.response.data);
    }
  }
);

export const partnerEmployeeEditUpdate = createAsyncThunk<
  MpUser,
  { id: UUID; partnerEmployee: MpUserData },
  AppThunkAPIConfig
>('partner/employee/edit/update', async ({ id, partnerEmployee }, { getState, rejectWithValue }) => {
  try {
    const { data } = await Api.user.mp.update({ id, data: partnerEmployee });

    if (partnerEmployeeEditByIdSelector(getState()).loadedUser?.email !== partnerEmployee.email) {
      await Api.user.mp.changeEmail({ id, email: partnerEmployee.email });
    }

    return data;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface PartnerEmployeeEditState {
  readonly byId: Fetchable & {
    readonly user: Nullable<MpUser>;
    readonly loadedUser: Nullable<MpUser>;
    readonly modified: boolean;
  };
  readonly save: Fetchable;
}

type Reducer<T = undefined> = CaseReducer<PartnerEmployeeEditState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<PartnerEmployeeEditState> {
  partnerEmployeeEditSetAttribute: Reducer<{ name: keyof MpUserData; value: any }>;
  partnerEmployeeEditReset: Reducer;
}

const slice = createSlice<PartnerEmployeeEditState, Reducers, 'edit'>({
  name: 'edit',
  initialState: {
    byId: {
      ...fetchableDefault,
      user: null,
      loadedUser: null,
      modified: false,
    },
    save: {
      ...fetchableDefault,
    },
  },
  reducers: {
    partnerEmployeeEditSetAttribute: (state, { payload }) => {
      const { name, value } = payload;
      if (state.byId.user) {
        (state.byId.user[name] as keyof MpUserData) = value;
        state.byId.modified = true;
      }
    },
    partnerEmployeeEditReset: state => {
      state.byId = {
        ...fetchableDefault,
        user: null,
        loadedUser: null,
        modified: false,
      };
      state.save = {
        ...fetchableDefault,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(partnerEmployeeEditByIdFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
        state.byId.user = null;

        state.byId.loadedUser = null;
        state.byId.modified = false;
      })
      .addCase(partnerEmployeeEditByIdFetch.fulfilled, (state, { payload }) => {
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;

        state.byId.user = payload;
        state.byId.loadedUser = payload;
      })
      .addCase(partnerEmployeeEditByIdFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = true;
        }

        state.byId.user = null;
        state.byId.loadedUser = null;
      })
      .addCase(partnerEmployeeEditUpdate.pending, state => {
        state.save.isFetching = true;
        state.save.isFetched = false;
        state.save.isFailed = false;
      })
      .addCase(partnerEmployeeEditUpdate.fulfilled, (state, { payload }) => {
        state.save.isFetching = false;
        state.save.isFetched = true;
        state.save.isFailed = false;

        state.byId.user = payload;
        state.byId.loadedUser = payload;
        state.byId.modified = false;
      })
      .addCase(partnerEmployeeEditUpdate.rejected, state => {
        state.save.isFetching = false;
        state.save.isFetched = false;
        state.save.isFailed = true;
      });
  },
});

export const { partnerEmployeeEditReset, partnerEmployeeEditSetAttribute } = slice.actions;

export default slice.reducer;
