import store from '@/data/store/store';
import { ClientOrgCreate } from '@/domain';
import { addSearchParamToLocation } from '@/routing/globalRouting';
import { FCC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import useHistoryExtensions from '../../../../hooks/useHistoryExtensions';
import validateObject from '../../../../hooks/validation/utils';
import { ValidationResult, ValidationRules } from '../../../../utils/validation';
import { useClientOrgActions } from '../../actions/useActions';
import { EClientOrgUrlParam, getClientOrgsTableRoute } from '../../entry';
import { ClientOrgCreateStep, ClientOrgForValidation } from '../../types';
import { showClientOrgInvalidNotification } from '../../utils';
import { clientOrgBusinessValidationRules, getClientOrgValidationRulesForSave } from '../../utils/validation';
import { ClientOrgCreateContainerProps } from '../container';
import { ClientOrgCreateHandlersContext, ClientOrgCreateHandlersContextValue } from '../context';
import {
  clientOrgCreateClientOrgSelector,
  clientOrgCreateModifiedSelector,
  clientOrgCreateUiStepsStateSelector,
  clientOrgCreateValidationResultsSelector,
  clientOrgCreateValidationStepperSelector,
} from '../store/selectors';
import {
  clientOrgCreateApplySavedClientOrg,
  clientOrgCreateClearAllValidations,
  clientOrgCreateClearAttributeValidation,
  clientOrgCreateSetAttribute,
  clientOrgCreateSetAttributeValidation,
  clientOrgCreateSetDialogState,
  clientOrgCreateSetModified,
  clientOrgCreateSetValidation,
  clientOrgCreateSetValidationStepper,
  ClientOrgCreateValidationStepper,
} from '../store/slice';
import { clientOrgCreateInitValidationStepper } from '../utils';

export const ClientOrgCreateHandlersProvider: FCC<ClientOrgCreateContainerProps> = ({ step, children }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const { onSave } = useClientOrgActions();

  const getClientOrgFromState = useCallback((): ClientOrgCreate => {
    const state = store.getState();
    return clientOrgCreateClientOrgSelector(state);
  }, [store]);

  const getModifiedFromState = useCallback((): boolean => {
    const state = store.getState();
    return clientOrgCreateModifiedSelector(state);
  }, [store]);

  const getValidationFromState = useCallback((): ValidationResult<ClientOrgCreate> => {
    const state = store.getState();
    return clientOrgCreateValidationResultsSelector(state);
  }, [store]);

  const getClientOrgStepsFromState = useCallback((): Nullable<ClientOrgCreateStep[]> => {
    const state = store.getState();
    return clientOrgCreateUiStepsStateSelector(state);
  }, [store]);

  const getClientOrgValidationStepperFromState = useCallback((): ClientOrgCreateValidationStepper => {
    const state = store.getState();
    return clientOrgCreateValidationStepperSelector(state);
  }, [store]);

  const onChangeDialogState = useCallback<ClientOrgCreateHandlersContextValue['onChangeDialogState']>(
    (name, clientOrg) => {
      dispatch(clientOrgCreateSetDialogState({ name, data: clientOrg }));
    },
    [dispatch]
  );

  const onChangeModified = useCallback(
    (modified: boolean) => {
      dispatch(clientOrgCreateSetModified(modified));
    },
    [dispatch]
  );

  const onClose = useCallback<ClientOrgCreateHandlersContextValue['onClose']>(() => {
    const isModify = getModifiedFromState();
    const clientOrg = getClientOrgFromState();

    if (isModify) {
      if (clientOrg.id) {
        onChangeDialogState('close', clientOrg);
      } else {
        onChangeDialogState('discard', clientOrg);
      }
    } else {
      gotoPrevIndependentLocation(getClientOrgsTableRoute({}));
    }
  }, [onChangeDialogState, gotoPrevIndependentLocation, getModifiedFromState]);

  const onCloseImmediate = useCallback<ClientOrgCreateHandlersContextValue['onCloseImmediate']>(() => {
    onChangeDialogState('close', null);
    onChangeModified(false);
    onClose();
    onChangeValidationStepper();
  }, [onChangeDialogState, onChangeModified, onClose]);

  const onChangeStep = useCallback<ClientOrgCreateHandlersContextValue['onChangeStep']>(
    newStep => {
      if (newStep.key !== step) {
        history.push(
          addSearchParamToLocation({
            location,
            param: EClientOrgUrlParam.Step,
            value: newStep.key,
          })
        );
      }
    },
    [dispatch, step, history, location]
  );

  const onChangeAttribute = useCallback<ClientOrgCreateHandlersContextValue['onChangeAttribute']>(
    (name, value) => {
      dispatch(clientOrgCreateClearAttributeValidation(name));
      dispatch(clientOrgCreateSetAttribute({ name, value }));
      onChangeModified(true);
      onChangeValidationStepper();
    },
    [dispatch]
  );

  const onAttributeValidate = useCallback<ClientOrgCreateHandlersContextValue['onAttributeValidate']>(
    name => {
      const clientOrg = getClientOrgFromState();

      //не проверяем пустые значения
      if (!clientOrg?.[name]) {
        return;
      }

      const rules = { [name]: clientOrgBusinessValidationRules[name] ?? {} };
      const results = validateObject(clientOrg, rules).results?.[name] ?? null;
      dispatch(clientOrgCreateSetAttributeValidation({ name, results }));

      onChangeValidationStepper();
    },
    [dispatch, getClientOrgFromState]
  );

  // обновляем валидацию для степпера
  const onChangeValidationStepper = useCallback(() => {
    const validation = getValidationFromState();

    dispatch(clientOrgCreateSetValidationStepper(clientOrgCreateInitValidationStepper(validation)));
  }, [dispatch, getValidationFromState]);

  // РК - валидация без списков ответственных, контактов и услуг
  const onValidateClientOrg = useCallback(
    (clientOrg: ClientOrgForValidation, rules: ValidationRules<ClientOrgForValidation>): boolean => {
      //запускаем валидацию бизнес правилам
      const byBusinessValidation = validateObject(clientOrg, clientOrgBusinessValidationRules);

      //запускаем валидацию по целевым правилам
      const byActionValidation = validateObject(clientOrg, rules);

      //сохраняем суммарные результаты валидации
      dispatch(clientOrgCreateSetValidation({ ...byBusinessValidation.results, ...byActionValidation.results }));

      // Обновляем валидацию степпера
      onChangeValidationStepper();

      //факт валидности берём суммарно
      return byBusinessValidation.isValid && byActionValidation.isValid;
    },
    [dispatch, validateObject]
  );

  const goToFirstIsNotValidStep = useCallback<ClientOrgCreateHandlersContextValue['goToFirstIsNotValidStep']>(() => {
    const steps = getClientOrgStepsFromState();
    const stepperValid = getClientOrgValidationStepperFromState();

    if (steps) {
      const firstStepIsNotValid = steps.find(step => stepperValid?.[step.key] !== null);

      if (firstStepIsNotValid) {
        onChangeStep(firstStepIsNotValid);
      }
    }
  }, [dispatch, history, location]);

  // сохранение
  const onClientOrgSave = useCallback<ClientOrgCreateHandlersContextValue['onClientOrgSave']>(async () => {
    const clientOrg = getClientOrgFromState();
    if (!clientOrg) {
      return Promise.resolve(null);
    }

    dispatch(clientOrgCreateClearAllValidations());

    const isModify = getModifiedFromState();
    onChangeModified(false);
    const rules = getClientOrgValidationRulesForSave(clientOrg);

    const isValidByActionRules = onValidateClientOrg(clientOrg, rules);
    if (isValidByActionRules) {
      const response = await onSave(clientOrg).finally(() => {
        if (isModify) {
          onChangeModified(true);
        }
      });
      if (response) {
        dispatch(clientOrgCreateApplySavedClientOrg(response));
      }
      return Promise.resolve(response);
    } else {
      showClientOrgInvalidNotification(clientOrg);
      return Promise.resolve(null);
    }
  }, [dispatch, onValidateClientOrg, onChangeModified, getModifiedFromState]);

  const value: ClientOrgCreateHandlersContextValue = {
    onClose,
    onCloseImmediate,
    onChangeDialogState,
    onChangeStep,
    onChangeAttribute,
    onAttributeValidate,

    onClientOrgSave,

    goToFirstIsNotValidStep,
  };

  return <ClientOrgCreateHandlersContext.Provider value={value}>{children}</ClientOrgCreateHandlersContext.Provider>;
};
