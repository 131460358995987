import { ContentTarget, MultiSelectorPredicate, UUID } from '@/domain';
import { clientOrgRzdCode } from '@features/clientOrg/utils';
import {
  CmsPreviewTarget,
  CmsPreviewTargetAudienceSelectorItem,
  CmsPreviewTargetAudienceSelectorItemType,
  CmsPreviewTargetExportData,
  ECmsPreviewTargetAudienceType,
} from './types';

export const getEmptyCmsPreviewTarget = (): CmsPreviewTarget => ({
  type: null,
  localities: null,
  clientOrgs: null,
  externalUsers: null,
  roads: null,
  orgUnits: null,
  familyMemberOnly: null,
  havingChildFamilyMemberOnly: null,
  tradeUnionMembersOnly: null,
  gender: null,
});

export const openCmsPreviewWindow = (url: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.style.display = 'none';
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const convertCmsPreviewTargetToContentTarget = (target: CmsPreviewTarget): ContentTarget => {
  return {
    type: target.type,
    localities: target.localities,
    roads: target.roads,
    orgUnits: target.orgUnits,
    gender: target.gender,
    clientOrgs: target.clientOrgs,
    externalUsers: target.externalUsers,
    tradeUnionMembersOnly: target.tradeUnionMembersOnly,
    havingChildFamilyMemberOnly: target.havingChildFamilyMemberOnly,
    familyMemberOnly: target.familyMemberOnly,
  };
};

interface ObjectLink {
  readonly id: UUID;
}

type CmsTargetSelectorType = CmsPreviewTarget['roads'] | CmsPreviewTarget['orgUnits'] | CmsPreviewTarget['localities'];

const convertSelectorPredicateToSelectorPredicateRequest = (
  value: CmsTargetSelectorType
): Nullable<MultiSelectorPredicate<ObjectLink>> => {
  return value ? { ...value, in: value.in?.map(item => ({ id: item.id })) ?? null } : null;
};

export const convertCmsPreviewTargetToExportTarget = (target: CmsPreviewTarget): CmsPreviewTargetExportData => {
  return {
    targetType: target.type,
    targetLocalities: convertSelectorPredicateToSelectorPredicateRequest(target.localities),
    targetRoads: convertSelectorPredicateToSelectorPredicateRequest(target.roads),
    targetOrgUnits: convertSelectorPredicateToSelectorPredicateRequest(target.orgUnits),
    targetGender: target.gender,
    targetClientOrgs: convertSelectorPredicateToSelectorPredicateRequest(target.clientOrgs),
    targetExternalUsers: target.externalUsers,
    targetTradeUnionMembersOnly: target.tradeUnionMembersOnly,
    targetHavingChildFamilyMemberOnly: target.havingChildFamilyMemberOnly,
    targetFamilyMemberOnly: target.familyMemberOnly,
  };
};

export const convertContentTargetAttributeToCmsPreviewTargetAttribute = (
  name: keyof ContentTarget
): keyof CmsPreviewTarget => {
  switch (name) {
    case 'type':
      return 'type';
    case 'localities':
      return 'localities';
    case 'orgUnits':
      return 'orgUnits';
    case 'roads':
      return 'roads';
    case 'gender':
      return 'gender';
    case 'clientOrgs':
      return 'clientOrgs';
    case 'externalUsers':
      return 'externalUsers';
    case 'havingChildFamilyMemberOnly':
      return 'havingChildFamilyMemberOnly';
    case 'tradeUnionMembersOnly':
      return 'tradeUnionMembersOnly';
    case 'familyMemberOnly':
      return 'familyMemberOnly';
  }
};

export const getCmsPreviewTargetAudienceSelectorItemExternalUsers = (): CmsPreviewTargetAudienceSelectorItem => ({
  id: CmsPreviewTargetAudienceSelectorItemType.ExternalUsers,
  name: 'Внешние пользователи',
  type: CmsPreviewTargetAudienceSelectorItemType.ExternalUsers,
});

export const getCmsPreviewTargetAudienceType = (
  externalUsers: CmsPreviewTarget['externalUsers'],
  clientOrgs: CmsPreviewTarget['clientOrgs']
): Nullable<ECmsPreviewTargetAudienceType> =>
  clientOrgs?.in![0].code === clientOrgRzdCode
    ? ECmsPreviewTargetAudienceType.Corporate
    : clientOrgs?.in?.length || externalUsers
    ? ECmsPreviewTargetAudienceType.External
    : null;
