import { CapturedUserAttributes } from '@/domain';
import { BrowserOptions } from '@sentry/react';
import { EAppFeature } from '../presentation/types';
import { parseEnv } from '../presentation/utils/env';

export type SentryType = BrowserOptions & {
  readonly tags: Nullable<object>;
  readonly project: Nullable<string>;
  readonly captureUserAttributes: Nullable<CapturedUserAttributes>;
};

type ApiAuthServiceType = {
  readonly clientId: string;
  readonly realm: string;
  readonly grantType: string;
  readonly locale: string;
  readonly idpHint: string;
  readonly url: string;
};

type TinyMCEConfigType = {
  readonly path: string;
};

type SupportInfoCommonType = {
  readonly phones: string;
  readonly emails: string;
};

export type SupportInfoMpType = SupportInfoCommonType;

export type SupportInfoSportManualPaths = {
  readonly systemAdmin: string;
  readonly eventAdmin: string;
  readonly complaintAdmin: string;
};

export type SupportInfoSportType = SupportInfoCommonType & {
  readonly manualPaths: SupportInfoSportManualPaths;
};

export type FeaturesOptions = {
  readonly cms: {
    readonly bannerImageCrop: boolean;
  };
  readonly corpOffer: {
    readonly bannerImageCrop: boolean;
  };
  readonly tradeOffer: {
    readonly bannerImageCrop: boolean;
  };
  readonly productOffer: {
    readonly bannerImageCrop: boolean;
  };
  readonly bookingOffer: {
    readonly bannerImageCrop: boolean;
  };
  readonly activityType: {
    readonly canDictionaryDelete: boolean;
  };
};

export type MapsInfoType = {
  readonly unavailableReason: Nullable<string>;
  readonly yandexApiKey: string;
};

export type AppConfiguratorOptions = {
  readonly apiBase: {
    readonly url: string;
    readonly filesPath: string;
    readonly comPath: string;
    readonly sportPath: string;
    readonly bonusesPath: string;
    readonly userStoragePath: string;
    readonly addressPath: string;
    readonly trustedDomains: string[];
  };
  readonly apiWs: {
    readonly url: string;
    readonly path: string;
    readonly sockjsPath: string;
  };
  readonly sentry: SentryType;
  readonly apiAuthService: ApiAuthServiceType;
  readonly tinyMce: TinyMCEConfigType;
  readonly supportInfo: {
    readonly termsOfUseUrl: string;
    readonly personalDataAgreementUrl: string;
    readonly personalDataPolicyUrl: string;
    readonly mp: SupportInfoMpType;
    readonly sport: SupportInfoSportType;
  };
  readonly privilege: {
    readonly url: string;
    readonly testCmsPath: string;
  };
  readonly debug?: boolean;
  readonly features?: string[];
  readonly featuresOptions?: FeaturesOptions;
  readonly maps?: MapsInfoType;
};

export type AppEnvStatic = {
  readonly apiBase: string;
  readonly apiFilesPath: string;
  readonly apiComPath: string;
  readonly apiSportPath: string;
  readonly apiBonusesPath: string;
  readonly apiUserStoragePath: string;
  readonly apiAddressPath: string;
  readonly trustedDomains: string;

  readonly apiWs: string;
  readonly apiWsPath: string;
  readonly apiWsSockjsPath: string;

  readonly authServiceUrl: string;
  readonly authServiceRealm: string;
  readonly authServiceClientId: string;
  readonly authServiceGrantType: string;
  readonly authServiceLocale: string;
  readonly authServiceIdpHint: string;

  readonly tinyMcePath: string;
  readonly mpSupportPhones: string;
  readonly mpSupportEmails: string;
  readonly supportTermsOfUseUrl: string;
  readonly supportPersonalDataAgreementUrl: string;
  readonly supportPersonalDataPolicyUrl: string;
  readonly sportSupportPhones: string;
  readonly sportSupportEmails: string;
  readonly sportSupportSystemAdminManualPath: string;
  readonly sportSupportEventAdminManualPath: string;
  readonly sportSupportComplaintAdminManualPath: string;

  readonly privilegeUrl: string;
  readonly privilegeTestCmsPath: string;

  readonly debug: string;

  readonly features: string;

  readonly sentryEnabled: string;
  readonly sentryDsn: string;
  readonly sentryTags: string;
  readonly sentryProject: string;
  readonly sentryRelease: string;
  readonly sentryCaptureUserAttributes: string;
  readonly sentryAutoSessionTracking: string;
  readonly sentryEnvironment: string;
  readonly sentryDebug: string;

  readonly featureCmsBannerImageCrop: string;
  readonly featureCorpOfferImageCrop: string;
  readonly featureTradeOfferImageCrop: string;
  readonly featureProductOfferImageCrop: string;
  readonly featureBookingOfferImageCrop: string;
  readonly featureActivityTypeCanDictionaryDelete: string;

  readonly mapsUnavailableReason?: string;
  readonly mapsYandexApiKey: string;
};

type AppEnvRuntime = {
  readonly appVersion: string;
};

export type AppEnv = AppEnvStatic & AppEnvRuntime;

export class AppConfigurator {
  private static instance: AppConfigurator;

  public static getInstance(): AppConfigurator {
    if (!AppConfigurator.instance) {
      AppConfigurator.instance = new AppConfigurator();
    }

    return AppConfigurator.instance;
  }

  private constructor() {}

  private options: AppConfiguratorOptions = AppConfigurator.init();

  private static loadEnv(): AppEnv {
    if (process.env.NODE_ENV === 'test') {
      return {
        appVersion: '',

        apiBase: '',
        apiComPath: '',
        apiSportPath: '',
        apiBonusesPath: '',
        apiUserStoragePath: '',
        apiAddressPath: '',
        apiFilesPath: '',
        apiWs: '',
        apiWsPath: '',
        apiWsSockjsPath: '',
        trustedDomains: '',

        authServiceIdpHint: '',
        authServiceClientId: '',
        authServiceRealm: '',
        authServiceUrl: '',
        authServiceGrantType: '',
        authServiceLocale: '',

        tinyMcePath: '',

        mpSupportPhones: '',
        mpSupportEmails: '',
        supportTermsOfUseUrl: '',
        supportPersonalDataAgreementUrl: '',
        supportPersonalDataPolicyUrl: '',

        sportSupportPhones: '',
        sportSupportEmails: '',
        sportSupportSystemAdminManualPath: '',
        sportSupportEventAdminManualPath: '',
        sportSupportComplaintAdminManualPath: '',

        privilegeUrl: '',
        privilegeTestCmsPath: '',

        debug: '',

        features: '',

        sentryEnabled: '',
        sentryDsn: '',
        sentryTags: '',
        sentryProject: '',
        sentryRelease: '',
        sentryEnvironment: '',
        sentryCaptureUserAttributes: '',
        sentryAutoSessionTracking: '',
        sentryDebug: '',

        featureCmsBannerImageCrop: '',
        featureCorpOfferImageCrop: '',
        featureTradeOfferImageCrop: '',
        featureProductOfferImageCrop: '',
        featureBookingOfferImageCrop: '',
        featureActivityTypeCanDictionaryDelete: '',

        mapsYandexApiKey: '',
      };
    } else {
      const request = new XMLHttpRequest();

      const staticConfigUrl = '/config/app.env';
      const versionFileUrl = '/version.txt';

      let staticOptions: Nullable<AppEnvStatic> = null;
      request.open('GET', staticConfigUrl, false);
      request.send();
      if (request.status === 200) {
        staticOptions = parseEnv<AppEnvStatic>(request.responseText);
        console.log('app static options', staticOptions);
      }

      if (!staticOptions || !Object.keys(staticOptions).length) {
        throw Error(`Not found application static config '${staticConfigUrl}'`);
      }

      let runtimeOptions: AppEnvRuntime = {
        appVersion: '',
      };
      request.open('GET', versionFileUrl, false);
      request.send();
      if (request.status === 200) {
        const version = request.responseText.trim();
        runtimeOptions = { appVersion: version };
        console.log('app version', version);
      }

      return {
        ...staticOptions,
        ...runtimeOptions,
      };
    }
  }

  private static init(): AppConfiguratorOptions {
    const env = AppConfigurator.loadEnv();

    let sentryTags: Nullable<object> = null;
    if (env.sentryTags) {
      try {
        const parsedSentryTags = JSON.parse(env.sentryTags);
        if (typeof parsedSentryTags === 'object') {
          sentryTags = parsedSentryTags;
        } else {
          console.warn('Sentry tags is not object');
        }
      } catch (e) {
        console.warn('Sentry tags is not json format');
      }
    }

    let sentryCaptureUserAttributes: Nullable<string[]> = null;
    if (env.sentryCaptureUserAttributes) {
      try {
        sentryCaptureUserAttributes = env.sentryCaptureUserAttributes.split(',').map(item => item.trim());
      } catch (e) {
        console.warn('Sentry capture user attribute parse error', sentryCaptureUserAttributes);
      }
    }

    return {
      apiBase: {
        url: env.apiBase ?? '',
        filesPath: env.apiFilesPath ?? '',
        comPath: env.apiComPath ?? '',
        sportPath: env.apiSportPath ?? '',
        bonusesPath: env.apiBonusesPath ?? '',
        userStoragePath: env.apiUserStoragePath ?? '',
        addressPath: env.apiAddressPath ?? '',
        trustedDomains: env.trustedDomains?.split(',') ?? [],
      },
      apiWs: {
        url: env.apiWs ?? '',
        path: env.apiWsPath ?? '',
        sockjsPath: env.apiWsSockjsPath ?? '',
      },
      apiAuthService: {
        url: env.authServiceUrl,
        realm: env.authServiceRealm,
        clientId: env.authServiceClientId,
        idpHint: env.authServiceIdpHint ?? '',
        grantType: env.authServiceGrantType,
        locale: env.authServiceLocale,
      },
      tinyMce: {
        path: env.tinyMcePath,
      },
      supportInfo: {
        termsOfUseUrl: env.supportTermsOfUseUrl ?? '',
        personalDataAgreementUrl: env.supportPersonalDataAgreementUrl ?? '',
        personalDataPolicyUrl: env.supportPersonalDataPolicyUrl ?? '',
        mp: {
          phones: env.mpSupportPhones ?? '',
          emails: env.mpSupportEmails ?? '',
        },
        sport: {
          phones: env.sportSupportPhones ?? '',
          emails: env.sportSupportEmails ?? '',
          manualPaths: {
            systemAdmin: env.sportSupportSystemAdminManualPath ?? '',
            eventAdmin: env.sportSupportEventAdminManualPath ?? '',
            complaintAdmin: env.sportSupportComplaintAdminManualPath ?? '',
          },
        },
      },
      debug: env.debug === 'true' || process.env.NODE_ENV === 'development',
      privilege: {
        url: env.privilegeUrl ?? '',
        testCmsPath: env.privilegeTestCmsPath ?? '',
      },
      features: env.features?.split(',') ?? [],
      sentry: {
        enabled: env.sentryEnabled === 'true',
        dsn: env.sentryDsn ?? '',
        debug: env.sentryDebug === 'true',
        tags: sentryTags,
        project: env.sentryProject ?? '',
        release: env.sentryRelease ?? '',
        captureUserAttributes: sentryCaptureUserAttributes,
        autoSessionTracking: env.sentryAutoSessionTracking === 'true',
        environment: env.sentryEnvironment ?? 'production',
      },
      featuresOptions: {
        cms: {
          bannerImageCrop: env.featureCmsBannerImageCrop === 'true',
        },
        corpOffer: {
          bannerImageCrop: env.featureCorpOfferImageCrop === 'true',
        },
        tradeOffer: {
          bannerImageCrop: env.featureTradeOfferImageCrop === 'true',
        },
        productOffer: {
          bannerImageCrop: env.featureProductOfferImageCrop === 'true',
        },
        bookingOffer: {
          bannerImageCrop: env.featureBookingOfferImageCrop === 'true',
        },
        activityType: {
          canDictionaryDelete: env.featureActivityTypeCanDictionaryDelete === 'true',
        },
      },
      maps: {
        unavailableReason: env.mapsUnavailableReason ?? null,
        yandexApiKey: env.mapsYandexApiKey,
      },
    };
  }

  getOptions(): AppConfiguratorOptions {
    return this.options;
  }

  getApiAuthService(): ApiAuthServiceType {
    return this.options?.apiAuthService!;
  }

  getTinyMCEConfig(): TinyMCEConfigType {
    return this.options?.tinyMce!;
  }

  getApiFilesBase() {
    return `${this.options?.apiBase?.url}${this.options?.apiBase?.filesPath}`;
  }

  getApiBonusesBase() {
    return `${this.options?.apiBase?.url}${this.options?.apiBase?.bonusesPath}`;
  }

  getApiComBase() {
    return `${this.options?.apiBase?.url}${this.options?.apiBase?.comPath}`;
  }

  getApiUserStorageBase() {
    return `${this.options?.apiBase?.url}${this.options?.apiBase?.userStoragePath}`;
  }

  getApiSportBase() {
    return `${this.options?.apiBase?.url}${this.options?.apiBase?.sportPath}`;
  }

  getApiWs() {
    return this.options?.apiWs;
  }

  getApiComWs() {
    return `${this.options?.apiWs?.url}${this.options?.apiBase?.comPath}${this.options?.apiWs?.path}`;
  }

  getApiSportWs() {
    return `${this.options?.apiWs?.url}${this.options?.apiBase?.sportPath}${this.options?.apiWs?.path}`;
  }

  getApiAddress() {
    return `${this.options?.apiBase?.url}${this.options?.apiBase?.addressPath}`;
  }

  getSupportInfo() {
    return this.options.supportInfo;
  }

  getPrivilegeTestCms() {
    return `${this.options?.privilege?.url}${this.options?.privilege.testCmsPath}`;
  }

  isDebug() {
    return this.options?.debug ?? false;
  }

  getFeatures() {
    return this.options?.features;
  }

  getFeaturesOptions<Key extends keyof FeaturesOptions>(feature: Key) {
    return this.options?.featuresOptions?.[feature] ?? null;
  }

  getTrustedDomains() {
    return this.options?.apiBase?.trustedDomains;
  }

  getMapsConfig() {
    return this.options?.maps ?? ({} as MapsInfoType);
  }

  hasFeature(feature: EAppFeature) {
    return this.options.features?.includes(feature.toString()) ?? false;
  }
}
