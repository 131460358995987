import { Typography } from '@mui/material';
import { Nullable } from '../../../../../domain/model/types';
import { EReportActionType } from '../../types';
import ReportCard from '../card';

interface ReportCardOffersStatisticProps {
  readonly isFetching?: boolean;
  readonly onDownload: Nullable<(type: EReportActionType) => void>;
}

const ReportCardOffersStatistic = (props: ReportCardOffersStatisticProps) => {
  const { onDownload, isFetching } = props;
  const onOffersStatisticReportDownload = onDownload ? () => onDownload(EReportActionType.OffersStatistic) : null;

  return (
    <ReportCard
      title={<Typography variant='subtitle2'>Статистика по предложениям</Typography>}
      isFetching={isFetching}
      onDownload={onOffersStatisticReportDownload}
    />
  );
};

export default ReportCardOffersStatistic;
