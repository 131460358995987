import { Typography } from '@mui/material';
import { Nullable } from '../../../../../domain/model/types';
import { EReportActionType } from '../../types';
import ReportCard from '../card';

interface ReportCardBookingOrdersStatisticProps {
  readonly isFetching?: boolean;
  readonly onDownload: Nullable<(type: EReportActionType) => void>;
}

const ReportCardBookingOrdersStatistic = (props: ReportCardBookingOrdersStatisticProps) => {
  const { onDownload, isFetching } = props;
  const onBookingOrdersStatisticReportDownload = onDownload
    ? () => onDownload(EReportActionType.BookingOrdersStatistic)
    : null;

  return (
    <ReportCard
      title={<Typography variant='subtitle2'>Статистика по реализации объектов модуля «Досуг и отдых»</Typography>}
      isFetching={isFetching}
      onDownload={onBookingOrdersStatisticReportDownload}
    />
  );
};

export default ReportCardBookingOrdersStatistic;
