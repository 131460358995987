import { CaseReducer, createSelector, createSlice, PayloadAction, Selector, SliceCaseReducers } from '@reduxjs/toolkit';
import { RootState } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { Nullable } from '../../../../../domain/model/types';
import { EReportActionType, EReportDownloadStatus, ReportOfferTypeItem } from '../../types';

type ReportAction = {
  isFetching: boolean;
  type: EReportActionType;
};

export interface PartnerReportsState {
  readonly data: Fetchable & {};
  readonly filterData: Fetchable & {
    readonly offer: Nullable<ReportOfferTypeItem>;
    readonly startDate: Nullable<string>;
    readonly endDate: Nullable<string>;
  };
  readonly actions: ReportAction[];
}

type Reducer<T = undefined> = CaseReducer<PartnerReportsState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<PartnerReportsState> {
  partnerReportsFilterSetOffer: Reducer<Nullable<ReportOfferTypeItem>>;
  partnerReportsReportDownload: Reducer<{ type: EReportActionType; status: EReportDownloadStatus }>;
}

const getActionProcess = (state: PartnerReportsState, actionType: EReportActionType) => {
  let process = state.actions.find(change => change.type === actionType);
  if (process) return process;

  process = {
    isFetching: false,
    type: actionType,
  };
  state.actions.push(process);

  return process;
};

const slice = createSlice<PartnerReportsState, Reducers, 'partner'>({
  name: 'partner',
  initialState: {
    data: {
      ...fetchableDefault,
    },
    filterData: {
      ...fetchableDefault,
      offer: null,
      startDate: null,
      endDate: null,
    },
    actions: [],
  },
  reducers: {
    partnerReportsFilterSetOffer: (state, { payload }) => {
      state.filterData.offer = payload;
    },
    partnerReportsFilterSetPeriod: (state, { payload }) => {
      const { startDate, endDate } = payload;

      state.filterData.startDate = startDate;
      state.filterData.endDate = endDate;
    },
    partnerReportsReportDownload: (state, { payload }) => {
      const { type, status } = payload;
      const process = getActionProcess(state, type);

      switch (status) {
        case EReportDownloadStatus.Pending:
          process.isFetching = true;
          break;
        case EReportDownloadStatus.Finished:
          process.isFetching = false;
          break;
      }
    },
  },
});

export const { partnerReportsFilterSetOffer, partnerReportsFilterSetPeriod, partnerReportsReportDownload } =
  slice.actions;

const partnerReportsActionsSelector: Selector<RootState, ReportAction[]> = state => state.report.admin.actions;
const partnerReportsTypeSelector: Selector<RootState, EReportActionType, [EReportActionType]> = (state, type) => type;

export const createPartnerReportIsFetchingSelector = createSelector(
  partnerReportsActionsSelector,
  partnerReportsTypeSelector,
  (actions, type) => actions.find(action => action.type === type)?.isFetching ?? false
);

export default slice.reducer;
