import { RootState } from '@/data/store/store';
import { Target, TradeOffer } from '@/domain';
import { createSelector } from '@reduxjs/toolkit';

export const tradeOfferCreateGuidSelector = (store: RootState) => store.tradeOffer.create.guid;

export const tradeOfferCreateIsPreloadingSelector = (store: RootState) => store.tradeOffer.create.byId.isFetching;
export const tradeOfferCreateFetchIsFailedSelector = (store: RootState) => store.tradeOffer.create.byId.isFailed;
export const tradeOfferCreateTradeOfferSelector = (store: RootState) => store.tradeOffer.create.byId.tradeOffer;
// TODO - нужно разделить жизненные циклы КП и ТП слишком жирно для LC вся TradeOffer
export const tradeOfferCreateTradeOfferForLifeCycleSelector = (store: RootState) =>
  store.tradeOffer.create.byId.tradeOffer;

// Отдельные селекторы для частоупотребимых атрибутов
export const tradeOfferCreateTradeOfferIdSelector = (store: RootState) => store.tradeOffer.create.byId.tradeOffer.id;
export const tradeOfferCreateTradeOfferPromotionTypeSelector = (store: RootState) =>
  store.tradeOffer.create.byId.tradeOffer.promotionType;
export const tradeOfferCreateTradeOfferPartnerSelector = (store: RootState) => store.tradeOffer.create.byId.partner;
export const tradeOfferCreateTradeOfferOfferCountSelector = (store: RootState) =>
  store.tradeOffer.create.byId.tradeOffer.offerCount;
export const tradeOfferCreateTradeOfferNotUsedOfferCountSelector = (store: RootState) =>
  store.tradeOffer.create.byId.tradeOffer.notUsedOfferCount;
export const tradeOfferCreateTradeOfferStatusSelector = (store: RootState) =>
  store.tradeOffer.create.byId.tradeOffer.status;
export const tradeOfferCreateTradeOfferTargetSelector = (store: RootState) =>
  store.tradeOffer.create.byId.tradeOffer.target;

export const tradeOfferCreateModifiedSelector = (store: RootState) => store.tradeOffer.create.modified;
export const tradeOfferCreateNeedSaveSelector = (store: RootState) => store.tradeOffer.create.needSave;

export const tradeOfferCreateUiStepsStateSelector = (store: RootState) => store.tradeOffer.create.ui?.steps ?? null;

export const tradeOfferCreateTradeOfferFieldByNameSelector =
  <Field extends keyof TradeOffer>(field: Field) =>
  (store: RootState) =>
    store.tradeOffer.create.byId.tradeOffer?.[field];

const tradeOfferCreateIsFieldVisibleSelectorInternal = createSelector(
  (store: RootState) => store.tradeOffer.create.ui?.fields?.visible ?? null,
  (store: RootState, field: keyof TradeOffer) => field,
  (fields, field) => fields && fields?.indexOf(field) > -1
);

export const tradeOfferCreateIsFieldVisibleSelector = (field: keyof TradeOffer) => (store: RootState) =>
  tradeOfferCreateIsFieldVisibleSelectorInternal(store, field);

const tradeOfferCreateIsFieldDisabledSelectorInternal = createSelector(
  (store: RootState) => store.tradeOffer.create.ui?.fields?.disabled ?? null,
  (store: RootState, field: keyof TradeOffer) => field,
  (fields, field) => (fields ? fields?.indexOf(field) > -1 : false)
);

export const tradeOfferCreateIsFieldDisabledSelector = (field: keyof TradeOffer) => (store: RootState) =>
  tradeOfferCreateIsFieldDisabledSelectorInternal(store, field);

export const tradeOfferCreateValidationResultsSelector = (store: RootState) => store.tradeOffer.create.validation;
export const tradeOfferCreateTargetValidationResultsSelector = (store: RootState) =>
  store.tradeOffer.create.validationTarget;
export const tradeOfferCreateValidationResultsByFieldSelector = (field: keyof TradeOffer) => (store: RootState) =>
  store.tradeOffer.create.validation[field] ?? null;
export const tradeOfferCreateTargetValidationResultsByFieldSelector = (field: keyof Target) => (store: RootState) =>
  store.tradeOffer.create.validationTarget[field] ?? null;
export const tradeOfferCreateValidationStepperSelector = (store: RootState) =>
  store.tradeOffer.create.validationStepper;

export const tradeOfferCreateDialogsSelector = (store: RootState) => store.tradeOffer.create.dialogs;
