import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault } from '@/data/store/types';
import {
  BannerPlace,
  CustomerOrderCancellationReasonType,
  DictionaryItem,
  EAdCampaignStatus,
  EAdCampaignType,
  EChatType,
  EClientOrgStatus,
  ECmsBannerLinkObjectType,
  ECmsContainerStatus,
  ECmsContainerType,
  EComplaintObjectType,
  ECorpPersonalPromotionType,
  EExternalCorpOfferType,
  EOfferStatus,
  EOfferType,
  EPartnerOwnershipType,
  EPartnerType,
  EPlaygroundModerationStatus,
  EPlaygroundStatus,
  EPrivilegeActorType,
  EServiceOrderByDateType,
  ESportEventApplicationRejectReason,
  ESportEventApplicationStatus,
  ESportEventClassificationType,
  ESportEventLevel,
  ESportEventParticipationFormat,
  ESportEventStatus,
  ESportEventType,
  ETaxationSystemType,
  ETradeOfferType,
  EUserGender,
  EUserType,
  OfferPausedReason,
  OrderCancellationReasonType,
  SportEnumOption,
  SportOption,
  SportOptionTyped,
  TradePaymentType,
} from '@/domain';
import { createAsyncThunk, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';
import {
  adCampaignStatus,
  adCampaignType,
  clientOrgStatus,
  cmsBannerLinkedObjectType,
  cmsContainerStatus,
  complaintType,
  corpPersonalPromotionType,
  eventApplicationRejectReason,
  eventApplicationStatus,
  eventClassification,
  eventParticipationFormats,
  externalCorpOfferType,
  offerStatus,
  offerType,
  partnerOwnershipType,
  partnerType,
  playgroundModerationStatus,
  privilegeActorType,
  productCountStatus,
  productStatus,
  serviceOrderByDateType,
  taxationSystemType,
  tradeOfferType,
  tradePaymentType,
  userGender,
  userType,
} from '../index';

export const nsiFetch = createAsyncThunk<NsiData, undefined, AppThunkAPIConfig>(
  'nsi/fetch',
  async (props, { rejectWithValue }) => {
    try {
      const [
        playgroundStatusesResponse,
        eventTypesResponse,
        playgroundTypesResponse,
        chatTypesResponse,
        complaintCausesResponse,
        roadsResponse,
        partnerRejectReasonsResponse,
        offerRejectReasonsResponse,
        offerPauseReasonsResponse,
        orderCancelReasonsResponse,
        customerOrderCancelReasonsResponse,
        eventLevelsResponse,
        eventStatusesResponse,
        bannerPlacesResponse,
        cmsContainerTypesResponse,
      ] = await Promise.allSettled<
        [
          Promise<AxiosResponse<DictionaryItem<EPlaygroundStatus>[]>>,
          Promise<AxiosResponse<DictionaryItem<ESportEventType>[]>>,
          Promise<AxiosResponse<SportOption[]>>,
          Promise<AxiosResponse<DictionaryItem<EChatType>[]>>,
          Promise<AxiosResponse<SportOption[]>>,
          Promise<AxiosResponse<SportOption[]>>,
          Promise<AxiosResponse<SportOption[]>>,
          Promise<AxiosResponse<SportOption[]>>,
          Promise<AxiosResponse<OfferPausedReason[]>>,
          Promise<AxiosResponse<OrderCancellationReasonType[]>>,
          Promise<AxiosResponse<CustomerOrderCancellationReasonType[]>>,
          Promise<AxiosResponse<DictionaryItem<ESportEventLevel>[]>>,
          Promise<AxiosResponse<DictionaryItem<ESportEventStatus>[]>>,
          Promise<AxiosResponse<BannerPlace[]>>,
          Promise<AxiosResponse<SportEnumOption<ECmsContainerType>[]>>
        ]
      >([
        Api.nsi.playgroundStatuses(),
        Api.nsi.eventTypes(),
        Api.nsi.playgroundTypes(),
        Api.nsi.chatTypes(),
        Api.nsi.complaintCauses(),
        Api.nsi.roads(),
        Api.nsi.partnerRejectReasons(),
        Api.nsi.offerRejectReasons(),
        Api.nsi.offerPauseReasons(),
        Api.nsi.orderCancelReasons(),
        Api.nsi.customerOrderCancelReasons(),
        Api.nsi.eventLevels(),
        Api.nsi.eventStatuses(),
        Api.nsi.bannerPlaces(),
        Api.nsi.cmsContainerTypes(),
      ]);

      const eventApplicationRejectReasons: SportOptionTyped<ESportEventApplicationRejectReason>[] = Object.values(
        ESportEventApplicationRejectReason
      ).map(item => ({
        ...eventApplicationRejectReason[item],
        code: eventApplicationRejectReason[item].id,
      }));

      const playgroundStatuses: SportOptionTyped<EPlaygroundStatus>[] = [];
      if (playgroundStatusesResponse.status === 'fulfilled') {
        try {
          playgroundStatuses.push(
            ...playgroundStatusesResponse.value.data.map(item => ({
              id: item.code,
              name: item.displayName,
            }))
          );
        } catch (e: any) {
          console.warn('Nsi prepare error', e);
        }
      }

      const eventTypes: SportOptionTyped<ESportEventType>[] = [];
      if (eventTypesResponse.status === 'fulfilled') {
        try {
          eventTypes.push(
            ...eventTypesResponse.value.data.map(item => ({
              code: item.code,
              id: item.code,
              name: item.displayName,
            }))
          );
        } catch (e: any) {
          console.warn('Nsi prepare error', e);
        }
      }

      const playgroundTypes: SportOption[] = [];
      if (playgroundTypesResponse.status === 'fulfilled') {
        try {
          playgroundTypes.push(...playgroundTypesResponse.value.data);
        } catch (e: any) {
          console.warn('Nsi prepare error', e);
        }
      }

      const chatTypes: SportOptionTyped<EChatType>[] = [];
      if (chatTypesResponse.status === 'fulfilled') {
        try {
          chatTypes.push(
            ...chatTypesResponse.value.data.map(item => ({
              code: item.code,
              id: item.code,
              name: item.displayName,
            }))
          );
        } catch (e: any) {
          console.warn('Nsi prepare error', e);
        }
      }

      const bannerPlaces: BannerPlace[] = [];
      if (bannerPlacesResponse.status === 'fulfilled') {
        try {
          bannerPlaces.push(...bannerPlacesResponse.value.data);
        } catch (e: any) {
          console.warn('Nsi prepare error', e);
        }
      }

      const cmsContainerTypes: SportEnumOption<ECmsContainerType>[] = [];
      if (cmsContainerTypesResponse.status === 'fulfilled') {
        try {
          cmsContainerTypes.push(...cmsContainerTypesResponse.value.data);
        } catch (e: any) {
          console.warn('Nsi prepare error', e);
        }
      }

      const complaintCauses: SportOption[] = [];
      if (complaintCausesResponse.status === 'fulfilled') {
        try {
          complaintCauses.push(
            ...complaintCausesResponse.value.data.map(item => ({
              id: item.id,
              code: item.code,
              name: item.name,
            }))
          );
        } catch (e: any) {
          console.warn('Nsi prepare error', e);
        }
      }

      const roads: SportOption[] = [];
      if (roadsResponse.status === 'fulfilled') {
        try {
          roads.push(
            ...roadsResponse.value.data
              .map(item => ({
                id: item.id,
                code: item.code,
                name: item.name,
              }))
              .sort((a, b) => (a.name > b.name ? 1 : -1))
          );
        } catch (e: any) {
          console.warn('Nsi prepare error', e);
        }
      }

      const partnerRejectReasons: SportOption[] = [];
      if (partnerRejectReasonsResponse.status === 'fulfilled') {
        try {
          partnerRejectReasons.push(
            ...partnerRejectReasonsResponse.value.data.map(item => ({
              id: item.id,
              code: item.code,
              name: item.name,
            }))
          );
        } catch (e: any) {
          console.warn('Nsi prepare error', e);
        }
      }

      const offerRejectReasons: SportOption[] = [];
      if (offerRejectReasonsResponse.status === 'fulfilled') {
        try {
          offerRejectReasons.push(
            ...offerRejectReasonsResponse.value.data.map(item => ({
              id: item.id,
              code: item.code,
              name: item.name,
            }))
          );
        } catch (e: any) {
          console.warn('Nsi prepare error', e);
        }
      }

      const offerPauseReasons: OfferPausedReason[] = [];
      if (offerPauseReasonsResponse.status === 'fulfilled') {
        try {
          offerPauseReasons.push(
            ...offerPauseReasonsResponse.value.data.map(item => ({
              id: item.id,
              code: item.code,
              name: item.name,
              system: item.system,
            }))
          );
        } catch (e: any) {
          console.warn('Nsi prepare error', e);
        }
      }

      const orderCancelReasons: OrderCancellationReasonType[] = [];
      if (orderCancelReasonsResponse.status === 'fulfilled') {
        try {
          orderCancelReasons.push(
            ...orderCancelReasonsResponse.value.data.map(item => ({
              id: item.id,
              offerType: item.offerType,
              name: item.name,
            }))
          );
        } catch (e: any) {
          console.warn('Nsi prepare error', e);
        }
      }

      const customerOrderCancelReasons: CustomerOrderCancellationReasonType[] = [];
      if (customerOrderCancelReasonsResponse.status === 'fulfilled') {
        try {
          customerOrderCancelReasons.push(
            ...customerOrderCancelReasonsResponse.value.data.map(item => ({
              id: item.id,
              offerType: item.offerType,
              name: item.name,
            }))
          );
        } catch (e: any) {
          console.warn('Nsi prepare error', e);
        }
      }

      const eventLevels: SportOptionTyped<ESportEventLevel>[] = [];
      if (eventLevelsResponse.status === 'fulfilled') {
        try {
          eventLevels.push(
            ...eventLevelsResponse.value.data.map(item => ({
              ...item,
              id: item.code,
              name: item.displayName,
            }))
          );
        } catch (e: any) {
          console.warn('Nsi prepare error', e);
        }
      }

      const eventStatuses: SportOptionTyped<ESportEventStatus>[] = [];
      if (eventStatusesResponse.status === 'fulfilled') {
        try {
          eventStatuses.push(
            ...eventStatusesResponse.value.data.map(item => ({
              ...item,
              id: item.code,
              name: item.displayName,
            }))
          );
        } catch (e: any) {
          console.warn('Nsi prepare error', e);
        }
      }
      const eventApplicationStatuses: SportOptionTyped<ESportEventApplicationStatus>[] = Object.values(
        ESportEventApplicationStatus
      ).map(item => ({
        ...eventApplicationStatus[item],
        code: eventApplicationStatus[item].id,
      }));

      const userTypes: SportOptionTyped<EUserType>[] = Object.values(EUserType).map(item => ({
        ...userType[item],
        code: userType[item].id,
      }));

      const participationFormats: SportOptionTyped<ESportEventParticipationFormat>[] = Object.values(
        ESportEventParticipationFormat
      ).map(item => ({
        ...eventParticipationFormats[item],
        code: eventParticipationFormats[item].id,
      }));

      const productStatuses: any = Object.values(productStatus);
      const productCountStatuses: any = Object.values(productCountStatus);
      const userGenders: SportOptionTyped<EUserGender>[] = Object.values(userGender);
      const eventClassifications: SportOptionTyped<ESportEventClassificationType>[] =
        Object.values(eventClassification);
      const tradeOfferTypes: SportOptionTyped<ETradeOfferType>[] = Object.values(tradeOfferType);
      const tradePaymentTypes: SportOptionTyped<TradePaymentType>[] = Object.values(tradePaymentType);
      const corpPromotionTypes: SportOptionTyped<ECorpPersonalPromotionType>[] =
        Object.values(corpPersonalPromotionType);
      const externalCorpOfferTypes: SportOptionTyped<EExternalCorpOfferType>[] = Object.values(externalCorpOfferType);
      const partnerOwnerShipTypes: SportOptionTyped<EPartnerOwnershipType>[] = Object.values(partnerOwnershipType);
      const taxationSystemTypes: SportOptionTyped<ETaxationSystemType>[] = Object.values(taxationSystemType);
      const complaintTypes: SportOptionTyped<EComplaintObjectType>[] = Object.values(complaintType);
      const playgroundModerationStatuses: SportOptionTyped<EPlaygroundModerationStatus>[] =
        Object.values(playgroundModerationStatus);
      const partnerTypes: SportOptionTyped<EPartnerType>[] = Object.values(partnerType);
      const offerStatuses: SportOptionTyped<EOfferStatus>[] = Object.values(offerStatus);
      const privilegeActorTypes: SportOptionTyped<EPrivilegeActorType>[] = Object.values(privilegeActorType);
      const cmsContainerStatuses: SportOptionTyped<ECmsContainerStatus>[] = Object.values(cmsContainerStatus);
      const offerTypes: SportOptionTyped<EOfferType>[] = Object.values(offerType);
      const cmsBannerLinkedObjectTypes: SportOptionTyped<ECmsBannerLinkObjectType>[] =
        Object.values(cmsBannerLinkedObjectType);
      const serviceOrderByDateTypes: SportOptionTyped<EServiceOrderByDateType>[] =
        Object.values(serviceOrderByDateType);
      const adCampaignStatuses: SportOptionTyped<EAdCampaignStatus>[] = Object.values(adCampaignStatus);
      const adCampaignTypes: SportOptionTyped<EAdCampaignType>[] = Object.values(adCampaignType);
      const clientOrgStatuses: SportOptionTyped<EClientOrgStatus>[] = Object.values(clientOrgStatus);

      return {
        playgroundStatuses,
        playgroundModerationStatuses,
        playgroundTypes,
        eventTypes,
        eventLevels,
        eventStatuses,
        eventApplicationStatuses,
        eventClassifications,
        productStatuses,
        productCountStatuses,
        chatTypes,
        promotionTypes: tradeOfferTypes,
        corpPromotionTypes,
        tradePaymentTypes,
        externalCorpOfferTypes,
        complaintTypes,
        complaintCauses,
        partnerOwnerShipTypes,
        taxationSystemTypes,
        roads,
        partnerRejectReasons,
        offerRejectReasons,
        offerPauseReasons,
        orderCancelReasons,
        customerOrderCancelReasons,
        userGenders,
        userTypes,
        bannerPlaces,
        partnerTypes,
        offerStatuses,
        privilegeActorTypes,
        cmsContainerTypes,
        cmsContainerStatuses,
        offerTypes,
        cmsBannerLinkedObjectTypes,
        eventApplicationRejectReasons,
        serviceOrderByDateTypes,
        participationFormats,
        adCampaignStatuses,
        adCampaignTypes,
        clientOrgStatuses,
      };
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

interface NsiData {
  readonly playgroundTypes: SportOption[];
  readonly playgroundStatuses: SportOptionTyped<EPlaygroundStatus>[];
  readonly playgroundModerationStatuses: SportOptionTyped<EPlaygroundModerationStatus>[];
  readonly eventTypes: SportOptionTyped<ESportEventType>[];
  readonly eventLevels: SportOptionTyped<ESportEventLevel>[];
  readonly eventStatuses: SportOptionTyped<ESportEventStatus>[];
  readonly eventClassifications: SportOptionTyped<ESportEventClassificationType>[];
  readonly eventApplicationStatuses: SportOptionTyped<ESportEventApplicationStatus>[];
  readonly productStatuses: SportOptionTyped<EOfferStatus>[];
  readonly productCountStatuses: SportOption[];
  readonly chatTypes: SportOptionTyped<EChatType>[];
  readonly promotionTypes: SportOptionTyped<ETradeOfferType>[];
  readonly corpPromotionTypes: SportOptionTyped<ECorpPersonalPromotionType>[];
  readonly tradePaymentTypes: SportOptionTyped<TradePaymentType>[];
  readonly externalCorpOfferTypes: SportOptionTyped<EExternalCorpOfferType>[];
  readonly complaintTypes: SportOptionTyped<EComplaintObjectType>[];
  readonly userGenders: SportOptionTyped<EUserGender>[];
  readonly complaintCauses: SportOption[];
  readonly partnerOwnerShipTypes: SportOptionTyped<EPartnerOwnershipType>[];
  readonly taxationSystemTypes: SportOptionTyped<ETaxationSystemType>[];
  readonly roads: SportOption[];
  readonly partnerRejectReasons: SportOption[];
  readonly offerRejectReasons: SportOption[];
  readonly offerPauseReasons: OfferPausedReason[];
  readonly orderCancelReasons: OrderCancellationReasonType[];
  readonly customerOrderCancelReasons: CustomerOrderCancellationReasonType[];
  readonly userTypes: SportOptionTyped<EUserType>[];
  readonly bannerPlaces: BannerPlace[];
  readonly partnerTypes: SportOptionTyped<EPartnerType>[];
  readonly offerStatuses: SportOptionTyped<EOfferStatus>[];
  readonly privilegeActorTypes: SportOptionTyped<EPrivilegeActorType>[];
  readonly cmsContainerTypes: SportEnumOption<ECmsContainerType>[];
  readonly cmsContainerStatuses: SportOptionTyped<ECmsContainerStatus>[];
  readonly offerTypes: SportOptionTyped<EOfferType>[];
  readonly eventApplicationRejectReasons: SportOptionTyped<ESportEventApplicationRejectReason>[];
  readonly cmsBannerLinkedObjectTypes: SportOptionTyped<ECmsBannerLinkObjectType>[];
  readonly serviceOrderByDateTypes: SportOptionTyped<EServiceOrderByDateType>[];
  readonly participationFormats: SportOptionTyped<ESportEventParticipationFormat>[];
  readonly adCampaignStatuses: SportOptionTyped<EAdCampaignStatus>[];
  readonly adCampaignTypes: SportOptionTyped<EAdCampaignType>[];
  readonly clientOrgStatuses: SportOptionTyped<EClientOrgStatus>[];
}

interface NsiState extends Fetchable {
  data: NsiData;
}

const slice = createSlice<NsiState, SliceCaseReducers<NsiState>, 'nsi'>({
  name: 'nsi',
  initialState: {
    ...fetchableDefault,
    data: {
      playgroundTypes: [],
      playgroundStatuses: [],
      playgroundModerationStatuses: [],
      eventTypes: [],
      eventLevels: [],
      eventStatuses: [],
      eventApplicationStatuses: [],
      eventClassifications: [],
      productStatuses: [],
      productCountStatuses: [],
      chatTypes: [],
      promotionTypes: [],
      corpPromotionTypes: [],
      tradePaymentTypes: [],
      externalCorpOfferTypes: [],
      complaintTypes: [],
      complaintCauses: [],
      partnerOwnerShipTypes: [],
      taxationSystemTypes: [],
      roads: [],
      partnerRejectReasons: [],
      offerRejectReasons: [],
      offerPauseReasons: [],
      orderCancelReasons: [],
      customerOrderCancelReasons: [],
      userGenders: [],
      userTypes: [],
      bannerPlaces: [],
      partnerTypes: [],
      offerStatuses: [],
      privilegeActorTypes: [],
      cmsContainerTypes: [],
      cmsContainerStatuses: [],
      offerTypes: [],
      eventApplicationRejectReasons: [],
      cmsBannerLinkedObjectTypes: [],
      serviceOrderByDateTypes: [],
      participationFormats: [],
      adCampaignStatuses: [],
      adCampaignTypes: [],
      clientOrgStatuses: [],
    },
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(nsiFetch.pending, state => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
        state.data = {
          playgroundTypes: [],
          playgroundStatuses: [],
          playgroundModerationStatuses: [],
          eventTypes: [],
          eventLevels: [],
          eventStatuses: [],
          eventApplicationStatuses: [],
          eventClassifications: [],
          productStatuses: [],
          productCountStatuses: [],
          chatTypes: [],
          promotionTypes: [],
          corpPromotionTypes: [],
          tradePaymentTypes: [],
          externalCorpOfferTypes: [],
          complaintTypes: [],
          complaintCauses: [],
          partnerOwnerShipTypes: [],
          taxationSystemTypes: [],
          roads: [],
          partnerRejectReasons: [],
          offerRejectReasons: [],
          offerPauseReasons: [],
          orderCancelReasons: [],
          customerOrderCancelReasons: [],
          userGenders: [],
          userTypes: [],
          bannerPlaces: [],
          partnerTypes: [],
          offerStatuses: [],
          privilegeActorTypes: [],
          cmsContainerTypes: [],
          cmsContainerStatuses: [],
          offerTypes: [],
          cmsBannerLinkedObjectTypes: [],
          eventApplicationRejectReasons: [],
          serviceOrderByDateTypes: [],
          participationFormats: [],
          adCampaignStatuses: [],
          adCampaignTypes: [],
          clientOrgStatuses: [],
        };
      })
      .addCase(nsiFetch.fulfilled, (state, { payload }) => {
        const {
          playgroundTypes,
          playgroundStatuses,
          playgroundModerationStatuses,
          eventTypes,
          eventLevels,
          eventStatuses,
          eventApplicationStatuses,
          eventClassifications,
          productStatuses,
          productCountStatuses,
          chatTypes,
          promotionTypes,
          corpPromotionTypes,
          tradePaymentTypes,
          externalCorpOfferTypes,
          partnerOwnerShipTypes,
          taxationSystemTypes,
          complaintTypes,
          complaintCauses,
          roads,
          partnerRejectReasons,
          offerRejectReasons,
          offerPauseReasons,
          orderCancelReasons,
          customerOrderCancelReasons,
          userGenders,
          userTypes,
          bannerPlaces,
          partnerTypes,
          offerStatuses,
          privilegeActorTypes,
          cmsContainerTypes,
          cmsContainerStatuses,
          offerTypes,
          cmsBannerLinkedObjectTypes: cmsBnnerLinkedObjectTypes,
          eventApplicationRejectReasons,
          serviceOrderByDateTypes,
          participationFormats,
          adCampaignStatuses,
          adCampaignTypes,
          clientOrgStatuses,
        } = payload;

        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;
        state.data = {
          playgroundTypes,
          playgroundStatuses,
          playgroundModerationStatuses,
          eventTypes,
          eventLevels,
          eventStatuses,
          eventApplicationStatuses,
          eventClassifications,
          productStatuses,
          productCountStatuses,
          chatTypes,
          promotionTypes,
          corpPromotionTypes,
          tradePaymentTypes,
          externalCorpOfferTypes: externalCorpOfferTypes,
          complaintTypes,
          complaintCauses,
          partnerOwnerShipTypes,
          taxationSystemTypes,
          roads,
          partnerRejectReasons,
          offerRejectReasons,
          offerPauseReasons,
          orderCancelReasons,
          customerOrderCancelReasons,
          userGenders,
          userTypes,
          bannerPlaces,
          partnerTypes,
          offerStatuses,
          privilegeActorTypes,
          cmsContainerTypes,
          cmsContainerStatuses,
          offerTypes,
          cmsBannerLinkedObjectTypes: cmsBnnerLinkedObjectTypes,
          eventApplicationRejectReasons,
          serviceOrderByDateTypes,
          participationFormats,
          adCampaignStatuses,
          adCampaignTypes,
          clientOrgStatuses,
        };
      })
      .addCase(nsiFetch.rejected, state => {
        state.isFetching = false;
        state.isFetched = false;
        state.isFailed = true;
        state.data = {
          playgroundTypes: [],
          playgroundStatuses: [],
          playgroundModerationStatuses: [],
          eventTypes: [],
          eventLevels: [],
          eventStatuses: [],
          eventApplicationStatuses: [],
          eventClassifications: [],
          productStatuses: [],
          productCountStatuses: [],
          chatTypes: [],
          promotionTypes: [],
          corpPromotionTypes: [],
          tradePaymentTypes: [],
          externalCorpOfferTypes: [],
          complaintTypes: [],
          complaintCauses: [],
          partnerOwnerShipTypes: [],
          taxationSystemTypes: [],
          roads: [],
          partnerRejectReasons: [],
          offerRejectReasons: [],
          offerPauseReasons: [],
          orderCancelReasons: [],
          customerOrderCancelReasons: [],
          userGenders: [],
          userTypes: [],
          bannerPlaces: [],
          partnerTypes: [],
          offerStatuses: [],
          privilegeActorTypes: [],
          cmsContainerTypes: [],
          cmsContainerStatuses: [],
          offerTypes: [],
          cmsBannerLinkedObjectTypes: [],
          eventApplicationRejectReasons: [],
          serviceOrderByDateTypes: [],
          participationFormats: [],
          adCampaignStatuses: [],
          adCampaignTypes: [],
          clientOrgStatuses: [],
        };
      });
  },
});

export default slice.reducer;
