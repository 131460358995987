export enum ENoticeStatus {
  Info = 'INFO',
  Error = 'ERROR',
  Warning = 'WARNING',
  Success = 'SUCCESS',
}

export enum ETeamMemberType {
  TeamLeader = 'TEAM_LEADER',
  Player = 'PLAYER',
}

export enum ETeamMemberStatus {
  Active = 'ACTIVE',
  LeftTeam = 'LEFT_TEAM',
  Removed = 'REMOVED',
  NotActive = 'NOT_ACTIVE',
}

export enum ETeamMemberInviteStatus {
  Invited = 'INVITED',
  InviteAccepted = 'INVITE_ACCEPTED',
  InviteRejected = 'INVITE_REJECTED',
  InviteRevoked = 'INVITE_REVOKED',
  ApplicationAdded = 'APPLICATION_ADDED',
  ApplicationRevoked = 'APPLICATION_REVOKED',
  ApplicationRejected = 'APPLICATION_REJECTED',
  Approved = 'APPROVED',
}

export enum ESportEventType {
  SportsFest = 'SPORTS_FEST',
  Tournament = 'TOURNAMENT',
  TournamentIndividual = 'TOURNAMENT_INDIVIDUAL',
  FriendlyMatch = 'FRIENDLY_MATCH',
  ActivityEvent = 'ACTIVITY_EVENT',
}

export enum ESimpleSportEventType {
  Tournament = 'TOURNAMENT_ANY',
  SportsFest = 'SPORTS_FEST',
  FriendlyMatch = 'FRIENDLY_MATCH',
  ActivityEvent = 'ACTIVITY_EVENT',
}

export enum ESportEventStatus {
  Draft = 'DRAFT',
  Announced = 'ANNOUNCED',
  ApplicationsCollecting = 'APPLICATIONS_COLLECTING',
  InvitationsDistribution = 'INVITATIONS_DISTRIBUTION',
  WaitingForStart = 'WAITING_FOR_START',
  InProgress = 'IN_PROGRESS',
  SummingUp = 'SUMMING_UP',
  Summarizing = 'SUMMARIZING',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
}

export enum ESportEventTeamStatus {
  InviteConsideration = 'INVITE_CONSIDERATION',
  InviteRevoked = 'INVITE_REVOKED',
  InviteRejected = 'INVITE_REJECTED',
  ApplicationApproval = 'APPLICATION_APPROVAL',
  ApplicationCancelled = 'APPLICATION_CANCELLED',
  ApplicationRejected = 'APPLICATION_REJECTED',
  ApplicationRevoked = 'APPLICATION_REVOKED',
  Participation = 'PARTICIPATION',
}

export enum ESportsFestCombinedTeamStatus {
  New = 'NEW',
  Forming = 'FORMING',
  Formed = 'FORMED',
}

export enum ESportEventLevel {
  NoLevel = 'NO_LEVEL',
  Hub = 'HUB',
  Road = 'ROAD',
  Federal = 'FEDERAL',
}

export enum ESportEventClassificationType {
  Male = 'MALE',
  Female = 'FEMALE',
  Mixed = 'MIXED',
}

export enum ESportEventDocType {
  EventRegulations = 'EVENT_REGULATIONS',
  EventApplicationForm = 'EVENT_APPLICATION_FORM',
  EventApplication = 'EVENT_APPLICATION',
}

export enum ESportEventApplicationStatus {
  New = 'NEW',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
}

export enum EClientSystemType {
  Web = 'WEB',
}

export enum EUserRole {
  SystemAdmin = 'system_admin',
  EventAdmin = 'event_admin',
  ComplaintAdmin = 'complaint_admin',
  CorpUser = 'corp_user',
  User = 'user',
  Customer = 'customer',

  DefaultMp = 'default-roles-mp',
  AdminMp = 'admin_mp',
  AdminMpReadOnly = 'admin_mp_read_only',
  AdminMpCorp = 'admin_mp_corp',
  AdminPartner = 'admin_partner',
  ManagerPartner = 'manager',
}

export enum ELocationType {
  Point = 'POINT',
}

export enum EUserGender {
  Male = 'MALE',
  Female = 'FEMALE',
}

export enum EOrderItemStatus {
  /** новый */
  New = 'NEW',
  /** подтвержден */
  Confirmed = 'CONFIRMED',
  /** возврат полный */
  Returned = 'RETURNED',
  /** отменен */
  Cancelled = 'CANCELLED',
  /** получен клиентом */
  Given = 'GIVEN',
  /** изменён */
  Changed = 'CHANGED',
  /** получено частично */
  PartiallyReceived = 'PARTIALLY_RECEIVED',
}

export enum EPlaygroundStatus {
  Available = 'AVAILABLE',
  TemporaryDisabled = 'TEMPORARY_DISABLED',
  Closed = 'CLOSED',
  Deleted = 'DELETED',
}

export enum EPlaygroundModerationStatus {
  Draft = 'DRAFT',
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
}

export enum EPresenceStatus {
  WaitingConfirmation = 'WAITING_CONFIRMATION',
  PresenceConfirmed = 'PRESENCE_CONFIRMED',
  PresenceNotConfirmed = 'PRESENCE_NOT_CONFIRMED',
}

export enum EChatType {
  PeerToPeer = 'PEER_TO_PEER',
  ForTeam = 'FOR_TEAM',
  AboutEvent = 'ABOUT_EVENT',
  TeamToTeam = 'TEAM_TO_TEAM',
  ForCommunity = 'FOR_COMMUNITY',
  ForCombinedTeam = 'FOR_COMBINED_TEAM',
  AboutSportsFest = 'ABOUT_SPORTS_FEST',
}

export enum EChatStatus {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
}

export enum EMessageType {
  Regular = 'REGULAR',
  Info = 'INFO',
}

export enum EComplaintObjectType {
  Team = 'TEAM',
  User = 'USER',
  FriendlyMatch = 'FRIENDLY_MATCH',
  Community = 'COMMUNITY',
  Tournament = 'TOURNAMENT',
  SportsFest = 'SPORTS_FEST',
  ActivityEvent = 'ACTIVITY_EVENT',
  Chat = 'CHAT',
  Playground = 'PLAYGROUND',
}

export enum EFriendlyMatchCandidateStatus {
  Invited = 'INVITED',
  InviteAccepted = 'INVITE_ACCEPTED',
  InviteRejected = 'INVITE_REJECTED',
  InviteRevoked = 'INVITE_REVOKED',
  ApplicationAdded = 'APPLICATION_ADDED',
  ApplicationRejected = 'APPLICATION_REJECTED',
  Approved = 'APPROVED',
}

export enum EOrderStatus {
  New = 'NEW',
  Given = 'GIVEN',
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  Returned = 'RETURNED',
  Paid = 'PAID', // - оплачен
  Sent = 'SENT', // отправлен
  PartiallyReturned = 'PARTIALLY_RETURNED', // - возврат частичный
}

export enum ETradeOfferType {
  PublicPromoCode = 'PUBLIC_PROMOCODE',
  PromoCode = 'PROMOCODE',
  Voucher = 'VOUCHER',
  ReferralLink = 'REFERRAL_LINK',
  Widget = 'WIDGET',
  AccessCode = 'ACCESS_CODE',
  Asp = 'ASP',
  DiGift = 'DIGIFT',
}

type TradePersonalPromotionType =
  | ETradeOfferType.PromoCode
  | ETradeOfferType.Voucher
  | ETradeOfferType.AccessCode
  | ETradeOfferType.Asp;

export enum ECorpPersonalPromotionType {
  Certificate = 'CORP_CERTIFICATE',
  ExternalCertificate = 'EXTERNAL_CORP_CERTIFICATE',
}

export enum EExternalCorpOfferType {
  Fitness = 'FITNES',
  Spa = 'SPA',
  Child = 'CHILD',
}

export type PersonalPromotionType = TradePersonalPromotionType | ECorpPersonalPromotionType;

export enum EOfferActivationStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Given = 'GIVEN',
  Rejected = 'REJECTED',
}

export enum EOfferActivationDiscriminator {
  TradeOffer = 'TradeOffer',
  CorpOffer = 'CorpOffer',
}

export enum EOfferActivationTypeDiscriminator {
  PersonalPromotionActivation = 'PersonalPromotionActivation',
  PublicActivation = 'PublicActivation',
}

export enum EOfferActivationActionDiscriminator {
  RejectActivationCommand = 'RejectActivationCommand',
  CorpOfferActivationSetGivenCommand = 'CorpOfferActivationSetGivenCommand',
  CorpOfferActivationUnsetGivenCommand = 'CorpOfferActivationUnsetGivenCommand',
}

export enum ETransactionType {
  Credit = 'CREDIT',
  Debit = 'DEBIT',
}

export enum ETransactionStatus {
  Pending = 'PENDING',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
}

export enum EUserStatus {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
  Deleted = 'DELETED',
  NotFilled = 'NOT_FILLED',
}

export enum EOfferPausedReason {
  PausedByAdmin = 'PAUSED_BY_ADMIN',
  PausedByPartner = 'PAUSED_BY_PARTNER',
  EndOfCodes = 'END_OF_CODES',
  PausedAfterCodesAdding = 'PAUSED_AFTER_CODES_ADDING',
}

export enum EOfferStatus {
  Active = 'ACTIVE', // Опубликованные
  Archived = 'ARCHIVED', // Архив
  Draft = 'DRAFT', // Черновики
  OnModeration = 'ON_MODERATION', // На проверке
  Paused = 'PAUSED', // Приостановленные
  ReadyForSale = 'OUT_OF_STOCK', // Готовы к продаже
  Rejected = 'REJECTED', // Отклоненные
  Upcoming = 'UPCOMING', // Одобрено
  WaitingForModeration = 'WAITING_FOR_MODERATION', // Ожидают проверку
}

export enum ETaxationSystemType {
  OSNO = 'OSNO',
  USN = 'USN',
}

export enum EComplaintStatus {
  New = 'NEW',
  UnderConsideration = 'UNDER_CONSIDERATION',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
}

export enum ESortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export enum EProductCountStatus {
  InStock = 'IN_STOCK',
  WillEndSoon = 'WILL_END_SOON',
  OutOfStock = 'OUT_OF_STOCK',
}

export enum EPartnerOwnershipType {
  IndividualEntrepreneur = 'INDIVIDUAL_ENTREPRENEUR',
  LimitedLiabilityCompany = 'LIMITED_LIABILITY_COMPANY',
  StockCompany = 'STOCK_COMPANY',
  PublicJoinStockCompany = 'PUBLIC_JOIN_STOCK_COMPANY',
  PK = 'PK',
  UP = 'UP',
  FGBNU = 'FGBNU',
  NonProfitOrganization = 'NONPROFIT_ORGANIZATION',
  ClosedJoinStockCompany = 'CLOSED_JOIN_STOCK_COMPANY',
  JoinStockCompany = 'JOIN_STOCK_COMPANY',
  NAO = 'NAO',
  AutonomousNonProfitOrganization = 'AUTONOMOUS_NONPROFIT_ORGANIZATION',
}

export enum EPartnerDiscriminator {
  PartnerIndividualData = 'PartnerIndividualData',
  PartnerCompanyData = 'PartnerCompanyData',
}

export enum ETradeOfferDiscriminator {
  TradeOfferDataRequest = 'TradeOfferDataRequest',
  NewVersionTradeOfferCommand = 'NewVersionTradeOfferCommand',
  CreateDraftTradeOfferCommand = 'CreateDraftTradeOfferCommand',
}

export enum EProductOfferDiscriminator {
  ProductOfferDataRequest = 'ProductOfferDataRequest',
  ProductOfferCopyCommand = 'ProductOfferCopyCommand',
  ProductOfferDraftUpdateRequest = 'ProductOfferDraftUpdateRequest',
  ValidProductOfferRequest = 'ValidProductOfferRequest',
}

export enum ECorpOfferDiscriminator {
  CorpOfferDataRequest = 'CorpOfferDataRequest',
  CreateDraftCorpOfferCommand = 'CreateDraftCorpOfferCommand',
}

export enum EBookingOfferDiscriminator {
  NewBookingOfferRequest = 'NewBookingOfferRequest',
  BookingOfferCopyCommand = 'BookingOfferCopyCommand',
  BookingOfferDraftUpdateRequest = 'BookingOfferDraftUpdateRequest',
  ValidBookingOfferRequest = 'ValidBookingOfferRequest',
}

export enum EPartnerType {
  Inner = 'INNER_PARTNER',
  External = 'EXTERNAL_PARTNER',
}

export enum EPartnerStatus {
  Unverified = 'UNVERIFIED',
  ToVerification = 'TO_VERIFICATION',
  OnVerification = 'ON_VERIFICATION',
  Rejected = 'REJECTED',
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
  Deleted = 'DELETED',
}

export type EPartnerStatusNotActive =
  | EPartnerStatus.Deleted
  | EPartnerStatus.Disabled
  | EPartnerStatus.Rejected
  | EPartnerStatus.Unverified
  | EPartnerStatus.OnVerification
  | EPartnerStatus.ToVerification;

export enum EFriendlyMatchResultsApprovalStatus {
  Approval = 'APPROVAL',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
}

export enum EUserType {
  Any = 'ANY',
  Corporate = 'CORPORATE',
}

export enum EPaymentType {
  Free = 'FREE',
  BenefitRzdPoint = 'BENEFIT_RZD_POINT',
  Rub = 'RUB',
  AspRzdRub = 'ASP_RZD_RUB',
}

export type TradePaymentType = EPaymentType.Free | EPaymentType.AspRzdRub;

export enum EAuthAction {
  CheckSsoSuccess = 'check-sso-success',
}

export enum EAuthError {
  NotAllowed = 'not_allowed',
  UnknownIdentityProvider = 'unknown_identity_provider',
  NotLoggedIn = 'not_logged_in',
}

export interface AuthAction {
  readonly message: EAuthAction;
  readonly error?: EAuthError;
}

export enum EPeriod {
  Week = 'week',
  Month = 'month',
  Quarter = 'quarter',
  Year = 'year',
}

export enum EOfferType {
  Trade = 'TRADE_OFFER',
  Corp = 'CORP_OFFER',
  Product = 'PRODUCT_OFFER',
  Booking = 'BOOKING_OFFER',
}

// разделы приложения
export enum ESecuredPartition {
  Partners = 'partners',
  PartnerDesks = 'partnerDesks',
  PartnerNotifications = 'partnerNotifications',
  ProductOffers = 'productOffers',
  TradeOffers = 'tradeOffers',
  TradeCategories = 'tradeCategories',
  ActivityTypes = 'activityTypes',
  BookingOfferCategories = 'bookingOfferCategories',
  BookingServiceCategories = 'bookingServiceCategories',
  BookingOfferPriceUnits = 'bookingOfferPriceUnits',
  CorpOffers = 'corpOffers',
  CorpCategories = 'corpCategories',
  BookingOffers = 'bookingOffers',
  Customers = 'customers',
  Orders = 'orders',
  CorpActivations = 'corpActivations',
  TradeActivations = 'tradeActivations',
  PartnerEmployees = 'partnerEmployees',
  AdminsMp = 'adminsMp',
  Playgrounds = 'playgrounds',
  Events = 'events',
  Products = 'products',
  Complaints = 'complaints',
  Chats = 'chats',
  Teams = 'teams',
  Reports = 'reports',
  Banners = 'banners',
  Configuration = 'configuration',
  PartnerWindow = 'partnerWindow',
  LandingWindow = 'landingWindow',
  Cms = 'cms',
  AnalyticsQueries = 'analyticsQueries',
  AdCampaigns = 'adCampaigns',
  ClientOrgs = 'clientOrgs',
  ClientOrgsUsers = 'clientOrgUsers',
  SocialPackages = 'socialPackages',
}

export enum EBannerPlace {
  TradeOffersListPageTop = 'TRADE_OFFERS_LIST_PAGE_TOP',
  CorpOffersListPageTop = 'CORP_OFFERS_LIST_PAGE_TOP',
}

export enum EBannerPartition {
  TradeOffers = 'trade-offers',
  CorpOffers = 'corp-offers',
}

export enum ETargetType {
  Geo = 'GEO',
  Corp = 'CORP',
}

export enum EOrderPartition {
  ProductOffers = 'product-offers',
  BookingOffers = 'booking-offers',
}

export enum EBannerStatus {
  Draft = 'DRAFT',
  Upcoming = 'UPCOMING',
  Active = 'ACTIVE',
  Paused = 'PAUSED',
  Archived = 'ARCHIVED',
}

export enum EPartnerPermission {
  TradeOfferPublic = 'TRADE_OFFER_PUBLIC',
  CorpOfferPublic = 'CORP_OFFER_PUBLIC',
  ProductOfferPublic = 'PRODUCT_OFFER_PUBLIC',
  BookingOfferPublic = 'BOOKING_OFFER_PUBLIC',
}

export enum EChatObjectType {
  CreateChatMessageCommand = 'CREATE_CHAT_MESSAGE_COMMAND',
  DeleteChatMessageCommand = 'DELETE_CHAT_MESSAGE_COMMAND',
  ChatMessageEvent = 'CHAT_MESSAGE_EVENT',
  DeleteMessageChatEvent = 'DELETE_MESSAGE_CHAT_EVENT',
  AddToChatEvent = 'ADD_TO_CHAT_EVENT',
  RemoveChatEvent = 'REMOVE_CHAT_EVENT',
  UserLeftChatEvent = 'USER_LEFT_CHAT_EVENT',
  ErrorEvent = 'ERROR_EVENT',
}

export enum EChatMessageStatus {
  Received = 'RECEIVED',
  Delivered = 'DELIVERED',
  Read = 'READ',
  Sending = 'Sending',
}

export enum EPersonalPromotionStatus {
  Free = 'FREE',
  Used = 'USED',
  Disabled = 'DISABLED',
}

export enum EPrivilegeActorType {
  System = 'SYSTEM',
  Admin = 'ADMIN',
  Partner = 'PARTNER',
  User = 'USER',
}

export enum ECmsContainerType {
  Banner1 = 'BANNER_1',
  Banner2 = 'BANNER_2',
  Banner3 = 'BANNER_3',
  Banner4 = 'BANNER_4',
  Banner4High = 'BANNER_4_HIGH',
  CompilationOffer = 'COMPILATION_OFFER',
  CompilationPartner = 'COMPILATION_PARTNER',
  Banner3Carousel = 'BANNER_3_CAROUSEL',
  Banner1Resizable = 'BANNER_1_RESIZABLE',
  Banner3Resizable = 'BANNER_3_RESIZABLE',
  Category4Offer = 'CATEGORY_4_OFFER',
  Banner2MobileWide = 'BANNER_2_MOBILE_WIDE',
  Banner1LinkResizable = 'BANNER_1_LINK_RESIZABLE',
}

export enum ECmsContainerStatus {
  Draft = 'DRAFT',
  Upcoming = 'UPCOMING',
  Active = 'ACTIVE',
  Paused = 'PAUSED',
  Archived = 'ARCHIVED',
}

export enum ECmsLinkObjectType {
  Banner = 'BANNER',
  Collection = 'COLLECTION',
}

export enum ECmsBannerLinkObjectType {
  TradeOffer = 'TRADE_OFFER',
  CorpOffer = 'CORP_OFFER',
  Collection = 'COLLECTION',
  CorpOfferCategory = 'CORP_OFFER_CATEGORY',
  TradeOfferCategory = 'TRADE_OFFER_CATEGORY',
  Link = 'LINK',
}

export enum ECmsCollectionLinkObjectType {
  Partner = 'PARTNER',
  TradeOffer = 'TRADE_OFFER',
  CorpOffer = 'CORP_OFFER',
}

export enum EEventCollectionLinkObjectType {
  Playground = 'PLAYGROUND',
}

export enum ESportEventApplicationRejectReason {
  Requirement = 'REQUIREMENT',
  Other = 'OTHER',
}

export enum ECmsContainerDiscriminator {
  ContainerDraftUpdateRequest = 'ContainerDraftUpdateRequest',
  ValidContainerUpdateRequest = 'ValidContainerUpdateRequest',
  ContainerCreateRequestData = 'ContainerCreateRequestData',
  ContainerCopyLink = 'ContainerCopyLink',
}

export enum ECmsContainerCommandDiscriminator {
  ContainerPublishCommand = 'ContainerPublishCommand',
  ContainerToEditCommand = 'ContainerToEditCommand',
  ContainerPauseCommand = 'ContainerPauseCommand',
  ContainerArchiveCommand = 'ContainerArchiveCommand',
}

export enum EProductDeskActionDiscriminator {
  ProductPauseCommand = 'ProductPauseCommand',
  ProductRenewCommand = 'ProductRenewCommand',
  ProductArchiveCommand = 'ProductArchiveCommand',
  ProductBackToDraftCommand = 'ProductBackToDraftCommand',
}

export enum EOrderDiscriminator {
  ProductOrder = 'ProductOrder',
  BookingOrder = 'BookingOrder',
}

export enum EOrderActionDiscriminator {
  OrderChangeStatusCommand = 'OrderChangeStatusCommand',
  PartnerUpdateProductOrderCommand = 'PartnerUpdateProductOrderCommand',
  UpdateActivationOrderCommand = 'UpdateActivationOrderCommand',
  UpdateBookingOrderCommand = 'UpdateBookingOrderCommand',
  OrderCancelCommand = 'OrderCancelCommand',
  CustomerOrderCancelCommand = 'CustomerOrderCancelCommand',
}

export enum ESearchQueryTypeDiscriminator {
  Query = 'query',
  Geo = 'geo',
  Counter = 'counter',
}

export enum ESearchQueryStatDiscriminator {
  Keyword = 'SearchQueryKeywordStat',
  Geo = 'SearchQueryGeoStat',
}

export enum EProductAttributeType {
  String = 'string',
  Integer = 'integer',
  File = 'file',
}

export enum EProductOrderPaymentType {
  Cash = 'CASH',
  Card = 'CARD',
  Invoice = 'INVOICE',
  CashOrCard = 'CASH_OR_CARD',
  Other = 'OTHER',
}

export enum EDeliveryType {
  Self = 'SELF',
  Delivery = 'DELIVERY',
  SelfOrDelivery = 'SELF_OR_DELIVERY',
  Other = 'OTHER',
}

export enum EProductOfferActionDiscriminator {
  // Перевод предложения партнером на модерацию
  OfferToModerationCommand = 'OfferToModerationCommand',

  // Снятие с модерации предложения партнёром Возврат из статуса WAITING_FOR_MODERATION в DRAFT!
  OfferBackToDraftCommand = 'OfferBackToDraftCommand',

  // Назначение модератора
  OfferAssignModeratorCommand = 'OfferAssignModeratorCommand',
  // Успешное завершение модерации предложения админом переход в следующий статус
  OfferApproveCommand = 'OfferApproveCommand',

  // Отклонение предложения модератором и отправка его на доработку
  OfferRejectCommand = 'OfferRejectCommand',

  // Приостановка(перевод в статус PAUSED)
  OfferPauseCommand = 'OfferPauseCommand',

  // Возобновление предложения после приостановки
  OfferRenewCommand = 'OfferRenewCommand',

  // Архивация предложения
  OfferArchiveCommand = 'OfferArchiveCommand',

  // Команда на изменение цены Допустима в любом статусе
  ProductOfferChangePriceCommand = 'ProductOfferChangePriceCommand',

  // Команда на изменение количества товара в наличии
  ProductOfferChangeStockCommand = 'ProductOfferChangeStockCommand',
}

export enum EUserCreateFlow {
  // саморегистрация партнёра
  PartnerSelfRegistration = 'partnerSelfRegistration',
  // регистрация партнёра админом
  PartnerCreate = 'partnerCreate',
}

/**
 * Адресный уровень элемента в виде номера(уровень в ГАР)
 *
 * @variation 1 - Субъект РФ
 * @variation 2 - Административный район
 * @variation 3 - Муниципальный район
 * @variation 4 - Сельское/городское поселение
 * @variation 5 - Город
 * @variation 6 - Населенный пункт
 * @variation 7 - Элемент планировочной структуры
 * @variation 8 - Элемент улично-дорожной сети
 * @variation 9 - Земельный участок
 * @variation 10 - Здание
 * @variation 11 - Помещение
 * @variation 12 - Помещения в пределах помещения
 * @variation 17 - Машино-место
 */
export enum EAddressLevel {
  /** 1 - Субъект РФ */
  Region = 1,
  /**  2 - Административный район */
  AdministrativeRegion = 2,
  /** 3 - Муниципальное образование (в т.ч. городской округ) */
  Municipality = 3,
  /** 4 - Сельское/городское поселение (по сути это некое образование, которое может включать несколько населенных пунктов) */
  Area = 4,
  /** 5 - Город */
  City = 5,
  /** 6 - Населенный пункт */
  Settlement = 6,
  /** 65 - Элемент планировочной структуры - особый вариант */
  PlanningStructureVariant = 65,
  /** 7 - Элемент планировочной структуры (микрорайоны, снт, днт и т.д.) */
  PlanningStructure = 7,
  /** 8 - Элемент улично-дорожной сети (улица) */
  Street = 8,
  /** 9 - Земельный участок */
  Stead = 9,
  /** 10 - Здание/дом */
  House = 10,
  /** 11 - Помещение/квартира */
  Apartment = 11,
  /** 12 - Помещения в пределах помещения */
  SubApartment = 12,
  /** 17 - Машино-место */
  Parking = 17,
}

// Адресные опции для отображения
export enum EAddressOption {
  // Почтовый индекс
  PostalCode,
}

export enum EFilterMultiSelectorValueType {
  All = 'all',
  In = 'in',
  None = 'none',
}

// Оператор утверждения(all - все значения перечисления, in - подмножество)
export enum EMultiSelectorValueType {
  All = 'all',
  In = 'in',
}

// Способ заказа услуги по датам
export enum EServiceOrderByDateType {
  // Можно выбрать период
  Period = 'PERIOD',
  // Можно выбрать по дням
  Days = 'DAYS',
  // Нельзя выбрать даты
  None = 'NONE',
}

// Статус услуги
export enum EOfferServiceStatus {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
}

// Статус элемента справочника Интересы
export enum EActivityTypeStatus {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
}

// Статус элемента справочника Единицы измерения тарифов в объектах досуга и отдыха
export enum EBookingServicePriceUnitStatus {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
  Draft = 'DRAFT',
}

// Статус элемента справочника Категории услуг в объектах досуга и отдыха
export enum EBookingServiceCategoryStatus {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
  Draft = 'DRAFT',
}

export enum EBookingOfferActionDiscriminator {
  // Перевод предложения партнером на модерацию
  OfferToModerationCommand = 'OfferToModerationCommand',

  // Снятие с модерации предложения партнёром Возврат из статуса WAITING_FOR_MODERATION в DRAFT!
  OfferBackToDraftCommand = 'OfferBackToDraftCommand',

  // Назначение модератора
  OfferAssignModeratorCommand = 'OfferAssignModeratorCommand',

  // Успешное завершение модерации предложения админом переход в следующий статус
  OfferApproveCommand = 'OfferApproveCommand',

  // Отклонение предложения модератором и отправка его на доработку
  OfferRejectCommand = 'OfferRejectCommand',

  // Приостановка(перевод в статус PAUSED)
  OfferPauseCommand = 'OfferPauseCommand',

  // Возобновление предложения после приостановки
  OfferRenewCommand = 'OfferRenewCommand',

  // Архивация предложения
  OfferArchiveCommand = 'OfferArchiveCommand',

  // Архивация предложения
  OfferBackFromArchiveCommand = 'OfferBackFromArchiveCommand',

  // Новая версия объекта
  NewVersionBookingOfferCommand = 'NewVersionBookingOfferCommand',
}

export enum EOfferCategoryType {
  Trade = 'trade',
  Corp = 'corp',
}

export enum ETournamentApplicationStatuses {
  New = 'NEW',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED ',
}

export enum ETournamentIndividualActionDiscriminator {
  /** Анонсировать индивидуальный турнир */
  AnnounceTournamentIndividualCommand = 'AnnounceTournamentIndividualCommand',

  /** Запрос на обновление анонсированного турнира */
  AnnouncedTournamentIndividualUpdateCommand = 'AnnouncedTournamentIndividualUpdateCommand',

  /** Запрос на обновление черновика турнира */
  DraftTournamentIndividualUpdateCommand = 'DraftTournamentIndividualUpdateCommand',

  /** Опубликовать индивидуальный турнир */
  PublishTournamentIndividualCommand = 'PublishTournamentIndividualCommand',

  /** Запрос на обновление опубликованного (в статусах WAITING_FOR_START, APPLICATIONS_COLLECTING) индивидуального турнира */
  PublishTournamentIndividualUpdateCommand = 'PublishTournamentIndividualUpdateCommand',

  /** Пригласить пользователей(я) на индивидуальный турнир */
  InviteUsersToTournamentIndividualCommand = 'InviteUsersToTournamentIndividualCommand',

  /** Команда на сохранение результатов индивидуального турнира */
  TournamentIndividualResultsCommand = 'TournamentIndividualResultsCommand',

  /** Публикация результатов индивидуального турнира */
  PublishTournamentIndividualResultCommand = 'PublishTournamentIndividualResultCommand',
}

/** Команды над заявкой на участие в индивидуальном турнире */
export enum ETournamentIndividualApplicationActionDiscriminator {
  /** Принять заявку на участие в турнире. Заявка может быть одобрена только при наличии у нее статуса NEW. */
  AcceptTournamentApplicationCommand = 'AcceptTournamentApplicationCommand',

  /** Отклонить заявку на участие в турнире. Заявка может быть отклонена только при наличии у нее статуса NEW.*/
  RejectTournamentApplicationCommand = 'RejectTournamentApplicationCommand',
}

/** Формат участия в мероприятии */
export enum ESportEventParticipationFormat {
  /** Онлайн */
  Online = 'ONLINE',

  /** Офлайн */
  Offline = 'OFFLINE',
}

export enum EClientOrgStatus {
  Active = 'ACTIVE', // Активный
  Blocked = 'BLOCKED', // Приостановленный
}

export enum EClientOrgActionDiscriminator {
  // Обновление организации
  ClientOrgUpdateCommand = 'ClientOrgUpdateCommand',
  // Блокировка организации
  ClientOrgBlockCommand = 'ClientOrgBlockCommand',
  // Разблокировка организации
  ClientOrgUnblockCommand = 'ClientOrgUnblockCommand',
}

export enum EAdCampaignType {
  Search = 'SEARCH', // поиск
  Catalog = 'CATALOG',
}

export enum EAdCampaignStatus {
  /** Опубликованные */
  Active = 'ACTIVE',
  /** Предстоящие */
  Upcoming = 'UPCOMING',
  /** Приостановленные */
  Paused = 'PAUSED',
  /** Архив */
  Archived = 'ARCHIVED',
}

export enum EAdCampaignCreateDiscriminator {
  CreateAdCampaignCatalogRequest = 'CreateAdCatalogCampaignRequest',
  CreateAdCampaignSearchRequest = 'CreateAdSearchCampaignRequest',
}

export enum EAdCampaignCommandDiscriminator {
  AdCatalogCampaignUpdateCommand = 'AdCatalogCampaignUpdateCommand',
  AdSearchCampaignUpdateCommand = 'AdSearchCampaignUpdateCommand',
  AdCampaignPauseCommand = 'AdCampaignPauseCommand',
  // Возобновление предложения после приостановки
  AdCampaignRenewCommand = 'AdCampaignRenewCommand',
  // Архивация предложения
  AdCampaignArchiveCommand = 'AdCampaignArchiveCommand',
}

export enum ESocialPackageStatus {
  /** КСП назначен в РЖД(approv=0/1) */
  Appointed = 'APPOINTED',
  /** Пакет готов к активации пользователем(Можно показать пользователю, лимиты распределены и сделаны другие необходимые действия и проверки с данными пакета) */
  Ready = 'READY',
  /** Предстоящий - все согласовано, пользователь согласился с пакетом, пакет ждет своей даты */
  Upcoming = 'UPCOMING',
  /** ктивный - все согласовано, пользователь согласился с пакетом, пакет активен по датам */
  Active = 'ACTIVE',
  /** Архив - отказ пользователя, закончилась дата, отзыв пакета работодателем */
  Archived = 'ARCHIVED',
}

/** Согласие работника c соцпакетом */
export enum ERzdSocialPackageEmployeeAgreement {
  /** Cогласился */
  Agreed = 'AGREED',
  /** Отказался */
  Refused = 'REFUSED',
}

export enum ESocialPackageAdminAction {
  SocialPackageAllocateLimitCommand = 'SocialPackageAllocateLimitCommand',
  SocialPackageArchiveCommand = 'SocialPackageArchiveCommand',
  SocialPackageToReadyCommand = 'SocialPackageToReadyCommand',
  SocialPackageToAppointedCommand = 'SocialPackageToAppointedCommand',
}
