import { Grid } from '@mui/material';
import moment, { Moment } from 'moment';
import { Nullable } from '../../../../../domain/model/types';
import ReportParamsOffers from '../../components/params/offers';
import ReportParamsPeriod from '../../components/params/period';
import { ReportFilter, ReportOfferTypeItem } from '../../types';
import { reportOfferTypes, reportPeriods } from '../../utils';

interface ReportsPartnerFilterProps {
  readonly filter: ReportFilter;
  readonly onPeriodChange: (startDate: Nullable<Moment>, endDate: Nullable<Moment>) => void;
  readonly onOfferChange: (value: Nullable<ReportOfferTypeItem>) => void;
}

const ReportsPartnerFilter = (props: ReportsPartnerFilterProps) => {
  const { filter, onPeriodChange, onOfferChange } = props;

  const { startDate, endDate, offer } = filter;

  const periodValue: [Nullable<Moment>, Nullable<Moment>] = [
    startDate ? moment(startDate) : null,
    endDate ? moment(endDate) : null,
  ];

  return (
    <Grid
      container
      spacing={2}
      wrap='nowrap'
    >
      <Grid item>
        <ReportParamsPeriod
          value={periodValue}
          onChange={period => onPeriodChange(period?.[0] ?? null, period?.[1] ?? null)}
          canEmptyPeriod={false}
          periods={reportPeriods}
        />
      </Grid>
      <Grid
        item
        md={4}
        xl={3}
      >
        <ReportParamsOffers
          value={offer}
          onChange={onOfferChange}
          types={reportOfferTypes}
        />
      </Grid>
    </Grid>
  );
};

export default ReportsPartnerFilter;
