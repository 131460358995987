import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { EReportType } from '@features/report/types';

export type ReportDownloadsState = {
  readonly reports: Record<EReportType, boolean>;
};

export type ReportDownloadsStateKey = keyof ReportDownloadsState['reports'];

interface Reducers extends SliceCaseReducers<ReportDownloadsState> {
  reportDownloadsChangeDialogState: Reducer<{
    name: keyof ReportDownloadsState['reports'];
    open: boolean;
  }>;
}

type Reducer<T = undefined> = CaseReducer<ReportDownloadsState, PayloadAction<T>>;

const slice = createSlice<ReportDownloadsState, Reducers>({
  name: 'report/downloads',
  initialState: {
    reports: {
      [EReportType.Offers]: false,
      [EReportType.Partners]: false,
      [EReportType.OffersStatistic]: false,
      [EReportType.Events]: false,
      [EReportType.EventsDetailed]: false,
      [EReportType.Users]: false,
      [EReportType.SportUsers]: false,
      [EReportType.BookingOrdersStatistic]: false,
    },
  },
  reducers: {
    reportDownloadsChangeDialogState: (state, { payload }) => {
      const { name, open } = payload;
      state.reports[name] = open;
    },
  },
  extraReducers: builder => {},
});

export const { reportDownloadsChangeDialogState } = slice.actions;

export default slice.reducer;
