import { EDeliveryType, EProductOrderPaymentType, ETargetType, ImageRestrictions, OptionType, Target } from '@/domain';
import { fileTypes } from '../../../constants';

export type PaymentType = Omit<OptionType<EProductOrderPaymentType>, 'code'>;

export const paymentTypes: PaymentType[] = [
  { id: EProductOrderPaymentType.Cash, name: 'Наличными' },
  { id: EProductOrderPaymentType.Card, name: 'Банковской картой' },
  { id: EProductOrderPaymentType.CashOrCard, name: 'Наличными и банковской картой' },
  { id: EProductOrderPaymentType.Invoice, name: 'Онлайн (выставление счета)' },
  { id: EProductOrderPaymentType.Other, name: 'Другое' },
];

export type DeliveryType = Omit<OptionType<EDeliveryType>, 'code'>;

export const deliveryType: DeliveryType[] = [
  { id: EDeliveryType.Delivery, name: 'Доставка' },
  { id: EDeliveryType.Self, name: 'Только самовывоз' },
  { id: EDeliveryType.SelfOrDelivery, name: 'Доставка и самовывоз' },
  { id: EDeliveryType.Other, name: 'Другое' },
];

export const productOfferImageRestrictions: ImageRestrictions = {
  recommendedWidthInPx: 405,
  recommendedHeightInPx: 220,
  maxFileSizeInBytes: 500 * 1024,
  fileAccept: [fileTypes.jpg.ext, fileTypes.jpeg.ext, fileTypes.png.ext],
  fileType: [fileTypes.jpg.mimeType, fileTypes.jpeg.mimeType, fileTypes.png.mimeType],
};

export const urlify = (text: string, linkText?: string): string => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replaceAll(urlRegex, url => {
    return `<a target='_blank' rel='noopener noreferrer' href='${url}'>${linkText || 'Скачать'}</a>`;
  });
};

export const getProductOfferCreateEmptyTarget = (): Target => ({
  targetType: ETargetType.Geo,
  targetRoads: null,
  targetLocalities: null,
  targetOrgUnits: null,
  targetGender: null,
  targetClientOrgs: null,
  targetExternalUsers: null,
  targetHavingChildFamilyMemberOnly: null,
  targetTradeUnionMembersOnly: null,
  targetFamilyMemberOnly: null,
});
