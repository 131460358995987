import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../../data/api';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../../data/store/store';
import {
  Fetchable,
  fetchableDefault,
  fetchableFailed,
  fetchableFetched,
  fetchableFetching,
} from '../../../../../../data/store/types';
import { Nullable, UUID } from '../../../../../../domain/model/types';
import { MpUser, MpUserData } from '../../../../../../domain/model/user';
import { userMpEditByIdSelector } from './selectors';

export const userMpEditByIdFetch = createAsyncThunk<MpUser, UUID, AppThunkAPIConfig>(
  'userMp/edit/byId/fetch',
  async (id, { rejectWithValue, signal }) => {
    try {
      const { data } = await Api.user.mp.one({ id, signal });
      return data;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);

      return rejectWithValue(e.response.data);
    }
  }
);

export const userMpEditUpdate = createAsyncThunk<
  { result: MpUser; newEmail: Nullable<string> },
  MpUser,
  AppThunkAPIConfig
>('userMp/edit/update', async (user, { getState, rejectWithValue }) => {
  try {
    const { data: result } = await Api.user.mp.update({ id: user.id, data: user });
    let newEmail = null;

    if (userMpEditByIdSelector(getState()).loadedData?.email !== user.email) {
      newEmail = user.email;
      await Api.user.mp.changeEmail({ id: user.id, email: newEmail });
    }

    return {
      result,
      newEmail,
    };
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface UserMpEditState {
  readonly byId: Fetchable & {
    readonly data: Nullable<MpUser>;
    readonly loadedData: Nullable<MpUser>;
    readonly modified: boolean;
  };
  readonly update: Fetchable;
}

type Reducer<T = undefined> = CaseReducer<UserMpEditState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<UserMpEditState> {
  userMpEditStateReset: Reducer;
  userMpEditSetAttribute: Reducer<{ name: keyof MpUserData; value: any }>;
}

const slice = createSlice<UserMpEditState, Reducers, 'edit'>({
  name: 'edit',
  initialState: {
    byId: {
      ...fetchableDefault,
      data: null,
      loadedData: null,
      modified: false,
    },
    update: {
      ...fetchableDefault,
    },
  },
  reducers: {
    userMpEditStateReset: state => {
      state.byId = {
        ...fetchableDefault,
        data: null,
        loadedData: null,
        modified: false,
      };
      state.update = {
        ...fetchableDefault,
      };
    },
    userMpEditSetAttribute: (state, { payload }) => {
      const { name, value } = payload;
      state.byId.data![name] = value;
      state.byId.modified = true;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(userMpEditByIdFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
        state.byId.data = null;
        state.byId.loadedData = null;
        state.byId.modified = false;
      })
      .addCase(userMpEditByIdFetch.fulfilled, (state, { payload }) => {
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;

        state.byId.data = payload;
        state.byId.loadedData = payload;
        state.byId.modified = false;
      })
      .addCase(userMpEditByIdFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = true;

          state.byId.data = null;
          state.byId.loadedData = null;
          state.byId.modified = false;
        }
      })
      .addCase(userMpEditUpdate.pending, state => {
        state.update = fetchableFetching;
      })
      .addCase(userMpEditUpdate.fulfilled, state => {
        state.update = fetchableFetched;
      })
      .addCase(userMpEditUpdate.rejected, state => {
        state.update = fetchableFailed;
      });
  },
});

export const { userMpEditSetAttribute, userMpEditStateReset } = slice.actions;

export default slice.reducer;
