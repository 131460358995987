import { Grid } from '@mui/material';
import { Moment } from 'moment';
import { useEffect } from 'react';
import { EPeriod } from '../../../../../domain/model/enums';
import { Nullable } from '../../../../../domain/model/types';
import { MPToggleButtonsGroupSingle } from '../../../../theme/ui-kit/button/toggle';
import { getPastPeriodDates } from '../../../../utils/dates';
import { ReportPeriodItem } from '../../types';
import { getReportPeriodByDates } from '../../utils';
import { StyledMPDatePicker } from './controls';

type PeriodType = [Nullable<Moment>, Nullable<Moment>];

interface ReportParamsPeriodProps {
  readonly value: Nullable<PeriodType>;
  readonly periods: ReportPeriodItem[];
  readonly canEmptyPeriod?: boolean;
  readonly defaultValue?: EPeriod;
  readonly onChange: (value: Nullable<PeriodType>) => void;
}

interface ReportParamsPeriodCustomProps {
  readonly value: Nullable<PeriodType>;
  readonly clearable: boolean;
  readonly onChange: (value: Nullable<PeriodType>) => void;
}

const ReportParamsPeriodCustom = (props: ReportParamsPeriodCustomProps) => {
  const { value, onChange, clearable } = props;

  const from: Nullable<Moment> = value?.[0] ?? null;
  const to: Nullable<Moment> = value?.[1] ?? null;

  const onChangeFrom = (date: Nullable<Moment>) => {
    onChange(!date && !to ? null : [date?.startOf('day') ?? null, to]);
  };

  const onChangeTo = (date: Nullable<Moment>) => {
    onChange(!date && !to ? null : [from, date?.endOf('day') ?? null]);
  };

  return (
    <Grid
      container
      spacing={1}
      wrap='nowrap'
    >
      <Grid item>
        <StyledMPDatePicker
          value={from}
          label='Период с'
          clearable={clearable}
          onChange={onChangeFrom}
        />
      </Grid>
      <Grid item>
        <StyledMPDatePicker
          value={to}
          clearable={clearable}
          label='Период по'
          onChange={onChangeTo}
        />
      </Grid>
    </Grid>
  );
};

const ReportParamsPeriod = (props: ReportParamsPeriodProps) => {
  const { value, periods, onChange, canEmptyPeriod, defaultValue = EPeriod.Week } = props;

  const onChangeSpecialPeriod = (periodType: Nullable<ReportPeriodItem>) => {
    if (!periodType) {
      return onChange(canEmptyPeriod ? null : value);
    }
    const period = getPastPeriodDates(periodType.id);
    return onChange(period);
  };

  const onChangeCustomPeriod = (period: Nullable<PeriodType>) => {
    if (!period) {
      return onChange(null);
    }

    if (!canEmptyPeriod) {
      if (!period[0]) {
        return onChange([period[1], period[1]]);
      }
      if (!period[1]) {
        return onChange([period[0], period[0]]);
      }
    }

    return onChange(period);
  };

  const selectedPeriod: Nullable<ReportPeriodItem> =
    value?.[0] && value?.[1] ? getReportPeriodByDates(periods, value[0], value[1]) : null;
  const isCustomPeriod = !selectedPeriod;

  useEffect(() => {
    if (!canEmptyPeriod) {
      const isSomeValueEmpty = value?.some(period => !period);
      if (isSomeValueEmpty) {
        onChange(getPastPeriodDates(defaultValue));
      }
    }
  }, [value, canEmptyPeriod, defaultValue]);

  return (
    <Grid
      container
      spacing={2}
      wrap='nowrap'
    >
      <Grid item>
        <MPToggleButtonsGroupSingle<ReportPeriodItem>
          size='large'
          items={periods}
          value={selectedPeriod}
          onChange={onChangeSpecialPeriod}
        />
      </Grid>
      <Grid item>
        <ReportParamsPeriodCustom
          clearable={!!canEmptyPeriod}
          value={isCustomPeriod ? value : null}
          onChange={onChangeCustomPeriod}
        />
      </Grid>
    </Grid>
  );
};

export default ReportParamsPeriod;
