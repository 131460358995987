import { Nullable, Pageable, RzdSocialPackage, UUID } from '@/domain';
import { PaginationSize } from '@/presentation/types';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { Fetchable, fetchableDefault } from '@/data/store/types';
import { ESocialPackageTableTab } from '../../types';
import { EBookingOfferTableColumn } from '@features/bookingOffer/types';
import { AppThunkAPIConfig } from '@/data/store/store';
import ErrorHandler from '@/data/network/errorHandler';
import socialPackageServices, { AllCommandProps } from '../../services';

export type SocialPackageTabsCounter = {
  [name in ESocialPackageTableTab]?: number;
};

const defaultSort = `${EBookingOfferTableColumn.Name},asc`;

export interface SocialPackageListState extends Fetchable, Pageable<RzdSocialPackage> {
  readonly guid: Nullable<UUID>;
  readonly needRefreshWatcher: number;
  readonly pageNumber: number;
  readonly totalCount: number;
  readonly pageCount: number;
  readonly search: {
    readonly sort: string;
    readonly pageSize: PaginationSize;
  };
  readonly tab: Nullable<ESocialPackageTableTab>;
  readonly tabsCounter: SocialPackageTabsCounter;
  readonly tabsCounterUnwatched: SocialPackageTabsCounter;
}

type Reducer<T = undefined> = CaseReducer<SocialPackageListState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<SocialPackageListState> {
  socialPackageDataReset: Reducer;
  socialPackageStartSession: Reducer<{ guid: UUID }>;
  socialPackageSetTab: Reducer<{ tab: ESocialPackageTableTab }>;
  socialPackageSetPageSize: Reducer<{ pageSize: PaginationSize }>;
  socialPackageSetPage: Reducer<{ pageNumber: number }>;
  socialPackageNeedRefreshWatcherReset: Reducer;
}

export type SocialPackageFetchProps = Omit<AllCommandProps, 'signal'>;

export const socialPackageFetch = createAsyncThunk<
  Pageable<RzdSocialPackage>,
  SocialPackageFetchProps,
  AppThunkAPIConfig
>('socialPackage/list/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    return await socialPackageServices.all({ ...props, signal });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

const initialState: SocialPackageListState = {
  guid: null,
  needRefreshWatcher: 0,
  pageNumber: 1,
  totalCount: 0,
  pageCount: 0,
  tab: null,
  tabsCounter: {},
  tabsCounterUnwatched: {},
  ...fetchableDefault,
  data: [],
  search: {
    sort: defaultSort,
    pageSize: 10,
  },
};

const slice = createSlice<SocialPackageListState, Reducers>({
  name: 'list',
  initialState,
  reducers: {
    socialPackageDataReset: () => {
      return initialState;
    },
    socialPackageStartSession: (state, { payload }) => {
      const { guid } = payload;

      if (guid !== state.guid) {
        return { ...initialState, guid };
      }
    },
    socialPackageSetTab: (state, { payload }) => {
      const { tab } = payload;
      if (state.tab !== tab) {
        state.pageNumber = 1;
        state.search.sort = defaultSort;
        state.tab = tab;
      }
      state.needRefreshWatcher++;
    },
    socialPackageSetPageSize: (state, { payload }) => {
      const { pageSize } = payload;
      state.pageNumber = 1;
      state.search.pageSize = pageSize;
      state.needRefreshWatcher++;
    },
    socialPackageSetPage: (state, { payload }) => {
      const { pageNumber } = payload;
      state.pageNumber = pageNumber;
      state.needRefreshWatcher++;
    },
    socialPackageNeedRefreshWatcherReset: state => {
      state.needRefreshWatcher = 0;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(socialPackageFetch.pending, (state, { meta }) => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
      })
      .addCase(socialPackageFetch.fulfilled, (state, { meta, payload }) => {
        const { data, totalCount, pageCount } = payload;

        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;

        state.data = data;
        state.totalCount = totalCount;
        state.pageCount = pageCount;
      })
      .addCase(socialPackageFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = true;
          state.data = [];
        }
      });
  },
});

export const {
  socialPackageDataReset,
  socialPackageStartSession,
  socialPackageSetTab,
  socialPackageSetPageSize,
  socialPackageSetPage,
  socialPackageNeedRefreshWatcherReset,
} = slice.actions;

export default slice.reducer;
