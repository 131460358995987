import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import Api from '../../../../../../data/api';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../../data/store/store';
import {
  Fetchable,
  fetchableDefault,
  fetchableFailed,
  fetchableFetched,
  fetchableFetching,
} from '../../../../../../data/store/types';
import { EUserRole } from '../../../../../../domain/model/enums';
import { NotificationOption, NotificationSetting } from '../../../../../../domain/model/notification';
import { Nullable, UUID } from '../../../../../../domain/model/types';
import { MpUser } from '../../../../../../domain/model/user';
import { userMpEditUpdate } from '../../../userMp/edit/store/slice';

export const userCurrentDetailsMpByIdFetch = createAsyncThunk<
  { user: MpUser; notifications: NotificationOption[] },
  { id: UUID; roles: EUserRole[] },
  AppThunkAPIConfig
>('user/current/details/mp/byId/fetch', async ({ id, roles }, { rejectWithValue, signal }) => {
  try {
    const responses = await Promise.allSettled([
      Api.user.mp.one({ id, signal }),
      Api.notification.byRoles({ signal, roles }),
    ]);

    if (responses[0].status === 'rejected') {
      return rejectWithValue('cancelled');
    }

    const user = (responses[0] as PromiseFulfilledResult<AxiosResponse<MpUser>>).value.data;
    const notifications = [];
    if (responses[1].status === 'fulfilled') {
      notifications.push(...responses[1].value.data);
    }

    return { user, notifications };
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);

    return rejectWithValue(e.response.data);
  }
});

export const userCurrentDetailsMpNotificationsUpdate = createAsyncThunk<
  NotificationSetting,
  { id: UUID; option: NotificationOption; enabled: boolean },
  AppThunkAPIConfig
>('user/current/details/mp/notifications/update', async ({ id, option, enabled }, { rejectWithValue }) => {
  try {
    const { data } = await Api.notification.update({ id, option, enabled });
    return data;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const userCurrentDetailsMpPasswordReset = createAsyncThunk<void, { id: UUID }, AppThunkAPIConfig>(
  'user/current/details/mp/password/reset',
  async ({ id }, { rejectWithValue }) => {
    try {
      await Api.user.resetPassword({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export interface UserCurrentDetailsMpState {
  readonly needRefreshWatcher: number;
  readonly byId: Fetchable & {
    readonly user: Nullable<MpUser>;
    readonly notifications: Nullable<NotificationOption[]>;
    readonly loadedUser: Nullable<MpUser>;
  };
  readonly notificationsUpdate: Fetchable;
  readonly emailChanged: Nullable<string>;
}

type Reducer<T = undefined> = CaseReducer<UserCurrentDetailsMpState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<UserCurrentDetailsMpState> {
  userCurrentDetailsMpStateReset: Reducer;
  userCurrentDetailsMpEmailChangedReset: Reducer;
}

const slice = createSlice<UserCurrentDetailsMpState, Reducers, 'details/mp'>({
  name: 'details/mp',
  initialState: {
    needRefreshWatcher: 0,
    byId: {
      ...fetchableDefault,
      user: null,
      loadedUser: null,
      notifications: null,
    },
    notificationsUpdate: {
      ...fetchableDefault,
    },
    emailChanged: null,
  },
  reducers: {
    userCurrentDetailsMpStateReset: state => {
      state.needRefreshWatcher = 0;
      state.byId = {
        ...fetchableDefault,
        user: null,
        notifications: null,
        loadedUser: null,
      };
      state.notificationsUpdate = {
        ...fetchableDefault,
      };
      state.emailChanged = null;
    },
    userCurrentDetailsMpEmailChangedReset: state => {
      state.emailChanged = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(userCurrentDetailsMpByIdFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
        state.byId.user = null;
        state.byId.notifications = null;
      })
      .addCase(userCurrentDetailsMpByIdFetch.fulfilled, (state, { payload }) => {
        const { user, notifications } = payload;

        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;

        state.byId.user = user;
        state.byId.loadedUser = user;
        state.byId.notifications = notifications;
      })
      .addCase(userCurrentDetailsMpByIdFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = true;
        }

        state.byId.user = null;
        state.byId.notifications = null;
      })
      .addCase(userCurrentDetailsMpNotificationsUpdate.pending, (state, { meta }) => {
        const { option, enabled } = meta.arg;

        const existedNotificationSetting = state.byId.user!.notificationSettings.find(
          item => item.option.id === option.id
        );
        if (existedNotificationSetting) {
          existedNotificationSetting.enabled = enabled;
        } else {
          state.byId.user!.notificationSettings.push({
            option,
            enabled,
          });
        }
        state.notificationsUpdate = fetchableFetching;
      })
      .addCase(userCurrentDetailsMpNotificationsUpdate.fulfilled, state => {
        state.notificationsUpdate = fetchableFetched;
      })
      .addCase(userCurrentDetailsMpNotificationsUpdate.rejected, state => {
        state.notificationsUpdate = fetchableFailed;
        state.byId.user = state.byId.loadedUser;
      })
      .addCase(userMpEditUpdate.pending, state => {
        state.emailChanged = null;
      })
      .addCase(userMpEditUpdate.fulfilled, (state, { payload }) => {
        state.emailChanged = payload.newEmail;
      })
      .addCase(userMpEditUpdate.rejected, state => {
        state.emailChanged = null;
      });
  },
});

export const { userCurrentDetailsMpStateReset, userCurrentDetailsMpEmailChangedReset } = slice.actions;

export default slice.reducer;
