import Api from '@/data/api';
import { Nullable, Pageable, RzdSocialPackage, UUID } from '@/domain';
import { getPageableFromResponseHeaders } from '@/data/api/utils';
import { ApiCancellable } from '@/data/api/types';
import { PaginationSize } from '@/presentation/types';

export type AllCommandProps = ApiCancellable & {
  readonly search: {
    readonly pageSize: PaginationSize;
    readonly sort: Nullable<string>;
  };
  readonly pageNumber: number;
};

type ArchiveCommandProps = {
  readonly id: UUID;
  readonly comment: string;
};

type SetLimitCommandProps = {
  readonly id: UUID;
  readonly limitToSelfRest: number;
  readonly limitToFamilyRest: number;
};

type SocialPackageServices = {
  readonly all: (props: AllCommandProps) => Promise<Pageable<RzdSocialPackage>>;
  readonly archive: (props: ArchiveCommandProps) => Promise<RzdSocialPackage>;
  readonly allocateLimit: (props: SetLimitCommandProps) => Promise<RzdSocialPackage>;
};

const socialPackageServices: SocialPackageServices = {
  all: async props => {
    const response = await Api.socialPackage.all(props);
    const { pageCount, totalCount, page } = getPageableFromResponseHeaders(response);

    return { data: response.data, totalCount, pageCount, pageNumber: page };
  },
  archive: async (props: ArchiveCommandProps) => {
    const { data } = await Api.socialPackage.archive(props);
    return data;
  },
  allocateLimit: async (props: SetLimitCommandProps) => {
    const { data } = await Api.socialPackage.allocateLimit(props);
    return data;
  },
};

export default socialPackageServices;
