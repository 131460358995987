import axios, { AxiosResponse } from 'axios';
import { EUserRole, EUserStatus } from '../../domain/model/enums';
import { ActivityType } from '../../domain/model/event';
import { Nullable, UUID } from '../../domain/model/types';
import { MpUser, MpUserData, MpUserShort, SportUserData, SportUserProfile } from '../../domain/model/user';
import { createCancelToken } from './index';
import {
  ApiCancellable,
  ApiQueryDsl,
  ApiRequestListDiscriminator,
  ApiRequestPageable,
  ApiResponseMaBeCounters,
  ApiResponseWithPageable,
  ApiSportResponseMaBeCounters,
} from './types';
import {
  appendQueryDslParams,
  getComServicesEndpoint,
  getSportBaseEndpoint,
  getUserStorageServicesEndpoint,
} from './utils';

type OneProps = ApiCancellable & {
  readonly id: UUID;
};

export type SportAllProps<D extends ApiRequestListDiscriminator> = ApiRequestPageable &
  ApiCancellable & {
    readonly name?: Nullable<string>;
    readonly addressObjectId?: Nullable<UUID>;
    readonly road?: Nullable<UUID[]>;
    readonly activityTypes?: Nullable<ActivityType[]>;
    readonly ageMin?: Nullable<number>;
    readonly ageMax?: Nullable<number>;
    readonly gender?: Nullable<UUID>;
    readonly roles?: Nullable<EUserRole[]>;
    readonly statuses?: Nullable<EUserStatus[]>;
    readonly discriminator?: D;
  };

type SportCurrentProps = ApiCancellable;

type SportUpdateProps = {
  readonly id: UUID;
  readonly data: SportUserData;
};

type SportChangeEmailProps = {
  readonly id: UUID;
  readonly email: string;
};

type MpCurrentProps = ApiCancellable;

export type MpAllProps<D extends ApiRequestListDiscriminator> = ApiRequestPageable &
  ApiCancellable & {
    readonly query?: Nullable<string>;
    readonly statuses?: Nullable<EUserStatus[]>;
    readonly roles?: Nullable<EUserRole[]>;
    readonly querydsl?: Nullable<ApiQueryDsl>;
    readonly discriminator?: D;
  };

type MpUpdateProps = {
  readonly id: UUID;
  readonly data: MpUserData;
};

type MpChangeStatusProps = {
  readonly id: UUID;
  readonly status: EUserStatus;
};

type MpCreateAdminProps = {
  readonly redirectUri?: string;
  readonly user: MpUserData;
};

type MpChangeEmailProps = {
  readonly id: UUID;
  readonly email: string;
};

type ResetPassword = {
  readonly id: UUID;
};

type SettingsGetValueProps = ApiCancellable & {
  readonly key: string;
};

type SettingsSetValueProps = {
  readonly key: string;
  readonly value: string;
};

type MpOfferEventProps = {
  readonly id: UUID;
  readonly typeCode: string;
}[];

type UserApi = {
  readonly resetPassword: (props: ResetPassword) => Promise<AxiosResponse<void>>;
  readonly settings: {
    readonly getValue: (props: SettingsGetValueProps) => Promise<AxiosResponse<string>>;
    readonly setValue: (props: SettingsSetValueProps) => Promise<AxiosResponse<void>>;
  };
  readonly mp: {
    readonly current: (props: MpCurrentProps) => Promise<AxiosResponse<MpUserShort>>;
    readonly one: (props: OneProps) => Promise<AxiosResponse<MpUser>>;
    readonly all: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
      props: MpAllProps<D>
    ) => Promise<AxiosResponse<ApiResponseMaBeCounters<D, MpUser[]>>>;
    readonly update: (props: MpUpdateProps) => Promise<AxiosResponse<MpUser>>;
    readonly changeStatus: (props: MpChangeStatusProps) => Promise<AxiosResponse<void>>;
    readonly createAdmin: (props: MpCreateAdminProps) => Promise<AxiosResponse<MpUser>>;
    readonly changeEmail: (props: MpChangeEmailProps) => Promise<AxiosResponse<void>>;
    readonly callOfferEvent: (props: MpOfferEventProps) => Promise<AxiosResponse<void>>;
  };
  readonly sport: {
    readonly all: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
      props: SportAllProps<D>
    ) => Promise<AxiosResponse<ApiSportResponseMaBeCounters<D, ApiResponseWithPageable<SportUserProfile>>>>;
    readonly current: (props: SportCurrentProps) => Promise<AxiosResponse<SportUserProfile>>;
    readonly one: (props: OneProps) => Promise<AxiosResponse<SportUserProfile>>;
    readonly update: (props: SportUpdateProps) => Promise<AxiosResponse<SportUserProfile>>;
    readonly changeEmail: (props: SportChangeEmailProps) => Promise<AxiosResponse<void>>;
  };
};

/**
 * АПИ по работе с пользователями
 */
const user: UserApi = {
  resetPassword: ({ id }) => {
    return axios.patch(`${getComServicesEndpoint()}/users/${id}/password`);
  },
  settings: {
    getValue: ({ key, signal }) => {
      const params = new URLSearchParams();
      params.append('key', key);

      return axios.get(`${getUserStorageServicesEndpoint()}/user-storage/current/value`, {
        params,
        headers: { accept: 'text/plain' },
        transformResponse: data => data,
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    setValue: ({ key, value }) => {
      return axios.post(`${getUserStorageServicesEndpoint()}/user-storage/current/values`, [{ key, value }]);
    },
  },
  mp: {
    current: ({ signal }) => {
      return axios.get(`${getComServicesEndpoint()}/users/current/userinfo`, {
        cancelToken: signal && createCancelToken(axios, signal),
      });
      /*return Promise.resolve({
        status: 200,
        statusText: 'OK',
        headers: {},
        config: {},
        request: {},
        data: {
          discriminator: 'User',
          createdAt: '2022-09-12T17:42:30.569583Z',
          createdBy: null,
          updatedAt: '2024-09-02T09:42:26.154665Z',
          updatedBy: '2912465d-b64c-4804-9c0b-d72772a98a1e',
          id: '2912465d-b64c-4804-9c0b-d72772a98a1e',
          phone: '79648636124',
          lastName: 'Админ',
          firstName: 'ЦС',
          middleName: 'ЦС',
          photo: {
            path: 'user/2912465d-b64c-4804-9c0b-d72772a98a1e/photo~1720448246147',
          },
          birthDate: '2000-11-12',
          gender: 'MALE',
          hasChildren: false,
          userSign: true,
          locality: {
            id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
            name: 'Москва',
            shortName: 'г Москва',
            level: {
              id: 1,
              name: 'Субъект РФ',
            },
            postalCode: null,
            position: {
              lat: 55.75396,
              lon: 37.62039,
            },
            hierarchy: [
              {
                id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
                level: {
                  id: 1,
                  name: 'Субъект РФ',
                },
                name: 'город Москва',
                shortName: 'г Москва',
                values: [
                  {
                    value: 'Москва',
                    typeName: 'город',
                    shortTypeName: 'г',
                  },
                ],
                manual: false,
              },
            ],
            manual: false,
          },
          email: 'admin-mp@test.mp',
          status: 'ENABLED',
          pernr: '24070666',
          roles: ['default-roles-mp', 'user', 'admin_mp', 'customer'],
          notificationSettings: [
            {
              option: {
                id: '83c4726d-46bf-49ce-b08f-a02f3f8f3af1',
                name: 'Новые предложения на проверку',
                description: null,
              },
              enabled: true,
            },
            {
              option: {
                id: '8f26a201-c2ca-445b-aca1-8c3c12a93006',
                name: 'Окончание срока публикации',
                description: null,
              },
              enabled: true,
            },
            {
              option: {
                id: 'dcb5910d-81b4-4eaa-8d74-13cbc53f992e',
                name: 'Предложение опубликовано',
                description: null,
              },
              enabled: true,
            },
            {
              option: {
                id: '78fa6156-5229-4ff9-a588-658f7daf4bc9',
                name: 'Отказ в публикации',
                description: null,
              },
              enabled: true,
            },
            {
              option: {
                id: '9d059964-dfe2-414c-a8d3-6d8a863faa98',
                name: 'Скидки и акции',
                description: 'Узнавайте первым о новых акциях и уникальных условиях',
              },
              enabled: true,
            },
            {
              option: {
                id: '011b8e70-5ec2-4651-8dae-cf0d2f3d1df5',
                name: 'Избранные предложения',
                description: 'Узнавайте о появлении любимых предложений',
              },
              enabled: true,
            },
            {
              option: {
                id: '98b6e5b2-2f68-4131-a80c-284b7464ac48',
                name: 'Новые партнёры на проверку',
                description: null,
              },
              enabled: true,
            },
          ],
          location: {
            id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
            name: 'Москва',
            shortName: 'г Москва',
            level: {
              id: 1,
              name: 'Субъект РФ',
            },
            postalCode: null,
            position: {
              lat: 55.75396,
              lon: 37.62039,
            },
            hierarchy: [
              {
                id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
                level: {
                  id: 1,
                  name: 'Субъект РФ',
                },
                name: 'город Москва',
                shortName: 'г Москва',
                values: [
                  {
                    value: 'Москва',
                    typeName: 'город',
                    shortTypeName: 'г',
                  },
                ],
                manual: false,
              },
            ],
            manual: false,
          },
          lastLoggedInAt: '2024-09-02T09:42:26.151689Z',
          balances: null,
        } as any,
      } as any);*/
    },

    one: ({ id, signal }) => {
      return axios.get(`${getComServicesEndpoint()}/users/${id}`, {
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },

    all: ({ page, pageSize, sort, query, statuses, roles, querydsl, discriminator, signal }) => {
      const params = new URLSearchParams();

      params.append('page', (page - 1).toString(10));
      params.append('size', pageSize.toString(10));

      if (sort) {
        if (typeof sort === 'string') {
          params.append('sort', sort);
        } else {
          sort.forEach(item => params.append('sort', item));
        }
      }

      if (query) {
        params.append('q', query);
      }

      if (statuses) {
        statuses.forEach(item => params.append('status', item));
      }

      if (roles) {
        roles.forEach(item => params.append('role', item));
      }

      if (querydsl) {
        appendQueryDslParams(params, querydsl);
      }

      if (discriminator) {
        params.append('resultType', discriminator);
      }

      return axios.get(`${getComServicesEndpoint()}/users`, {
        params,
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },

    update: ({ id, data }) => {
      return axios.put(`${getComServicesEndpoint()}/users/${id}`, data);
    },

    changeStatus: ({ id, status }) => {
      return axios.patch(`${getComServicesEndpoint()}/users/${id}/status`, { status });
    },

    createAdmin: ({ redirectUri, user }) => {
      const params = new URLSearchParams();
      if (redirectUri) {
        params.append('redirect_uri', redirectUri);
      }

      return axios.post(`${getComServicesEndpoint()}/admins`, user, { params });
    },

    changeEmail: ({ id, email }) => {
      return axios.patch(`${getComServicesEndpoint()}/users/${id}/email`, { email });
    },

    callOfferEvent: props => {
      return axios.post(
        `${getComServicesEndpoint()}/offers/events`,
        props.map(({ id, typeCode }) => ({
          offer: { id },
          typeCode,
        }))
      );
    },
  },
  sport: {
    all: ({
      page,
      pageSize,
      gender,
      name,
      sort,
      addressObjectId,
      activityTypes,
      road,
      ageMin,
      ageMax,
      roles,
      statuses,
      discriminator,
      signal,
    }) => {
      const params = new URLSearchParams();

      params.append('page', (page - 1).toString(10));
      params.append('size', pageSize.toString(10));

      if (name) {
        params.append('name', name);
      }

      if (sort) {
        if (typeof sort === 'string') {
          params.append('sort', sort);
        } else {
          sort.forEach(item => params.append('sort', item));
        }
      }

      if (gender) {
        params.append('gender', gender);
      }

      if (addressObjectId) {
        params.append('addressObjectId', addressObjectId);
      }

      if (activityTypes) {
        activityTypes.forEach(item => params.append('activityTypes', item.id));
      }

      if (road) {
        road.forEach(item => params.append('road', item));
      }

      if (ageMin) {
        params.append('ageMin', ageMin.toString(10));
      }

      if (ageMax) {
        params.append('ageMax', ageMax.toString(10));
      }

      if (roles) {
        roles.forEach(item => params.append('roles', item));
      }

      if (statuses) {
        statuses.forEach(item => params.append('status', item));
      }

      if (discriminator) {
        params.append('resultType', discriminator);
      }

      return axios.get(`${getSportBaseEndpoint()}/pulse/users`, {
        params,
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },

    one: ({ id, signal }) => {
      return axios.get(`${getSportBaseEndpoint()}/pulse/users/${id}`, {
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },

    update: ({ id, data }) => {
      return axios.put(`${getSportBaseEndpoint()}/user/${id}`, data);
    },

    current: ({ signal }) => {
      return axios.get(`${getSportBaseEndpoint()}/user/current`, {
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },

    changeEmail: ({ id, email }) => {
      return axios.patch(`${getSportBaseEndpoint()}/user/${id}/email`, { email });
    },
  },
};

export default user;
