import { Nullable } from '../../../../../domain/model/types';
import { MPAutocompleteSingleSelect } from '../../../../theme/ui-kit/autocomplete/single';
import { ReportOfferTypeItem } from '../../types';

interface ReportParamsOffersProps {
  readonly value: Nullable<ReportOfferTypeItem>;
  readonly types: ReportOfferTypeItem[];
  readonly onChange: (value: Nullable<ReportOfferTypeItem>) => void;
}

const ReportParamsOffers = (props: ReportParamsOffersProps) => {
  const { value, types, onChange } = props;

  return (
    <MPAutocompleteSingleSelect<ReportOfferTypeItem>
      label='Выбрать тип предложения'
      value={value ?? null}
      inputProps={{
        readOnly: true,
      }}
      options={types}
      onChangeValue={onChange}
    />
  );
};

export default ReportParamsOffers;
