import { combineReducers } from '@reduxjs/toolkit';
import detailsMp, { UserCurrentDetailsMpState } from './detailsMp/store/slice';
import detailsSport, { UserCurrentDetailsSportState } from './detailsSport/store/slice';
import common, { UserCurrentCommonState } from './store/slice';

export interface UserCurrentState {
  common: UserCurrentCommonState;
  detailsMp: UserCurrentDetailsMpState;
  detailsSport: UserCurrentDetailsSportState;
}

export default combineReducers<UserCurrentState>({
  common,
  detailsMp,
  detailsSport,
});
