import { AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';
import { ENoticeStatus } from '../../../../../domain/model/enums';
import Notifier from '../../../../../system/notifier';
import { EReportActionType, EReportDownloadStatus } from '../../types';
import { reportDownloadURL } from '../../utils';
import { partnerReportsReportDownload } from '../store/slice';

type UseReportPartnerDownload = (type: EReportActionType, promise: Promise<AxiosResponse<string>>) => void;

function useReportPartnerDownload(): UseReportPartnerDownload {
  const dispatch = useDispatch();

  return (type: EReportActionType, promise: Promise<AxiosResponse<string>>) => {
    dispatch(partnerReportsReportDownload({ type, status: EReportDownloadStatus.Pending }));
    promise
      .then(result => reportDownloadURL(result))
      .catch(error => Notifier.getInstance().addNotice(ENoticeStatus.Error, `${error.message}`))
      .finally(() => dispatch(partnerReportsReportDownload({ type, status: EReportDownloadStatus.Finished })));
  };
}

export default useReportPartnerDownload;
