import {
  CaseReducer,
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
  Selector,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig, RootState } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { EPartnerStatus } from '../../../../../domain/model/enums';
import { PartnerShort } from '../../../../../domain/model/partner';
import { Nullable } from '../../../../../domain/model/types';
import { EReportActionType, EReportDownloadStatus, ReportOfferTypeItem } from '../../types';

type ReportAction = {
  isFetching: boolean;
  type: EReportActionType;
};

export interface AdminReportsState {
  readonly partners: Fetchable & {
    readonly data: PartnerShort[];
  };
  readonly offers: Fetchable & {
    readonly data: ReportOfferTypeItem[];
  };
  readonly filterData: Fetchable & {
    readonly partner: Nullable<PartnerShort>;
    readonly offer: Nullable<ReportOfferTypeItem>;
    readonly startDate: Nullable<string>;
    readonly endDate: Nullable<string>;
  };
  readonly actions: ReportAction[];
  readonly needRefreshWatcher: number;
}

const getActionProcess = (state: AdminReportsState, actionType: EReportActionType) => {
  let process = state.actions.find(change => change.type === actionType);
  if (process) return process;

  process = {
    isFetching: false,
    type: actionType,
  };
  state.actions.push(process);

  return process;
};

export const adminReportFilterPartnerFetch = createAsyncThunk<PartnerShort[], undefined, AppThunkAPIConfig>(
  'report/admin/filterPartners/fetch',
  async (props, { rejectWithValue, signal }) => {
    try {
      const { data: response } = await Api.partner.all({
        page: 1,
        pageSize: 100000,
        signal,
        statuses: [EPartnerStatus.Disabled, EPartnerStatus.Enabled],
        sort: 'name',
      });
      return response;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

type Reducer<T = undefined> = CaseReducer<AdminReportsState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<AdminReportsState> {
  adminReportsFilterSetOffer: Reducer<Nullable<ReportOfferTypeItem>>;
  adminReportsFilterSetPartner: Reducer<Nullable<PartnerShort>>;
  adminReportsFilterSetPeriod: Reducer<{ startDate: Nullable<string>; endDate: Nullable<string> }>;
  adminReportsReportDownload: Reducer<{ type: EReportActionType; status: EReportDownloadStatus }>;
}

const slice = createSlice<AdminReportsState, Reducers, 'list'>({
  name: 'list',
  initialState: {
    partners: {
      ...fetchableDefault,
      data: [],
    },
    offers: {
      ...fetchableDefault,
      data: [],
    },
    needRefreshWatcher: 0,
    filterData: {
      ...fetchableDefault,
      partner: null,
      offer: null,
      startDate: null,
      endDate: null,
    },
    actions: [],
  },
  reducers: {
    adminReportsFilterSetOffer: (state, { payload }) => {
      state.filterData.offer = payload;
    },
    adminReportsFilterSetPartner: (state, { payload }) => {
      state.filterData.partner = payload;
    },
    adminReportsFilterSetPeriod: (state, { payload }) => {
      const { startDate, endDate } = payload;

      state.filterData.startDate = startDate;
      state.filterData.endDate = endDate;
    },
    adminReportsReportDownload: (state, { payload }) => {
      const { type, status } = payload;
      const process = getActionProcess(state, type);

      switch (status) {
        case EReportDownloadStatus.Pending:
          process.isFetching = true;
          break;
        case EReportDownloadStatus.Finished:
          process.isFetching = false;
          break;
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(adminReportFilterPartnerFetch.pending, state => {
        state.partners.isFetching = true;
        state.partners.isFetched = false;
        state.partners.isFailed = false;
      })
      .addCase(adminReportFilterPartnerFetch.fulfilled, (state, { payload }) => {
        state.partners.isFetching = false;
        state.partners.isFetched = true;
        state.partners.isFailed = false;

        state.partners.data = payload;
        state.needRefreshWatcher++;
      })
      .addCase(adminReportFilterPartnerFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.partners.isFetching = false;
          state.partners.isFetched = false;
          state.partners.isFailed = true;
          state.partners.data = [];
        } else {
          state.partners.isFetching = false;
          state.partners.isFetched = false;
          state.partners.isFailed = false;
        }
      });
  },
});

export const {
  adminReportsFilterSetOffer,
  adminReportsFilterSetPartner,
  adminReportsFilterSetPeriod,
  adminReportsReportDownload,
} = slice.actions;

const adminReportsActionsSelector: Selector<RootState, ReportAction[]> = state => state.report.admin.actions;
const adminReportsTypeSelector: Selector<RootState, EReportActionType, [EReportActionType]> = (state, type) => type;

export const createAdminReportIsFetchingSelector = createSelector(
  adminReportsActionsSelector,
  adminReportsTypeSelector,
  (actions, type) => actions.find(action => action.type === type)?.isFetching ?? false
);

export default slice.reducer;
