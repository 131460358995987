import { combineReducers } from '@reduxjs/toolkit';
import admin, { AdminReportsState } from './admin/store/slice';
import downloads, { ReportDownloadsState } from './downloads/store/slice';
import partner, { PartnerReportsState } from './partner/store/slice';
import sport, { SportReportsState } from './sport/store/slice';
import list, { ReportListState } from './table/store/slice';

interface ReportState {
  admin: AdminReportsState;
  partner: PartnerReportsState;
  sport: SportReportsState;
  list: ReportListState;
  downloads: ReportDownloadsState;
}

export default combineReducers<ReportState>({
  partner,
  admin,
  sport,
  list,
  downloads,
});
