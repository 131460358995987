import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Partner } from '../../../domain/model/partner';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import { viewShowPartnerManagementMenu } from '../../features/partner/management/container/store/slice';
import ReportPartnerContainer from '../../features/report/partner/container';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

interface PartnerManagementReportsScreenProps {
  readonly partner: Partner;
}

const PartnerManagementReportsScreen = ({ partner }: PartnerManagementReportsScreenProps) => {
  const dispatch = useDispatch();
  const { reports } = useCurrentUser().accessMatrix;

  useEffect(() => {
    dispatch(viewHideMainMenu());
    dispatch(viewShowPartnerManagementMenu());
  }, [dispatch]);

  return (
    <ReportPartnerContainer
      partnerId={partner.id}
      canExecute={reports.execute}
    />
  );
};

export default PartnerManagementReportsScreen;
