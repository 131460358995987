import ContentTargetEditClientOrgs from '@features/general/contentTarget/components/editClientOrgs';
import ContentTargetEditExternalUsers from '@features/general/contentTarget/components/editExternalUsers';
import { Grid } from '@mui/material';
import ContentTargetEditFamilyMemberOnly from '../components/editFamilyMemberOnly';
import ContentTargetEditGender from '../components/editGender';
import ContentTargetEditHavingChildFamilyMemberOnly from '../components/editHavingChildFamilyMemberOnly';
import ContentTargetEditLocalities from '../components/editLocalities';
import ContentTargetEditOrgUnits from '../components/editOrgUnits';
import ContentTargetEditRoads from '../components/editRoads';
import ContentTargetEditTradeUnionMembersOnly from '../components/editTradeUnionMembersOnly';
import { ContentTargetComponentsType, ContentTargetEditProps } from '../types';

type ContentTargetEditContainerProps = ContentTargetEditProps & ContentTargetComponentsType;

const ContentTargetEditContainer = (props: ContentTargetEditContainerProps) => {
  const { target, nsi, topUsed, validation, onChangeAttribute, ...components } = props;
  const componentProps = {
    target,
    validation,
    onChangeAttribute,
    nsi,
    topUsed,
  };

  return (
    <Grid
      container
      spacing={2}
      direction='column'
    >
      {components.localities && (
        <Grid item>
          <ContentTargetEditLocalities {...componentProps} />
        </Grid>
      )}
      {components.externalUsers && (
        <Grid item>
          <ContentTargetEditExternalUsers {...componentProps} />
        </Grid>
      )}
      {components.clientOrgs && (
        <Grid item>
          <ContentTargetEditClientOrgs {...componentProps} />
        </Grid>
      )}
      {components.roads && (
        <Grid item>
          <ContentTargetEditRoads {...componentProps} />
        </Grid>
      )}
      {components.orgUnits && (
        <Grid item>
          <ContentTargetEditOrgUnits {...componentProps} />
        </Grid>
      )}
      {components.gender && (
        <Grid item>
          <ContentTargetEditGender {...componentProps} />
        </Grid>
      )}
      {(components.tradeUnionMembersOnly || components.havingChildFamilyMemberOnly || components.familyMemberOnly) && (
        <Grid item>
          {components.tradeUnionMembersOnly && <ContentTargetEditTradeUnionMembersOnly {...componentProps} />}
          {components.havingChildFamilyMemberOnly && (
            <ContentTargetEditHavingChildFamilyMemberOnly {...componentProps} />
          )}
          {components.familyMemberOnly && <ContentTargetEditFamilyMemberOnly {...componentProps} />}
        </Grid>
      )}
    </Grid>
  );
};
export default ContentTargetEditContainer;
