import {
  EOfferStatus,
  EPartnerStatus,
  EPartnerType,
  EPaymentType,
  ETargetType,
  ETradeOfferType,
  ImageRestrictions,
  Nullable,
  Target,
  TradeOffer,
} from '@/domain';
import { pluralize } from '@/presentation/utils/pluralize';
import { fileTypes } from '../../../constants';
import { EValidationType, ValidationResult } from '../../../utils/validation';
import { isOfferTypeWithCodes } from '../../general/offer/utils/common';
import { ETradeOfferStep, TradeOfferCreateStepType } from '../types';

export const tradeOfferImageRestrictions: ImageRestrictions = {
  recommendedWidthInPx: 964,
  recommendedHeightInPx: 660,
  maxFileSizeInBytes: 1 * 1024 * 1024,
  whStrict: true,
  fileAccept: [fileTypes.jpg.ext, fileTypes.jpeg.ext, fileTypes.png.ext],
  fileType: [fileTypes.jpg.mimeType, fileTypes.jpeg.mimeType, fileTypes.png.mimeType],
};

export enum ETradeOfferCreateDialogState {
  ChangePromotionType = 'changePromotionType',
  History = 'history',
}

export const tradeOfferCreateDefaultSteps: TradeOfferCreateStepType[] = [
  ETradeOfferStep.General,
  ETradeOfferStep.Description,
  ETradeOfferStep.Conditions,
];

export const getTradeOfferCreateSteps = (tradeOfferType: ETradeOfferType): TradeOfferCreateStepType[] => {
  const steps = [];

  steps.push(ETradeOfferStep.General);

  if (tradeOfferType === ETradeOfferType.Widget) {
    steps.push(ETradeOfferStep.Widget);
  }

  steps.push(ETradeOfferStep.Description);

  steps.push(ETradeOfferStep.Conditions);

  if (isOfferTypeWithCodes(tradeOfferType)) {
    steps.push(ETradeOfferStep.PromoCodes);
  }

  return steps;
};

export const tradeOfferImagesMaxCount = 20;
export const tradeOfferTitleMaxLength = 30 as const;
export const tradeOfferNameMaxLength = 120 as const;

export const getTradeOfferCreateEmptyTarget = (): Target => ({
  targetType: ETargetType.Geo,
  targetRoads: null,
  targetLocalities: null,
  targetOrgUnits: null,
  targetGender: null,
  targetClientOrgs: null,
  targetExternalUsers: null,
  targetHavingChildFamilyMemberOnly: null,
  targetTradeUnionMembersOnly: null,
  targetFamilyMemberOnly: null,
});

export const getTradeOfferCreateEmptyOffer = (): TradeOffer => ({
  id: '',
  partner: {
    id: '',
    name: '',
    code: 0,
    type: EPartnerType.External,
    status: EPartnerStatus.Enabled,
    logo: null,
  },
  updatedBy: null,
  lastStatusComment: null,
  rejectionReasonType: null,
  createdAt: '',
  createdBy: '',
  updatedAt: null,
  status: EOfferStatus.Draft,
  offerCount: 0,
  notUsedOfferCount: 0,
  approvingAdmin: null,
  code: null,
  lastStatusAuthor: null,
  lastStatusDate: null,
  pausedReason: null,

  title: null,
  name: null,
  images: null,
  promotionType: null,
  paymentType: EPaymentType.Free,
  price: null,
  originalPrice: null,
  publicPromotionValue: null,
  aggregatorIntegrationId: null,
  activationExpiryDays: null,
  partnerLink: null,
  partnerId: null,
  categories: null,
  startDate: null,
  endDate: null,
  conditions: null,
  description: null,
  externalId: null,
  target: getTradeOfferCreateEmptyTarget(),
  tradeOffer: null,
});

const tradeOfferGetValidationCommonError = (
  step: TradeOfferCreateStepType,
  count: number
): Nullable<ValidationResult<any>> => {
  if (!count) {
    return null;
  }

  switch (step) {
    case ETradeOfferStep.PromoCodes:
      return {
        common: {
          type: EValidationType.Error,
          hasError: true,
          message: `Не загружены коды`,
        },
      };
    case ETradeOfferStep.General:
    case ETradeOfferStep.Description:
    case ETradeOfferStep.Conditions:
    case ETradeOfferStep.Widget:
      return {
        common: {
          type: EValidationType.Error,
          hasError: true,
          message: `Не заполнено ${count} ${pluralize(count, ['поле', 'поля', 'полей'])}`,
        },
      };
  }
};

export const tradeOfferCreateInitValidationStepper = (
  promotionType: Nullable<ETradeOfferType>,
  validation: ValidationResult<TradeOffer>,
  targetValidation: ValidationResult<Target>
) => {
  const targetValidationCount = [
    targetValidation.targetLocalities,
    targetValidation.targetExternalUsers,
    targetValidation.targetClientOrgs,
  ].filter(item => !!item).length;

  const generalErrorsCount = [
    validation.categories,
    validation.target,
    validation.name,
    validation.title,
    validation.images,
    validation.promotionType,
    validation.partnerLink,
    validation.startDate,
    validation.endDate,
    validation.price,
    validation.paymentType,
    validation.aggregatorIntegrationId,
    validation.activationExpiryDays,
  ]
    .concat(
      promotionType && [ETradeOfferType.PublicPromoCode, ETradeOfferType.ReferralLink].includes(promotionType)
        ? [validation.publicPromotionValue]
        : []
    )
    .filter(item => !!item).length;

  const descriptionErrorsCount = [validation.description].filter(item => !!item).length;
  const conditionsErrorsCount = [validation.conditions].filter(item => !!item).length;
  const widgetErrorsCount =
    promotionType === ETradeOfferType.Widget ? [validation.publicPromotionValue].filter(item => !!item).length : 0;
  const promoCodesErrorsCount = [validation.notUsedOfferCount].filter(item => !!item).length;

  return {
    [ETradeOfferStep.General]: tradeOfferGetValidationCommonError(
      ETradeOfferStep.General,
      generalErrorsCount + targetValidationCount
    ),
    [ETradeOfferStep.Description]: tradeOfferGetValidationCommonError(
      ETradeOfferStep.Description,
      descriptionErrorsCount
    ),
    [ETradeOfferStep.Conditions]: tradeOfferGetValidationCommonError(ETradeOfferStep.Conditions, conditionsErrorsCount),
    [ETradeOfferStep.Widget]: tradeOfferGetValidationCommonError(ETradeOfferStep.Widget, widgetErrorsCount),
    [ETradeOfferStep.PromoCodes]: tradeOfferGetValidationCommonError(ETradeOfferStep.PromoCodes, promoCodesErrorsCount),
  };
};
