import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import DataFilterComponent from '../../../../components/common/dataFilter';
import { getBookingOrderFilterStrategies } from '../filterUtils';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { bookingOrdersFilterSelector } from '../store/selectors';

interface OrdersTableFilterAdapterProps {
  readonly partnerId: Nullable<UUID>;
}

const BookingOrderTableFilterAdapter = ({ partnerId }: OrdersTableFilterAdapterProps) => {
  const handlers = useContextHandlers();
  const filter = useSelector(bookingOrdersFilterSelector);
  const filterStrategies = getBookingOrderFilterStrategies(filter, partnerId);

  return useMemo(
    () => (
      <DataFilterComponent
        strategies={filterStrategies}
        onChange={handlers.onChangeFilter}
      />
    ),
    [filterStrategies, handlers.onChangeFilter]
  );
};

export default BookingOrderTableFilterAdapter;
