import { Typography } from '@mui/material';
import { Nullable } from '../../../../../domain/model/types';
import { EReportActionType } from '../../types';
import ReportCard from '../card';
import { Description } from './controls';

interface ReportCardOffersProps {
  readonly isFetching?: boolean;
  readonly onDownload: Nullable<(type: EReportActionType) => void>;
}

const ReportCardOffers = (props: ReportCardOffersProps) => {
  const { onDownload, isFetching } = props;
  const onOffersReportDownload = onDownload ? () => onDownload(EReportActionType.Offers) : null;

  return (
    <ReportCard
      title={<Typography variant='subtitle2'>Предложения</Typography>}
      description={
        <Description>
          <li>Опубликованные;</li>
          <li>На проверке;</li>
          <li>Отклонённые;</li>
          <li>Клики на получение предложения;</li>
          <li>Пользователи кликнувшие на получение предложения;</li>
          <li>Переходы на сайт.</li>
        </Description>
      }
      isFetching={isFetching}
      onDownload={onOffersReportDownload}
    />
  );
};

export default ReportCardOffers;
