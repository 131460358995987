import { useContext } from 'react';
import BookingOfferActionsContext, { ClientOrgActionsContextType } from './context';

export const useClientOrgActions = (): ClientOrgActionsContextType => {
  return useContext<ClientOrgActionsContextType>(BookingOfferActionsContext);
};

export const useClientOrgActionsSelectors = (): ClientOrgActionsContextType['utils']['selectors'] => {
  return useClientOrgActions().utils.selectors;
};
