import { getComServicesEndpoint } from '@/data/api/utils';
import { ESocialPackageAdminAction, RzdSocialPackage } from '@/domain';
import axios, { AxiosResponse } from 'axios';
import { ApiRequestListDiscriminator, ApiResponseMaBeCounters } from '@/data/api/types';

type AllCommandProps = {};

type ArchiveCommandProps = {
  readonly id: UUID;
  readonly comment: string;
};

type SetLimitCommandProps = {
  readonly id: UUID;
  readonly limitToSelfRest: number;
  readonly limitToFamilyRest: number;
};

type SocialPackageApi = {
  readonly all: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: AllCommandProps
  ) => Promise<AxiosResponse<ApiResponseMaBeCounters<D, RzdSocialPackage[]>>>;
  readonly archive: (props: ArchiveCommandProps) => Promise<AxiosResponse<RzdSocialPackage>>;
  readonly allocateLimit: (props: SetLimitCommandProps) => Promise<AxiosResponse<RzdSocialPackage>>;
};

const socialPackage: SocialPackageApi = {
  all: props => {
    return axios.get(`${getComServicesEndpoint()}/social-packages`);
  },
  archive: ({ id, comment }) => {
    return axios.post(`${getComServicesEndpoint()}/social-packages/${id}`, {
      discriminator: ESocialPackageAdminAction.SocialPackageArchiveCommand,
      comment,
    });
  },
  allocateLimit: ({ id, limitToSelfRest, limitToFamilyRest }) => {
    return axios.post(`${getComServicesEndpoint()}/social-packages/${id}`, {
      discriminator: ESocialPackageAdminAction.SocialPackageAllocateLimitCommand,
      limitToSelfRest,
      limitToFamilyRest,
    });
  },
};

export default socialPackage;
