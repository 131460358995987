import { Nullable, Target, TradeOffer } from '@/domain';
import { OnChangeObjectAttribute, PanelAction } from '@/presentation/types';
import { TradeOfferLifeCycle, TradeOfferLifeCycleRules } from '@features/general/offer/lifecycle/types';
import { OfferActionCreateType, OfferActions } from '@features/general/offer/types';
import {TradeOfferCreateContainerProps} from '@features/tradeOffer/create/container';
import { TradeOfferCreateChangeDialogStateType } from '@features/tradeOffer/create/store/slice';
import React from 'react';
import { TradeOfferCreateStep } from '../types';

export type TradeOfferCreateParamsContextValue = TradeOfferCreateContainerProps;

export const TradeOfferCreateParamsContext = React.createContext<TradeOfferCreateParamsContextValue>(
  {} as TradeOfferCreateParamsContextValue
);

export type TradeOfferCreateLifecycleContextValue = {
  readonly lifecycle: TradeOfferLifeCycle;
  readonly lifeCycleRules: TradeOfferLifeCycleRules;
  readonly actions: OfferActions<OfferActionCreateType>;
  readonly onAction: (action: PanelAction<OfferActionCreateType>) => void;
};

export const TradeOfferCreateLifecycleContext = React.createContext<TradeOfferCreateLifecycleContextValue>(
  {} as TradeOfferCreateLifecycleContextValue
);

export type TradeOfferCreateHandlersContextValue = {
  readonly onClose: () => void;
  readonly onChangeDialogState: (props: TradeOfferCreateChangeDialogStateType) => void;
  readonly onCloseImmediate: () => void;
  readonly onChangeStep: (step: TradeOfferCreateStep) => void;
  readonly onChangeAttribute: OnChangeObjectAttribute<TradeOffer>;
  readonly onAttributeValidate: (name: keyof TradeOffer) => void;

  readonly onChangeTargetAttribute: OnChangeObjectAttribute<Target>;
  readonly onTargetAttributeValidate: (name: keyof Target) => void;

  readonly goToFirstIsNotValidStep: () => void;

  readonly onTradeOfferRestore: () => void;
  readonly onTradeOfferRefetchCodesCount: () => void;

  readonly onCustomerClick: (customerId: UUID) => void;

  readonly onTradeOfferSave: (newVersion: boolean) => Promise<Nullable<TradeOffer>>;
  readonly onTradeOfferPublish: () => Promise<Nullable<TradeOffer>>;
  readonly onTradeOfferResume: () => Promise<Nullable<TradeOffer>>;

  readonly onTradeOfferDelete: () => void;

  readonly onTradeOfferDownloadCodes: () => void;
  readonly onTradeOfferTryCreateCodes: () => void;
  readonly onTradeOfferDeleteCodes: () => void;
};

export const TradeOfferCreateHandlersContext = React.createContext<TradeOfferCreateHandlersContextValue>(
  {} as TradeOfferCreateHandlersContextValue
);
