import { ContentTarget, EMultiSelectorValueType, Nullable, Target } from '@/domain';
import { clientOrgRzdCode } from '@features/clientOrg/utils';
import { ValidationResult } from '../../../utils/validation';

export const convertTargetToContentTarget = (target: Target): ContentTarget => {
  return {
    type: target.targetType,
    localities: target.targetLocalities,
    roads: target.targetRoads,
    orgUnits: target.targetOrgUnits,
    gender: target.targetGender,
    clientOrgs: target.targetClientOrgs,
    externalUsers: target.targetExternalUsers,
    tradeUnionMembersOnly: target.targetTradeUnionMembersOnly,
    havingChildFamilyMemberOnly: target.targetHavingChildFamilyMemberOnly,
    familyMemberOnly: target.targetFamilyMemberOnly,
  };
};

export const convertTargetValidationToContentTargetValidation = (
  validation: Nullable<ValidationResult<Target>>
): ValidationResult<ContentTarget> => {
  return {
    type: validation?.targetType,
    localities: validation?.targetLocalities,
    roads: validation?.targetRoads,
    orgUnits: validation?.targetOrgUnits,
    gender: validation?.targetGender,
    clientOrgs: validation?.targetClientOrgs,
    externalUsers: validation?.targetExternalUsers,
    tradeUnionMembersOnly: validation?.targetTradeUnionMembersOnly,
    havingChildFamilyMemberOnly: validation?.targetHavingChildFamilyMemberOnly,
    familyMemberOnly: validation?.targetFamilyMemberOnly,
  };
};

export const convertContentTargetAttributeToTargetAttribute = (name: keyof ContentTarget): keyof Target => {
  switch (name) {
    case 'type':
      return 'targetType';
    case 'localities':
      return 'targetLocalities';
    case 'orgUnits':
      return 'targetOrgUnits';
    case 'roads':
      return 'targetRoads';
    case 'gender':
      return 'targetGender';
    case 'clientOrgs':
      return 'targetClientOrgs';
    case 'externalUsers':
      return 'targetExternalUsers';
    case 'havingChildFamilyMemberOnly':
      return 'targetHavingChildFamilyMemberOnly';
    case 'tradeUnionMembersOnly':
      return 'targetTradeUnionMembersOnly';
    case 'familyMemberOnly':
      return 'targetFamilyMemberOnly';
  }
};

export const getContentTargetClientOrgsDisplayText = (
  value: ContentTarget['clientOrgs'],
  selectAllText = 'все компании'
) => {
  const targetClientOrgs = value ?? { select: EMultiSelectorValueType.In, in: null };
  return targetClientOrgs.select === EMultiSelectorValueType.All
    ? selectAllText
    : [...(targetClientOrgs.in ?? [])]
        .sort((a, b) => (a.code === clientOrgRzdCode ? -1 : 1))
        .map(org => org.name)
        .join(', ');
};
