import { CaseReducer, createSelector, createSlice, PayloadAction, Selector, SliceCaseReducers } from '@reduxjs/toolkit';
import { RootState } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { Nullable } from '../../../../../domain/model/types';
import {
  EReportDownloadStatus,
  EReportSportActionType,
  EReportSportPeriodType,
  ReportSportPeriodTypeItem,
} from '../../types';
import { reportSportPeriodTypes } from '../../utils';

type ReportAction = {
  isFetching: boolean;
  type: EReportSportActionType;
};

export interface SportReportsState {
  readonly data: Fetchable & {};
  readonly filterData: Fetchable & {
    readonly startDate: Nullable<string>;
    readonly endDate: Nullable<string>;
    readonly periodType: ReportSportPeriodTypeItem;
  };
  readonly actions: ReportAction[];
}

type Reducer<T = undefined> = CaseReducer<SportReportsState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<SportReportsState> {
  sportReportsFilterSetPeriod: Reducer<{ startDate: Nullable<string>; endDate: Nullable<string> }>;
  sportReportsFilterSetPeriodType: Reducer<ReportSportPeriodTypeItem>;
  sportReportsReportDownload: Reducer<{ type: EReportSportActionType; status: EReportDownloadStatus }>;
}

const getActionProcess = (state: SportReportsState, actionType: EReportSportActionType) => {
  let process = state.actions.find(change => change.type === actionType);
  if (process) return process;

  process = {
    isFetching: false,
    type: actionType,
  };
  state.actions.push(process);

  return process;
};

const slice = createSlice<SportReportsState, Reducers, 'sport'>({
  name: 'sport',
  initialState: {
    data: {
      ...fetchableDefault,
    },
    filterData: {
      ...fetchableDefault,
      periodType: reportSportPeriodTypes.find(item => item.id === EReportSportPeriodType.DateEvent)!,
      startDate: null,
      endDate: null,
    },
    actions: [],
  },
  reducers: {
    sportReportsFilterSetPeriod: (state, { payload }) => {
      const { startDate, endDate } = payload;

      state.filterData.startDate = startDate;
      state.filterData.endDate = endDate;
    },
    sportReportsFilterSetPeriodType: (state, { payload }) => {
      state.filterData.periodType = payload;
    },
    sportReportsReportDownload: (state, { payload }) => {
      const { type, status } = payload;
      const process = getActionProcess(state, type);

      switch (status) {
        case EReportDownloadStatus.Pending:
          process.isFetching = true;
          break;
        case EReportDownloadStatus.Finished:
          process.isFetching = false;
          break;
      }
    },
  },
});

export const { sportReportsFilterSetPeriodType, sportReportsFilterSetPeriod, sportReportsReportDownload } =
  slice.actions;

const sportReportsActionsSelector: Selector<RootState, ReportAction[]> = state => state.report.sport.actions;
const sportReportsTypeSelector: Selector<RootState, EReportSportActionType, [EReportSportActionType]> = (state, type) =>
  type;

export const createSportReportIsFetchingSelector = createSelector(
  sportReportsActionsSelector,
  sportReportsTypeSelector,
  (actions, type) => actions.find(action => action.type === type)?.isFetching ?? false
);

export default slice.reducer;
