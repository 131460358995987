import { ClientOrgMultiSelectorPredicate, ContentTarget, EUserGender, Nullable, SportOptionTyped } from '@/domain';
import CmsPreviewTargetAudienceSelector from '@features/cms/previewTarget/audienceSelector';
import { Grid, Typography } from '@mui/material';
import { MPButton } from '@ui-kit/button';
import Splitter from '../../../components/common/splitter';
import { OnChangeObjectAttribute } from '../../../types';
import ContentTargetEditFamilyMemberOnly from '../../general/contentTarget/components/editFamilyMemberOnly';
import ContentTargetEditGender from '../../general/contentTarget/components/editGender';
import ContentTargetEditHavingChildFamilyMemberOnly from '../../general/contentTarget/components/editHavingChildFamilyMemberOnly';
import ContentTargetEditLocalities from '../../general/contentTarget/components/editLocalities';
import ContentTargetEditOrgUnits from '../../general/contentTarget/components/editOrgUnits';
import ContentTargetEditRoads from '../../general/contentTarget/components/editRoads';
import ContentTargetEditTradeUnionMembersOnly from '../../general/contentTarget/components/editTradeUnionMembersOnly';
import { CmsPreviewTarget, ECmsPreviewTargetAudienceType } from './types';
import {
  convertCmsPreviewTargetToContentTarget,
  convertContentTargetAttributeToCmsPreviewTargetAttribute,
  getCmsPreviewTargetAudienceType,
} from './utils';

interface CmsPreviewTargetComponentProps {
  readonly target: CmsPreviewTarget;
  readonly isTargetFilled: boolean;
  readonly genderTypes: SportOptionTyped<EUserGender>[];
  readonly onChangeAttribute: OnChangeObjectAttribute<CmsPreviewTarget>;
  readonly onAudienceChange: (
    externalUsers: Nullable<boolean>,
    clientOrgs: Nullable<ClientOrgMultiSelectorPredicate>
  ) => void;
  readonly onPreview: () => void;
}

const CmsPreviewTargetComponent = (props: CmsPreviewTargetComponentProps) => {
  const { target, genderTypes, isTargetFilled, onAudienceChange, onChangeAttribute, onPreview } = props;

  const contentTarget: ContentTarget = convertCmsPreviewTargetToContentTarget(target);

  const onChangeAttributeInternal: OnChangeObjectAttribute<ContentTarget> = (name, value) => {
    onChangeAttribute(convertContentTargetAttributeToCmsPreviewTargetAttribute(name), value as any);
  };

  const componentProps = {
    target: contentTarget,
    validation: null,
    onChangeAttribute: onChangeAttributeInternal,
    nsi: { genderTypes },
  };

  const audienceType: Nullable<ECmsPreviewTargetAudienceType> = getCmsPreviewTargetAudienceType(
    target.externalUsers,
    target.clientOrgs
  );

  return (
    <Grid
      container
      spacing={2}
      direction='column'
    >
      <Grid item>
        <Typography
          variant={'h3'}
          gutterBottom
        >
          Предпросмотр
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant={'body1'}
          gutterBottom
        >
          Выберите целевую аудиторию
        </Typography>
        <CmsPreviewTargetAudienceSelector
          label='Целевая аудитория'
          externalUsers={target.externalUsers}
          clientOrgs={target.clientOrgs}
          onChange={onAudienceChange}
        />
      </Grid>

      {audienceType === ECmsPreviewTargetAudienceType.External && (
        <Grid item>
          <Typography
            variant='body2'
            gutterBottom
          >
            Укажите город, чтобы отобразить баннеры и подборки с товарами и торговыми предложениями
          </Typography>
          <ContentTargetEditLocalities {...componentProps} />
        </Grid>
      )}

      {audienceType === ECmsPreviewTargetAudienceType.Corporate && (
        <Grid item>
          <Typography
            variant='body2'
            gutterBottom
          >
            Укажите город, чтобы отобразить баннеры и подборки с товарами, торговыми и корпоративными предложениями,
            которые настроены по региону.
          </Typography>
          <ContentTargetEditLocalities {...componentProps} />
        </Grid>
      )}

      {audienceType === ECmsPreviewTargetAudienceType.Corporate && (
        <Grid item>
          <Typography
            variant='body2'
            gutterBottom
          >
            Укажите железную дорогу и/или филиал, чтобы отобразить баннеры и подборки с корпоративными предложениями,
            которые настроены по структуре ОАО «РЖД».
          </Typography>
          <ContentTargetEditRoads
            label={false}
            {...componentProps}
          />
          <Splitter />
          <ContentTargetEditOrgUnits
            label={false}
            {...componentProps}
          />
        </Grid>
      )}

      {audienceType === ECmsPreviewTargetAudienceType.Corporate && (
        <Grid item>
          <Splitter
            variant='horizontal'
            size={1}
          />
          <Typography
            variant='body2'
            gutterBottom
          >
            Выберите дополнительные условия
          </Typography>
          <ContentTargetEditGender
            label={false}
            {...componentProps}
          />
          <Splitter size={1} />
          <ContentTargetEditTradeUnionMembersOnly {...componentProps} />
          <ContentTargetEditHavingChildFamilyMemberOnly {...componentProps} />
          <ContentTargetEditFamilyMemberOnly {...componentProps} />
        </Grid>
      )}

      <Grid item>
        <MPButton
          variant='outlined'
          disabled={!isTargetFilled}
          onClick={onPreview}
        >
          Показать предпросмотр страницы
        </MPButton>
      </Grid>
    </Grid>
  );
};

export default CmsPreviewTargetComponent;
