import { combineReducers } from '@reduxjs/toolkit';
import activityType from '../../presentation/features/activityType/reducers';
import adCampaign from '../../presentation/features/adCampaign/reducers';
import address from '../../presentation/features/address/reducers';
import analytics from '../../presentation/features/analytics/reducers';
import banner from '../../presentation/features/banner/reducers';
import bookingOffer from '../../presentation/features/bookingOffer/reducers';
import bookingOrder from '../../presentation/features/bookingOrder/reducers';
import chat from '../../presentation/features/chat/reducers';
import clientOrg from '../../presentation/features/clientOrg/reducers';
import cms from '../../presentation/features/cms/reducers';
import complaint from '../../presentation/features/complaint/reducers';
import configuration from '../../presentation/features/configuration/reducers';
import corpActivation from '../../presentation/features/corpActivation/reducers';
import corpOffer from '../../presentation/features/corpOffer/reducers';
import customer from '../../presentation/features/customer/reducers';
import dictionary from '../../presentation/features/dictionary/reducers';
import event from '../../presentation/features/event/reducers';
import historyExtensions from '../../presentation/features/general/historyExtension/store/slice';
import notifier from '../../presentation/features/general/notifier/store/slice';
import nsi from '../../presentation/features/general/nsi/store/slice';
import uikit from '../../presentation/features/general/uikit/reducers';
import landingWindow from '../../presentation/features/landingWindow/reducers';
import legalAgreements from '../../presentation/features/legal/reducers';
import lock from '../../presentation/features/lock/reducers';
import mainView from '../../presentation/features/main/reducers';
import offerHistory from '../../presentation/features/offerHistory/reducers';
import order from '../../presentation/features/order/reducers';
import partner from '../../presentation/features/partner/reducers';
import partnerApplication from '../../presentation/features/partnerApplication/reducers';
import partnerDesk from '../../presentation/features/partnerDesk/reducers';
import employee from '../../presentation/features/partnerEmployee/reducers';
import partnerWindow from '../../presentation/features/partnerWindow/reducers';
import personalPromotion from '../../presentation/features/personalPromotion/reducers';
import playground from '../../presentation/features/playground/reducers';
import productOrder from '../../presentation/features/productOrder/reducers';
import product from '../../presentation/features/products/reducers';
import report from '../../presentation/features/report/reducers';
import socialPackage from '../../presentation/features/socialPackage/reducers';
import sportsman from '../../presentation/features/sportsman/reducers';
import team from '../../presentation/features/team/reducers';
import tradeActivation from '../../presentation/features/tradeActivation/reducers';
import tradeOffer from '../../presentation/features/tradeOffer/reducers';
import user from '../../presentation/features/user/reducers';
import layout from '../../presentation/layouts/reducers';

export const rootReducer = combineReducers({
  nsi,
  notifier,
  playground,
  team,
  event,
  user,
  sportsman,
  order,
  chat,
  tradeOffer,
  tradeActivation,
  corpOffer,
  corpActivation,
  bookingOffer,
  bookingOrder,
  mainView,
  offerHistory,
  partner,
  product,
  productOrder,
  complaint,
  historyExtensions,
  partnerDesk,
  employee,
  customer,
  partnerApplication,
  partnerWindow,
  landingWindow,
  configuration,
  banner,
  report,
  lock,
  personalPromotion,
  uikit,
  cms,
  layout,
  legalAgreements,
  dictionary,
  activityType,
  address,
  analytics,
  clientOrg,
  adCampaign,
  socialPackage,
});
