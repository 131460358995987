import { Grid } from '@mui/material';
import { Nullable } from '../../../../../../../domain/model/types';
import { MpUserData } from '../../../../../../../domain/model/user';
import ValidationErrorPanel from '../../../../../../components/common/validation/errorPanel';
import { OnChangeObjectAttribute } from '../../../../../../types';
import { ValidationResult, ValidationRules } from '../../../../../../utils/validation';
import UserCommonEditProfile from '../../../../components/editProfile';
import { Content } from '../../controls';

interface UserMpStepProfileProps {
  readonly user: MpUserData;
  readonly validation: Nullable<ValidationResult<MpUserData>>;
  readonly validationRules?: ValidationRules<MpUserData>;
  readonly isValid: boolean;
  readonly onChangeAttribute: OnChangeObjectAttribute<MpUserData>;
}

const UserMpStepProfile = (props: UserMpStepProfileProps) => {
  const { user, validation, validationRules, isValid, onChangeAttribute } = props;

  return (
    <Content>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <UserCommonEditProfile
            user={user}
            validation={validation}
            validationRules={validationRules}
            onChangeAttribute={onChangeAttribute}
          />
        </Grid>
        {!isValid && (
          <Grid
            item
            xs={12}
          >
            <ValidationErrorPanel text='Заполните все обязательные поля' />
          </Grid>
        )}
      </Grid>
    </Content>
  );
};

export default UserMpStepProfile;
