// TODO - csp дополнить
import { RzdSocialPackage } from '@/domain';
import { PanelActions } from '@/presentation/types';

export enum ESocialPackageTableTab {
  /** Распределение лимитов */
  Appointed = 'appointed',
  /** Готовы к активации */
  Ready = 'ready',
  /** Активные */
  Active = 'active',
  /** Не активные */
  Archive = 'archive',
}

export enum ESocialPackageActionType {
  Archive = 'archive',
  AllocateLimit = 'allocateLimit',
  ToReady = 'toReade',
  BackToAppointed = 'backToAppointed',
}

export type ActionSocialPackageType = RzdSocialPackage;
export type SocialPackageActions<A extends string = ESocialPackageActionType> = PanelActions<A>;

export enum ESocialPackageTableColumn {
  LastStatusDate = 'lastStatusDate',
  EmployeeFullName = 'employee.firstName',
  YearAppointment = 'employee.endDate',
  EmployeeCategory = 'employeeCategory',
  EndDate = 'endDate',
  EmployeeAgreement = 'EmployeeAgreement',
}
