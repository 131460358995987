import ErrorHandler from '@/data/network/errorHandler';
import { ServerErrorResponse } from '@/data/network/types';
import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault } from '@/data/store/types';
import { Nullable, RzdSocialPackage } from '@/domain';
import { ESocialPackageActionType } from '@features/socialPackage/types';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import socialPackageServices from '../../services';

const getActionProcess = (state: SocialPackageActionsState, id: UUID, actionType: ESocialPackageActionType) => {
  let process = state.actions.find(change => change.id === id);
  if (process) return process;

  process = {
    ...fetchableDefault,
    id,
    type: actionType,
    error: null,
  };
  state.actions.push(process);

  return process;
};

export const socialPackageActionsArchive = createAsyncThunk<
  RzdSocialPackage,
  { id: UUID; comment: string },
  AppThunkAPIConfig<ServerErrorResponse>
>('socialPackage/actions/archive', async (payload, { rejectWithValue }) => {
  try {
    return await socialPackageServices.archive(payload);
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const socialPackageActionsAllocateLimit = createAsyncThunk<
  RzdSocialPackage,
  { id: UUID; limitToSelfRest: number; limitToFamilyRest: number },
  AppThunkAPIConfig<ServerErrorResponse>
>('socialPackage/actions/allocateLimit', async (payload, { rejectWithValue }) => {
  try {
    return await socialPackageServices.allocateLimit(payload);
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface SocialPackageActionsState {
  readonly actions: (Fetchable & {
    id: UUID;
    type: ESocialPackageActionType;
    error: Nullable<ServerErrorResponse>;
  })[];
  readonly dialogs: {
    readonly allocateLimit: Nullable<RzdSocialPackage>;
    readonly archive: Nullable<RzdSocialPackage>;
  };
}

type Reducer<T = undefined> = CaseReducer<SocialPackageActionsState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<SocialPackageActionsState> {
  socialPackageActionsChangeDialogState: Reducer<{
    name: keyof SocialPackageActionsState['dialogs'];
    data: Nullable<RzdSocialPackage>;
  }>;
  socialPackageActionsOptimize: Reducer;
}

const slice = createSlice<SocialPackageActionsState, Reducers, 'socialPackage/actions'>({
  name: 'socialPackage/actions',
  initialState: {
    actions: [],
    dialogs: {
      archive: null,
      allocateLimit: null,
    },
  },
  reducers: {
    socialPackageActionsChangeDialogState: (state, { payload }) => {
      const { name, data } = payload;
      state.dialogs[name] = data;
    },
    socialPackageActionsOptimize: state => {
      // Оставляем только выполняющиеся действия
      state.actions = state.actions.filter(action => action.isFetching);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(socialPackageActionsArchive.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = ESocialPackageActionType.Archive;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(socialPackageActionsArchive.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = ESocialPackageActionType.Archive;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(socialPackageActionsArchive.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = ESocialPackageActionType.Archive;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(socialPackageActionsAllocateLimit.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = ESocialPackageActionType.AllocateLimit;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(socialPackageActionsAllocateLimit.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = ESocialPackageActionType.AllocateLimit;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(socialPackageActionsAllocateLimit.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = ESocialPackageActionType.AllocateLimit;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      });
  },
});

export const { socialPackageActionsChangeDialogState, socialPackageActionsOptimize } = slice.actions;

export default slice.reducer;
