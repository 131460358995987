import { Fade, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useAppDispatch } from '../../../../../data/store/store';
import { EUserRole, EUserStatus } from '../../../../../domain/model/enums';
import { NotificationOption } from '../../../../../domain/model/notification';
import { UUID } from '../../../../../domain/model/types';
import MasterActionsComponent from '../../../../components/common/actions/master';
import ConfirmDialog from '../../../../components/common/dialogs/confirm';
import DefaultHeader from '../../../../components/common/header';
import ContentLoader from '../../../../components/common/loader';
import Splitter from '../../../../components/common/splitter';
import { DefaultContentWrapper } from '../../../../components/common/wrappers/content';
import { DefaultFooterWrapper } from '../../../../components/common/wrappers/footer';
import { MPButton } from '../../../../theme/ui-kit/button';
import { ETagColors, MPTag } from '../../../../theme/ui-kit/tag';
import { EPanelActionPosition, PanelAction, PanelActions } from '../../../../types';
import { getUserShortName } from '../../../../utils';
import { getCurrentUserEditRoute } from '../../entry';
import { CurrentUserDetailsActionType, ECurrentUserActionType } from '../types';
import { getCurrentUserActionName } from '../utils';
import UserCurrentDetailsMpComponent from './component';
import { ContainerWrapper, LoaderWrapper, TitleWrapper } from './controls';
import {
  userCurrentDetailsMpByIdSelector,
  userCurrentDetailsMpEmailChangedSelector,
  userCurrentDetailsMpNeedRefreshWatcherSelector,
} from './store/selectors';
import {
  userCurrentDetailsMpByIdFetch,
  userCurrentDetailsMpEmailChangedReset,
  userCurrentDetailsMpNotificationsUpdate,
  userCurrentDetailsMpPasswordReset,
  userCurrentDetailsMpStateReset,
} from './store/slice';

interface UserCurrentDetailsMpContainerProps {
  readonly id: UUID;
  readonly roles: EUserRole[];
  readonly logOut: () => void;
}

const allowedRoles = [
  EUserRole.AdminMp,
  EUserRole.AdminPartner,
  EUserRole.ManagerPartner,
  EUserRole.AdminMpReadOnly,
  EUserRole.AdminMpCorp,
];

const UserCurrentDetailsMpContainer = (props: UserCurrentDetailsMpContainerProps) => {
  const { id, roles, logOut } = props;

  const dispatch = useAppDispatch();
  const history = useHistory();

  const { user, notifications, isFetching } = useSelector(userCurrentDetailsMpByIdSelector);
  const needRefreshWatcher = useSelector(userCurrentDetailsMpNeedRefreshWatcherSelector);
  const emailChanged = useSelector(userCurrentDetailsMpEmailChangedSelector);

  const [passwordResetDialog, setPasswordResetDialog] = useState<boolean>(false);
  const [passwordResetFetching, setPasswordResetFetching] = useState<boolean>(false);

  const onEdit = () => {
    history.push(getCurrentUserEditRoute());
  };

  const onChangeNotification = (option: NotificationOption, enabled: boolean) => {
    dispatch(userCurrentDetailsMpNotificationsUpdate({ id, option, enabled }));
  };

  const resetEmailChanged = () => {
    dispatch(userCurrentDetailsMpEmailChangedReset());
  };

  const onResetPassword = () => {
    setPasswordResetFetching(true);
    dispatch(userCurrentDetailsMpPasswordReset({ id }))
      .unwrap()
      .then(() => {
        logOut();
      })
      .catch((e: Error) => {
        console.error(e);
      })
      .finally(() => setPasswordResetFetching(false));
  };

  const onPanelAction = (action: PanelAction<CurrentUserDetailsActionType>) => {
    const { type } = action;
    switch (type) {
      case ECurrentUserActionType.Edit:
        onEdit();
        break;
      case ECurrentUserActionType.Logout:
        logOut();
        break;
    }
  };

  useEffect(() => {
    const promise = dispatch(
      userCurrentDetailsMpByIdFetch({
        id,
        roles: roles.filter(r => allowedRoles.includes(r)) ?? [],
      })
    );
    return () => {
      dispatch(userCurrentDetailsMpStateReset());
      promise?.abort();
    };
  }, [dispatch, id, roles, needRefreshWatcher]);

  const actions: PanelActions<CurrentUserDetailsActionType> = [];
  actions.push({
    type: ECurrentUserActionType.Logout,
    label: getCurrentUserActionName(ECurrentUserActionType.Logout),
    primary: true,
    position: [EPanelActionPosition.Default],
  });
  actions.push({
    type: ECurrentUserActionType.Edit,
    label: getCurrentUserActionName(ECurrentUserActionType.Edit),
    position: [EPanelActionPosition.Menu],
  });

  const actionsPanel = (
    <MasterActionsComponent<CurrentUserDetailsActionType>
      show={!!actions.length}
      actions={actions}
      onAction={onPanelAction}
      wrapper={DefaultFooterWrapper}
    />
  );

  return (
    <>
      <Fade in>
        <DefaultContentWrapper
          type='details'
          stickyHeader
          fullHeight
          footer={actionsPanel}
        >
          <ContainerWrapper>
            {user && (
              <>
                <DefaultHeader
                  sticky
                  headline={
                    user.status === EUserStatus.Disabled && (
                      <MPTag
                        bold
                        label={'Отключён'}
                        color={ETagColors.Warning}
                      />
                    )
                  }
                >
                  <TitleWrapper>
                    <Typography variant='h2'>{getUserShortName(user)}</Typography>
                  </TitleWrapper>
                </DefaultHeader>
              </>
            )}

            <Splitter
              variant='horizontal'
              size={2}
            />

            {user && (
              <UserCurrentDetailsMpComponent
                user={user}
                notifications={notifications}
                onChangeNotification={onChangeNotification}
                onChangePassword={() => setPasswordResetDialog(true)}
              />
            )}
          </ContainerWrapper>
          {(isFetching || passwordResetFetching) && (
            <LoaderWrapper>
              <ContentLoader
                size={75}
                alpha
              />
            </LoaderWrapper>
          )}
        </DefaultContentWrapper>
      </Fade>

      <ConfirmDialog
        open={!!emailChanged}
        onClose={resetEmailChanged}
        title='Подтвердите электронную почту'
      >
        <Grid
          container
          spacing={2}
          wrap='nowrap'
          direction='column'
        >
          <Grid item>
            <Typography>
              На почту <b>{emailChanged}</b> отправлено письмо. Нажмите на кнопку подтверждения в письме
            </Typography>
          </Grid>
        </Grid>
      </ConfirmDialog>

      <ConfirmDialog
        open={passwordResetDialog}
        onClose={() => setPasswordResetDialog(false)}
        title='Сменить пароль'
        text='Для изменения пароля необходимо выполнить вход с текущим паролем, после чего вы сможете ввести новый.'
        dialogButtons={
          <Grid
            container
            spacing={2}
          >
            <Grid item>
              <MPButton
                fullWidth={false}
                onClick={onResetPassword}
              >
                Продолжить{passwordResetFetching && <ContentLoader />}
              </MPButton>
            </Grid>
            <Grid item>
              <MPButton
                fullWidth={false}
                disabled={passwordResetFetching}
                onClick={() => setPasswordResetDialog(false)}
                variant='outlined'
              >
                Отменить
              </MPButton>
            </Grid>
          </Grid>
        }
      />
    </>
  );
};
export default UserCurrentDetailsMpContainer;
