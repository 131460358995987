import { Grid } from '@mui/material';
import React from 'react';
import { Nullable } from '../../../../../domain/model/types';
import ContentLoader from '../../../../components/common/loader';
import { MPButton } from '../../../../theme/ui-kit/button';
import { Wrapper } from './controls';

interface ReportCardProps {
  readonly title: React.ReactNode;
  readonly description?: React.ReactNode;
  readonly isFetching?: boolean;
  readonly onDownload: Nullable<() => void>;
}

const ReportCard = (props: ReportCardProps) => {
  const { title, description, onDownload, isFetching } = props;

  return (
    <Wrapper>
      <Grid
        container
        direction='column'
        spacing={2}
      >
        <Grid item>{title}</Grid>
        <Grid
          item
          xs
        >
          {description}
        </Grid>
        {onDownload && (
          <Grid item>
            <MPButton
              variant='contained'
              fullWidth={false}
              size='small'
              disabled={isFetching}
              onClick={onDownload}
            >
              Скачать отчёт
              {isFetching && <ContentLoader size={15} />}
            </MPButton>
          </Grid>
        )}
      </Grid>
    </Wrapper>
  );
};

export default ReportCard;
