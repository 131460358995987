import { FormControlLabel, Grid, Radio } from '@mui/material';
import { EOfferType, ETargetType } from '../../../../../../domain/model/enums';
import SectionTitle from '../../../../../components/common/sectionTitle';
import Splitter from '../../../../../components/common/splitter';
import { MPRadioGroup } from '../../../../../theme/ui-kit/radio';
import {
  cmsCorpTargetByCorpStructureAttributes,
  cmsCorpTargetByLocalitiesAttributes,
  cmsTradeTargetByLocalitiesAttributes,
} from '../../../utils';
import { cmsContainerOfferTargets } from '../../utils/constants';
import CmsEditTargetAdapter from '../adapters/target';
import { CmsContainerCreateComponentProps } from '../types';

type CmsContainerEditComponentTargetProps = CmsContainerCreateComponentProps & {
  readonly isNotRequiredOfferType?: boolean;
};

const CmsContainerEditComponentTarget = (props: CmsContainerEditComponentTargetProps) => {
  const { cmsContainer, validation, isNotRequiredOfferType, onChange } = props;
  const { offerType } = cmsContainer;

  const isOfferTypeCorp = cmsContainer.offerType === EOfferType.Corp;
  const isOfferTypeTradeOrNull = !cmsContainer.offerType || cmsContainer.offerType === EOfferType.Trade;

  const isTargetTypeGeo = cmsContainer.targetType === ETargetType.Geo;
  const isTargetTypeCorp = cmsContainer.targetType === ETargetType.Corp;

  const isRequiredOfferType = !isNotRequiredOfferType;

  if (isRequiredOfferType && !offerType) return null;

  return (
    <Grid
      container
      direction='column'
      spacing={1}
    >
      <Grid item>
        <SectionTitle>Доступность</SectionTitle>
      </Grid>
      <Grid item>
        {isOfferTypeCorp && (
          <>
            <MPRadioGroup<ETargetType>
              value={cmsContainer?.targetType || ETargetType.Geo}
              onChange={value => onChange('targetType', value)}
            >
              <Grid
                container
                spacing={3}
              >
                {cmsContainerOfferTargets.map(t => (
                  <Grid
                    key={t.id}
                    item
                  >
                    <FormControlLabel
                      value={t.id}
                      label={t.name}
                      control={<Radio color='primary' />}
                    />
                  </Grid>
                ))}
              </Grid>
            </MPRadioGroup>
            <Splitter
              variant='horizontal'
              size={1}
            />
          </>
        )}
        {isOfferTypeCorp && isTargetTypeCorp && (
          <CmsEditTargetAdapter
            {...cmsCorpTargetByCorpStructureAttributes}
            target={cmsContainer}
            validation={validation}
            onChangeAttribute={onChange}
          />
        )}
        {isOfferTypeCorp && isTargetTypeGeo && (
          <CmsEditTargetAdapter
            {...cmsCorpTargetByLocalitiesAttributes}
            target={cmsContainer}
            validation={validation}
            onChangeAttribute={onChange}
          />
        )}
        {isOfferTypeTradeOrNull && isTargetTypeGeo && (
          <CmsEditTargetAdapter
            {...cmsTradeTargetByLocalitiesAttributes}
            target={cmsContainer}
            validation={validation}
            onChangeAttribute={onChange}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default CmsContainerEditComponentTarget;
