import styled from '@emotion/styled';

export const Wrapper = styled.div`
  height: 100%;

  display: flex;

  padding: ${p => p.theme.spacing(2.5, 2.5, 3.5, 2.5)};

  border-radius: ${p => p.theme.shape.borderRadius}px;
  border: 1px solid ${p => p.theme.palette.secondary.main};

  background-color: ${p => p.theme.palette.secondary.light};
`;
